import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";
import useApi from "../api/useApi";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import { useParams } from "react-router-dom";

const ProjectDashboardTabs = ({ number, project }) => {
  const { user } = useAuth();

  const { page } = useParams();

  const { data: orgStandardJobTypes } = useApi(
    "organisations-standard-job-types",
    [],
  );

  const [hasJobTypeProjectForm, setHasJobTypeProjectForm] = useState(false);

  const cantSeeDocuments =
    !user.is_admin &&
    project.is_confidential &&
    !project.team.map(({ id }) => id).includes(user.id);

  useEffect(() => {
    if (orgStandardJobTypes) {
      setHasJobTypeProjectForm(
        !!orgStandardJobTypes.find(
          (jobType) => jobType.job_type_type === "App\\Models\\ProjectForm",
        ),
      );
    }
  }, [orgStandardJobTypes]);

  const isAdmin = user.is_position_admin;

  const internal = project?.internal;

  const setting = useOrganisationSetting("project_managers_can_add_allowances");

  const canViewOverview = user.hasAccessTo(
    "App\\Models\\Project",
    "view_overview",
  );

  const canAddAllowances = user.hasAccessTo(
    "App\\Models\\Project",
    "add_allowances",
  );

  return (
    <TabList>
      <Tab
        link={`/projects/${number}/details`}
        active={page === "details"}
        icon="fa fa-file-text-o"
      >
        Details
      </Tab>
      {((canViewOverview && isAdmin) || user.id === project?.project_manager) &&
        !project.is_template && (
          <Tab
            link={`/projects/${number}/main`}
            active={page === "main"}
            icon="fa fa-file-text-o"
          >
            Overview
          </Tab>
        )}
      {cantSeeDocuments ? null : (
        <Tab
          link={`/projects/${number}/documents`}
          active={page === "documents"}
          icon="fa fa-folder-open-o"
        >
          Documents
        </Tab>
      )}
      <Tab
        link={`/projects/${number}/jobs/list`}
        active={page === "jobs"}
        icon="fa fa-pencil-square-o"
      >
        Jobs
      </Tab>
      {isAdmin && !project.is_template && (
        <Tab
          link={`/projects/${number}/deliverables`}
          active={page === "deliverables"}
          icon="fa fa-pencil-square-o"
        >
          Deliverables
        </Tab>
      )}
      {!project.internal && isAdmin && !project.is_template && (
        <Tab
          link={`/projects/${number}/invoices`}
          active={page === "invoices"}
          icon="fa fa-pencil-square-o"
        >
          Invoices
        </Tab>
      )}
      {project.inter_entity_invoices.length > 0 && (
        <Tab
          link={`/projects/${number}/inter-entity-invoices`}
          active={page === "inter-entity-invoices"}
          icon="fa fa-pencil-square-o"
        >
          Inter Entity Invoices
        </Tab>
      )}
      {isAdmin && !project.is_template && (
        <Tab
          link={`/projects/${number}/purchases`}
          active={page === "purchases"}
          icon="fa fa-pencil-square-o"
        >
          Purchases
        </Tab>
      )}
      {(user.is_admin ||
        canAddAllowances ||
        (user.id == project.project_manager && setting?.meta_value)) &&
        !project.is_template && (
          <Tab
            link={`/projects/${number}/allowances`}
            active={page === "allowances"}
            icon="fa fa-pencil-square-o"
          >
            Allowances
          </Tab>
        )}
      {!project.is_template && (
        <Tab
          link={`/projects/${number}/equipment`}
          active={page === "equipment"}
          icon="fa fa-pencil-square-o"
        >
          Equipment
        </Tab>
      )}
      {!project.is_template && (
        <Tab
          link={`/projects/${number}/emails`}
          active={page === "emails"}
          icon="fa fa-pencil-square-o"
        >
          Emails
        </Tab>
      )}
      {isAdmin && (
        <Tab
          link={`/projects/${number}/audit-log`}
          active={page === "audit-log"}
          icon="fa fa-pencil-square-o"
        >
          Audit Log
        </Tab>
      )}

      {user.active_organisation.id !== project.organisation_id && (
        <Tab
          link={`/projects/${number}/inter-entity-invoices`}
          active={page === "shared"}
          icon="fa fa-pencil-square-o"
        >
          Inter Entity Invoices
        </Tab>
      )}
      {!project.internal && (
        <Tab
          link={`/projects/${number}/communications`}
          active={page === "communications"}
        >
          Communication Log
        </Tab>
      )}

      {hasJobTypeProjectForm && !internal && !project.is_template && (
        <Tab
          link={`/projects/${number}/project-forms`}
          active={page === "project-forms"}
          icon="fa fa-pencil-square-o"
        >
          QA Forms
        </Tab>
      )}
    </TabList>
  );
};

export default ProjectDashboardTabs;
