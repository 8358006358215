import { FormSubmitHandler } from "redux-form";
import OpportunityForm from "./OpportunityForm";
import { Opportunity } from "./opportunityTypes";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { useParams } from "react-router-dom";
import formError from "../utils/formError";
import { toast } from "react-toastify";

const EditOpportunity = ({
  opportunity,
  setOpportunity,
}: {
  opportunity: Opportunity;
  setOpportunity: Function;
}) => {
  const { takeAction }: IUseApi = useApi();

  const { uuid } = useParams<{ uuid: string }>();

  const onSubmit: FormSubmitHandler<Opportunity> = (values) => {
    return takeAction("update", `opportunities/${uuid}`, values)
      .then(({ data }) => {
        toast.success("Opportunity updated successfully");
        setOpportunity(data.data);
      })
      .catch(formError);
  };

  return (
    <OpportunityForm
      form="EditOpportunity"
      initialValues={{
        ...opportunity,
        industries: opportunity.industries.map((d) => ({
          label: d.name,
          value: d.id,
        })),
        services: opportunity.services.map((d) => ({
          label: d.name,
          value: d.id,
        })),
      }}
      onSubmit={onSubmit}
    />
  );
};

export default EditOpportunity;
