import { Field, WrappedFieldArrayProps } from "redux-form";
import SelectInput from "../form/SelectInput";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { Button } from "reactstrap";
import useApi from "../api/useApi";
import FormHeader from "../utils/FormHeader";

const ReportPages = (
  props: WrappedFieldArrayProps & { worksheets: string[] },
) => {
  const { worksheets, fields } = props;

  const { takeAction } = useApi();

  return (
    <>
      <FormHeader>Report Pages</FormHeader>

      {fields.map((field, index) => {
        const sheet = fields.get(index);

        return (
          <>
            <div className="col-lg-12 form-group">
              <p className="mb-0 text-dark">Report Page {index + 1}</p>
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={SelectInput}
                name={`${field}.logo_sheet`}
                label="Logo Sheet"
                validate={required}
                options={worksheets.map((d) => ({
                  label: d,
                  value: d,
                }))}
              />
            </div>

            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`${field}.logo_cell`}
                label="Logo Cell"
                validate={required}
                required
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`${field}.logo_settings.height`}
                label="Logo Heigh (px)"
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`${field}.logo_settings.width`}
                label="Logo Width (px)"
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={SelectInput}
                options={worksheets.map((d) => ({
                  label: d,
                  value: d,
                }))}
                name={`${field}.signature_sheet`}
                label="Signature Sheet"
                validate={required}
                required
              />
            </div>

            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`${field}.signature_cell`}
                label="Signature Cell"
                validate={required}
                required
              />
            </div>

            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`${field}.signature_settings.height`}
                label="Signature Height (px)"
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                component={RenderField}
                name={`${field}.signature_settings.width`}
                label="Signature Width (px)"
              />
            </div>
            <div className="col-lg-12 form-group">
              <Button
                color="danger"
                outline
                onClick={() => {
                  if (sheet.uuid) {
                    return takeAction(
                      "destroy",
                      `test-worksheet-report-pages/${sheet.uuid}`,
                    ).then(() => {
                      fields.remove(index);
                    });
                  }

                  fields.remove(index);
                }}
              >
                Remove Report Page
              </Button>
            </div>
          </>
        );
      })}
      <div className="col-lg-12 form-group">
        <Button color="primary" outline onClick={() => fields.push({})}>
          Add Report Page
        </Button>
      </div>
    </>
  );
};

export default ReportPages;
