import renderField from "../utils/renderField";
import { Field, InjectedFormProps } from "redux-form";
import required from "../utils/required";
import SubmitButton from "../utils/SubmitButton";
import Toggle from "react-toggle";
import SelectInput from "../form/SelectInput";
import { percentage } from "../form/formatters";
import FormErrorAlert from "../form/FormErrorAlert";
import renderToggleInput from "../utils/renderToggleInput";
import { useState } from "react";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import { Link } from "react-router-dom";
import useApi from "../api/useApi";
import InformationAlert from "../utils/InformationAlert";
import { Tender } from "./tenderTypes";
import { IUseApiWithData } from "../api/apiTypes";
import PaginatedSelectInput from "../form/PaginatedSelectInput";
import { ClientIndex } from "../clients/clientTypes";

const AddTenderFields = (
  props: InjectedFormProps<any, { showProject?: boolean }> & {
    showProject?: boolean;
    showSave?: boolean;
    organisationId?: number;
  },
) => {
  const {
    showProject = false,
    showSave = true,
    change,
    organisationId,
    initialValues,
  } = props;

  const hasDefaultTender = useOrganisationSetting(
    "tender_default_project",
    organisationId ?? undefined,
  );

  const [newClient, setNewClient] = useState(
    !!initialValues?.tender?.client_name,
  );

  const { data: clientTenders, setUrl }: IUseApiWithData<Tender[]> = useApi(
    "",
    [],
  );

  const warnName = () => {
    if (clientTenders.length > 0) {
      return (
        <>
          <div className="mb-5">
            <InformationAlert
              type="info"
              title={`Last ${clientTenders.length} tender${
                clientTenders.length === 1 ? "" : "s"
              } from this client:`}
              body={
                <>
                  {clientTenders.map((tender, index) => (
                    <>
                      <Link key={index} to={`${tender.link}`}>
                        {tender.project_name}
                      </Link>
                      {", "}
                    </>
                  ))}
                </>
              }
            />
          </div>
        </>
      );
    }

    return undefined;
  };

  const checkTendersByClient = (value: any) => {
    if (!value || value.uuid == undefined) return;

    setUrl(`/clients/${value.uuid}/tenders?paginate=5`);
  };

  return (
    <>
      <FormErrorAlert error={props.error} />
      {warnName()}
      {showProject && hasDefaultTender?.meta_value == 0 && (
        <div className="col-12 form-group">
          <Field
            label="Project"
            name="project_id"
            component={PaginatedSelectInput}
            url="/schedule-projects?"
            validate={required}
            required
          />
          <small className="text-muter">Type to see options.</small>
        </div>
      )}
      <div className="form-group col-lg-3">
        <Field
          component={SelectInput}
          name="tender.type_id"
          label="Type"
          required
          validate={required}
          url={`/tender-types${
            organisationId ? `?organisation_id=${organisationId}` : ""
          }`}
          formatData={(data: any) => {
            return data.map((type: any) => ({
              label: type.name,
              value: type.id,
            }));
          }}
          empty="Unable to find any tender types, please ask admin to add before continuing."
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderField}
          name="tender.project_name"
          label="Project/Job Name"
          information="Enter a name here for the Project/Job. This name will be applied once the Tender is marked as successful."
          required
          validate={required}
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={SelectInput}
          name="job.project_manager"
          label="Tender Manager"
          url={`/staff${
            organisationId ? `?organisation_id=${organisationId}` : ""
          }`}
          required
          validate={required}
          formatData={(data: any[]) => {
            return data.map((d) => ({
              label: d.name,
              value: d.id,
            }));
          }}
        />
      </div>
      <div className="form-group col-lg-3">
        <Field
          component={renderToggleInput}
          name="tender.confidential"
          label="Confidential"
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          component={PaginatedSelectInput}
          name="job.branch_id"
          label="Branch"
          url={`/organisation-branches?paginate=10${
            organisationId ? `&organisation_id=${organisationId}` : ""
          }`}
          required
          validate={required}
          hideDisabled={true}
          formatData={(data: any[]) => {
            return data.map((d) => ({
              label: d.name,
              value: d.id,
            }));
          }}
        />
      </div>
      <div className="form-group col-lg-4">
        <div className="d-flex align-items-center mb-2">
          <label className="tx-inverse tx-semibold mb-0">Client</label>
          <div className="d-flex ms-auto align-items-center">
            <Toggle
              checked={newClient}
              onChange={() => {
                setNewClient(!newClient);
                change("tender.client_name", null);
                change("tender.client_id", null);
              }}
              className="toggle-sm"
              icons={false}
            />
            <span className="tx-sm ms-1">New Client</span>
          </div>
        </div>
        <Field
          name={newClient ? "tender.client_name" : "tender.client_id"}
          component={newClient ? renderField : SelectInput}
          url={`/organisation-clients?${
            organisationId ? `organisation_id=${organisationId}` : ""
          }`}
          formatData={(data: ClientIndex[]) =>
            data.map((d) => ({
              label: d.is_disabled ? (
                <>
                  <p className="mb-0">{d.display_name}</p>
                  <p className="tx-12 text-warning mb-0">{d.disabled_reason}</p>
                </>
              ) : (
                d.name
              ),
              value: d.id,
              disabled: d.is_disabled,
            }))
          }
          required
          validate={required}
          onChangeValue={checkTendersByClient}
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="job.users"
          label="Staff"
          isMulti
          component={SelectInput}
          url={`/staff${
            organisationId ? `?organisation_id=${organisationId}` : ""
          }`}
          formatData={(data: any[]) => {
            return data.map((d) => ({
              label: d.name,
              value: d.id,
            }));
          }}
        />
        <small>
          Staff from Your Organisation that will be working on this job
        </small>
      </div>
      <div className="col-lg-4 form-group">
        <Field
          name="job.scheduled_start_date"
          type="date"
          component={renderField}
          required
          label="Tender Start Date"
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="job.scheduled_finish_date"
          type="date"
          component={renderField}
          required
          label="Expected Tender Award Date"
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="job.estimated_hours"
          type="number"
          component={renderField}
          label="Estimated Hours to Complete Tender"
          validate={required}
          required
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="tender.work_start_date"
          type="date"
          component={renderField}
          required
          validate={required}
          label="Expected Start Date of Work"
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="tender.work_finish_date"
          type="date"
          component={renderField}
          required
          validate={required}
          label="Expected Finish Date of Work"
        />
      </div>
      <div className="form-group col-lg-4">
        <Field
          name="tender.probability_of_project_starting"
          component={renderField}
          label="Probability of Work Commencing"
          {...percentage}
        />
      </div>
      {showSave && (
        <div className="form-group col-lg-12">
          <SubmitButton {...props} />
        </div>
      )}
    </>
  );
};

export default AddTenderFields;
