import { useParams } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import ErrorFallback from "../../ErrorFallback";
import useApi from "../api/useApi";
import SideList from "../comments/SideList";
import SyncIntegrationEstimation from "../contacts/SyncIntegrationEstimation";
import HeaderPage from "../header/HeaderPage";
import useModal from "../hooks/useModal";
import AverageDaysToPay from "../invoices/AverageDaysToPay";
import ProfileViewsList from "../profileViews/ProfileViewsList";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import Spinner from "../utils/Spinner";
import CopyExternalFormLinkButton from "./CopyExternalFormLinkButton";
import DeleteClientButton from "./DeleteClientButton";
import HoldsPopover from "./HoldsPopover";
import OnHoldModal from "./OnHoldModal";
import ProfileTabs from "./ProfileTabs";
import SendFormButton from "./SendFormButton";
import ShowPage from "./ShowPage";
import UnpaidAmountBadges from "./UnpaidAmountBadges";
import useUserGroup from "../hooks/useUserGroup";
import SyncEstimatedEntity from "./SyncEstimatedEntity";
import CopyButton from "../copyToOtherOrganisation/CopyButton";

const ClientProfile = (props) => {
  const { uuid } = useParams();
  const { user } = useAuth();
  const {
    loading,
    data: client,
    response,
    takeAction,
    setData: setClient,
    refreshData,
  } = useApi(`organisation-clients/${uuid}`, {}, true, "/clients");

  const { toggle, modal } = useModal();

  const isPartOfAccounting = useUserGroup("Accounting");

  const canPutOnHold = user.is_admin || isPartOfAccounting;

  if (loading || !response) return <Spinner loading />;

  if (!client) {
    return <ErrorFallback />;
  }

  return (
    <>
      <HeaderPage
        titlePage={client.display_name}
        crumbs={[
          { link: "clients", name: "Clients" },
          { link: "", name: client.display_name, active: true },
        ]}
      />
      <div className="row row-sm justify-content-end">
        <div className="col-lg-4 m-1">
          <ProfileViewsList
            viewable={client}
            viewable_type={"App\\Models\\OrganisationClient"}
          />
        </div>
      </div>{" "}
      <div className="row row-sm">
        <div className="col-lg-8">
          <Header client={client} />
          <SyncIntegrationEstimation contact={client} setContact={setClient} />

          <SyncEstimatedEntity refreshData={refreshData} client={client} />

          <ShowPage
            client={client}
            setClient={setClient}
            refreshData={refreshData}
            {...props}
          />
        </div>

        <div className="col-lg-4 mg-t-20 mg-lg-t-0">
          <ProfileTabs />
          <div className="mt-3">
            <SideList
              commentableType="App\Models\OrganisationClient"
              commentableId={client.id}
            />
          </div>
          {canPutOnHold && (
            <div className="mt-3">
              <button
                className="btn btn-outline-secondary w-100"
                onClick={toggle}
              >
                {client.hold ? "Remove Hold" : "Add Hold"}
              </button>
            </div>
          )}
          <SendFormButton setClient={setClient} client={client} />
          <DeleteClientButton client={client} />
          <CopyExternalFormLinkButton />
          <CopyButton model="App\Models\OrganisationClient" />
        </div>
      </div>
      <OnHoldModal
        toggle={toggle}
        modal={modal}
        hold={client.hold}
        onSuccess={refreshData}
        holdable={{
          holdable_id: client.id,
          holdable_type: "App\\Models\\OrganisationClient",
        }}
      />
    </>
  );
};

const Header = ({ client }) => {
  const { uuid } = useParams();

  return (
    <DashboardHeader>
      <div className="d-flex">
        <DashboardH3>{client?.display_name}</DashboardH3>
        <UnpaidAmountBadges
          unpaid={client.accounts_receivable_outstanding ?? client.total_unpaid}
          overdue={client.accounts_receivable_overdue ?? client.total_overdue}
          link={`/clients/${uuid}/invoices`}
        />
      </div>
      <AverageDaysToPay resource={client} />
      {client.hold && (
        <DashboardSubTitle>
          <Badge color="danger" className="rounded-pill shadow">
            On Hold
          </Badge>
        </DashboardSubTitle>
      )}
      {!client.form_filled_at && (
        <DashboardSubTitle className="my-1">
          <Badge color="warning" className="rounded-pill shadow">
            Details not completed.
          </Badge>
        </DashboardSubTitle>
      )}
      <p className="mb-0 d-flex space-x-3 mt-2">
        <Badge color="info" className="mb-1 rounded-pill shadow">
          {client.account_string}
        </Badge>
        {client.is_key_client && (
          <DashboardSubTitle>
            <Badge color="success" className="rounded-pill shadow">
              Key Client
            </Badge>
          </DashboardSubTitle>
        )}
      </p>
      <div className="mt-1">
        <DashboardSubTitle>{client.contact_phone}</DashboardSubTitle>
        <DashboardSubTitle>{client.contact_email}</DashboardSubTitle>
      </div>

      {client.holds_count > 0 && (
        <p className="mb-0 mt-2">
          <Button color="warning" size="sm" id="holds">
            {client.display_name} has been put on hold {client.holds_count}{" "}
            times.
          </Button>
          <HoldsPopover
            holdableType="App\Models\OrganisationClient"
            holdableId={client.id}
          />
        </p>
      )}
    </DashboardHeader>
  );
};

export default ClientProfile;
