import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import Prices from "../prices/Prices";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import ClientAccountDetails from "./ClientAccountDetails";
import ClientContacts from "./ClientContacts";
import ClientFeedbackCollection from "./ClientFeedbackCollection";
import ClientProjects from "./ClientProjects";
import ClientDetails from "./Details";
import Invoices from "./Invoices";
import Overview from "./Overview";
import Tenders from "./Tenders";
import AuditLog from "../auditLog/AuditLog";
import Communications from "../communicationLogs/Communications";

const ShowPage = (props) => {
  const { uuid, page } = useParams();
  const { user } = useAuth();
  const isPositionAdmin = user.is_position_admin;
  const { client } = props;

  switch (page) {
    case "details":
      return <ClientDetails initialValues={client} {...props} />;
    case "documents":
      return (
        <ProfileDocuments
          {...props}
          model="App\Models\OrganisationClient"
          documentableId={uuid}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/clients/${uuid}`}
          homeUrl={`clients/${uuid}/documents`}
        />
      );
    case "feedback":
      return <ClientFeedbackCollection client={client} />;
    case "account":
      return <ClientAccountDetails {...props} />;
    case "contacts":
      return <ClientContacts {...props} />;
    case "projects":
      return <ClientProjects />;

    case "tenders":
      return <Tenders {...props} />;
    case "pricing":
      return (
        <Prices
          duplicate
          chargeableType="App\Models\OrganisationClient"
          chargeableId={client.id}
        />
      );
    case "overview":
      return <Overview />;
    case "invoices":
      return isPositionAdmin ? <Invoices {...props} /> : <NotFound />;
    case "audit-log":
      return isPositionAdmin ? (
        <AuditLog
          subjectType="App\Models\OrganisationClient"
          uuid={uuid}
          {...props}
        />
      ) : (
        <NotFound />
      );
    case "communications":
      return (
        <Communications
          communicableId={client.id}
          communicableType="App\Models\OrganisationClient"
          initialClientUuid={client.uuid}
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
