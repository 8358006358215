import { useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector, WrappedFieldArrayProps } from "redux-form";
import ItemFields from "./ItemFields";
import { money } from "../form/formatters";
import useApi from "../api/useApi";
import { Branch } from "../branches/branchTypes";
import { ChartOfAccount } from "../chartOfAccounts/chartOfAccountTypes";
import { IUseApiWithData } from "../api/apiTypes";
import { PurchaseItem } from "../purchases/purchaseTypes";

interface PurchaseItemsProps {
  fields: any;
  creditCard?: boolean;
  purchaseOrder?: boolean;
  disabled?: boolean;
  initialValues?: any;
  purchaseItems: PurchaseItem[];
  isTestPurchaseOrder: boolean;
  project?: {
    id: number;
  };
  projectId?: number;
  fromModal?: boolean;
  change?: Function;
  siteOrder?: boolean;
  loading?: boolean;
}

export const formatBranches = (branches: Branch[]) => {
  return branches.map((branch) => ({
    label: branch.name,
    value: branch.id,
  }));
};

export const formatAccountCodes = (codes: ChartOfAccount[]) => {
  return codes.map((code) => ({
    label: `${code.code} - ${code.name}`,
    value: code.id,
    type: code.type,
  }));
};

const PurchaseItems = (
  props: WrappedFieldArrayProps<PurchaseItem> & PurchaseItemsProps,
) => {
  const {
    fields,
    creditCard,
    purchaseOrder,
    disabled,
    initialValues,
    purchaseItems,
    isTestPurchaseOrder,
    project,
    projectId,
  } = props;

  // const [branches, setBranches] = useState([]);
  const { data: branches }: IUseApiWithData<Branch[]> = useApi(
    "user-branches",
    [],
  );

  const { data: jobs, setUrl } = useApi("", []);

  useEffect(() => {
    if (projectId || project) {
      setUrl(
        `custom-fields/jobs?filter[project_id]=${project?.id ?? projectId}`,
      );
    }
  }, [projectId, project]);

  const { data: accountCodes }: IUseApiWithData<ChartOfAccount[]> = useApi(
    `chart-of-accounts?${creditCard ? "&creditCards=1" : ""}${
      purchaseOrder ? "&purchaseOrders=1" : ""
    }`,
    [],
  );

  useEffect(() => {
    if ((!initialValues || !initialValues.uuid) && fields.length === 0) {
      fields.push({});
    }
  }, []);

  const totalPrice = purchaseItems
    ? purchaseItems.reduce((carry, item) => {
        if (item.price && item.quantity) {
          carry += item.price * item.quantity;
        }
        return carry;
      }, 0)
    : 0;

  return (
    <>
      {!disabled && (
        <div className="col-12 d-flex align-items-center">
          <h5 className="tx-inverse">Add Line Items Below</h5>
          <span className="ms-auto">
            <TotalPrice price={totalPrice} />
          </span>
        </div>
      )}
      {fields.map((member: any, index: any) => {
        const newProps = {
          member,
          branches: formatBranches(branches),
          fields,
          index,
          accountCodes: formatAccountCodes(accountCodes),
          key: index,
          jobs,
        };

        /** @ts-ignore */
        return <ItemFields {...props} {...newProps} />;
      })}

      {!isTestPurchaseOrder && (
        <div className="col-12 form-group d-flex">
          <button
            className="btn btn-link ms-auto"
            type="button"
            onClick={() => fields.push({})}
          >
            Add Line Item
          </button>
        </div>
      )}
    </>
  );
};

const TotalPrice = ({ price }: { price: number }) => (
  <>
    <small className="text-secondary">Total Price</small>
    <p className="text-dark mb-0 tx-16">
      <strong>{money.format(price.toFixed(2))}</strong>
    </p>
  </>
);

interface StateProps {
  purchaseItems: PurchaseItem[];
}

const mapStateToProps = (
  state: StateProps,
  {
    form,
  }: {
    form: string;
  },
) => {
  const selector = formValueSelector(form);

  return {
    purchaseItems: selector(state, "purchase_items"),
    projectId: selector(state, "project_id"),
  };
};

export default connect(mapStateToProps, {})(PurchaseItems);
