import PaginatedList from "../pagination/PaginatedList";
import ReactTable from "../table/ReactTable";
import { ActiveDashboard } from "../timesheets/ProfileCards";
import ApprovalList from "./ApprovalList";
import useUserJobs from "./hooks/useUserJobs";
import JobOrder from "./JobOrder";
import tableColumns from "./tableColumns";

export interface HomeDataProps {
  activeDashboard: ActiveDashboard;
  setDashboardData: (data: any) => void;
  dashboardData: any;
  refreshData: Function;
  userUuid: string;
}

const HomeData = ({
  activeDashboard,
  dashboardData,
  setDashboardData,
  refreshData,
  userUuid,
}: HomeDataProps) => {
  if (activeDashboard.key === "to-approve") {
    return (
      <ApprovalList
        approvals={dashboardData}
        setApprovals={setDashboardData}
        refreshData={refreshData}
      />
    );
  }

  if (activeDashboard.key === "overdue-jobs") {
    const jobTypes = [
      "App\\Models\\JobType",
      "App\\Models\\CustomJob",
      "App\\Models\\StandardJobType",
      "App\\Models\\Tender",
    ]
      .map((type) => encodeURIComponent(type))
      .join(",");

    return (
      <PaginatedList
        indexHook={useUserJobs}
        originalFilters={[]}
        indexHookArguments={[userUuid]}
        listName="overdue-jobs"
        defaultFilters={`&filter[status]=0,1&filter[overdue]=true&filter[job_type_type]=${jobTypes}`}
        list={({ data }) => (
          <ReactTable
            data={data as any[]}
            disableSearch
            wrapperClasses="table-responsive card pd-sm-x-0-force"
            columns={tableColumns[activeDashboard.description]}
          />
        )}
      />
    );
  }

  if (activeDashboard.description !== "Jobs") {
    return (
      <ReactTable
        data={dashboardData}
        disableSearch
        wrapperClasses="table-responsive card pd-sm-x-0-force"
        columns={tableColumns[activeDashboard.description]}
      />
    );
  }

  return <JobOrder userUuid={userUuid} />;
};

export default HomeData;
