import { AccountRequest } from "../accounts/accountTypes";
export interface ClientIndex {
  uuid: string;
  link: string;
  id: number;
  name: string;
  trading_name?: string;
  display_name: string;
  abn_holder: boolean;
  formatted_address?: string;
  accounts_email?: string;
  contact_email?: string;
  contact_telephone?: string;
  invoice_terms: "C.O.D." | "Account";
  form_filled_at?: string;
  is_key_client: boolean;
  client_type?: {
    id: number;
    uuid: string;
    name: string;
  };
  is_disabled: boolean;
  disabled_reason?: string;
}

export interface Client {
  id: number;
  created_at: string;
  abn_holder: boolean;
  updated_at: string;
  uuid: string;
  invoice_name: string;
  invoice_terms: number;
  invoice_terms_amount: number;
  client_winners: { value: number; label: string }[];
  how_client_found: { value: number; label: string }[];
  business_activities: { value: number; label: string }[];
  contact_telephone: string;
  contact_email: string;
  address: string;
  description: string;
  is_on_hold: boolean;
  has_account: boolean;
  account_limit: number;
  name: string;
  form_sent_at: string;
  abn: string;
  accounts_email: string;
  state: string;
  postcode: string;
  suburb: string;
  line_1: string;
  line_2: string;
  postal_state: string;
  postal_postcode: string;
  postal_suburb: string;
  postal_line_1: string;
  postal_line_2: string;
  display_name: string;
  trading_name: string;
  hold: string;
  form_filled_at: string;
  holds_count: string;
  total_overdue: number;
  total_unpaid: number;
  accounts_telephone: string;
  account_request?: AccountRequest;
  previous_account_requests: AccountRequest[];
}

export enum InvoiceTerms {
  COD,
  DaysFromInvoiced,
  DaysFromEndOfMonth,
}
