import { InjectedFormProps } from "redux-form";
import FormModal from "../utils/FormModal";
import Fields from "./Fields";

interface PurchaseModalProps {
  toggle: Function;
  modal: boolean;
  url: string;
  initialValues: any;
  onSubmit: Function;
  form: string;
  title: string;
  project: {
    id: number;
  };
  selectedTests?: any[];
}

const PurchaseModal = (props: PurchaseModalProps) => {
  return (
    <FormModal {...props}>
      {(formProps: InjectedFormProps) => {
        return (
          <Fields
            {...props}
            file
            purchaseOrder={props.url === "purchase-orders"}
            creditCard={props.url === "credit-card-receipts"}
            {...formProps}
          />
        );
      }}
    </FormModal>
  );
};

export default PurchaseModal;
