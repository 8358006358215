import { RiAddFill } from "react-icons/ri";
import ServiceModal from "./ServiceModal";
import { FormSubmitHandler } from "redux-form";
import { IUseApi } from "../api/apiTypes";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import useModal from "../hooks/useModal";
import TextButton from "../utils/TextButton";

const AddService = () => {
  const { takeAction }: IUseApi = useApi();
  const { toggle, modal } = useModal();

  const queryClient = useQueryClient();

  const onSubmit: FormSubmitHandler<{ name: string }> = (values, _, props) => {
    return takeAction("store", "services", values)
      .then(() => {
        toast.success(`${values.name} added successfully`);
        queryClient.invalidateQueries("services");
        toggle();
        props?.reset?.();
      })
      .catch(formError);
  };

  return (
    <>
      <TextButton onClick={toggle}>
        <RiAddFill aria-label="Add New Service" className="tx-24 ms-1" />
      </TextButton>
      <ServiceModal
        title="Add New Service"
        form="AddService"
        modal={modal}
        toggle={toggle}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default AddService;
