import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import Invoices from "../invoices/Page";
import ProjectJobsTabsList from "./ProjectJobsTabsList";
import ProjectPurchases from "../purchases/ProjectPurchases";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import ProjectDeliverables from "./deliverables";
import ProjectDetail from "./Detail";
import Overview from "./overview";
import ProjectAllowances from "./ProjectAllowances";
import ProjectEquipment from "./ProjectEquipment";
import ProjectEmails from "./ProjectEmails";
import ProjectFormsTabsList from "./projectForms/TabsList";
import AuditLog from "../auditLog/AuditLog";
import useOrganisationSetting from "../hooks/useOrganisationSetting";
import InterEntityInvoices from "./InterEntityInvoices";
import { useParams } from "react-router-dom";
import CheckFilesSynced from "./CheckFilesSynced";
import Communications from "../communicationLogs/Communications";

const ShowPage = (props) => {
  const { project } = props;

  const { page, number } = useParams();

  const { user } = useAuth();

  const isPositionAdmin = user.is_position_admin;

  const setting = useOrganisationSetting("project_managers_can_add_allowances");

  const canViewOverview = user.hasAccessTo(
    "App\\Models\\Project",
    "view_overview",
  );

  const canAddAllowances = user.hasAccessTo(
    "App\\Models\\Project",
    "add_allowances",
  );

  const cantSeeDocuments =
    !user.is_admin &&
    project.is_confidential &&
    !project.team.map(({ id }) => id).includes(user.id);

  switch (page) {
    case "main":
      return ((canViewOverview && isPositionAdmin) ||
        user.id === props?.project?.project_manager) &&
        !project.is_template ? (
        <Overview {...props} />
      ) : (
        <NotFound />
      );
    case "details":
      return <ProjectDetail initialValues={props.project} {...props} />;
    case "documents":
      return cantSeeDocuments ? (
        <NotFound />
      ) : (
        <ProfileDocuments
          {...props}
          model="App\Models\Project"
          documentableId={number}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/projects/${number}`}
          homeUrl={`projects/${number}/documents`}
          extraInfo={<CheckFilesSynced />}
        />
      );
    case "jobs":
      return <ProjectJobsTabsList {...props} />;
    case "deliverables":
      return isPositionAdmin && !project.is_template ? (
        <ProjectDeliverables />
      ) : (
        <NotFound />
      );
    case "invoices":
      return !props.project.internal &&
        isPositionAdmin &&
        !project.is_template ? (
        <Invoices {...props} />
      ) : (
        <NotFound />
      );
    case "purchases":
      return isPositionAdmin && !project.is_template ? (
        <ProjectPurchases {...props} />
      ) : (
        <NotFound />
      );
    case "allowances":
      return (user.is_admin ||
        (user.id == props.project.project_manager && setting?.meta_value) ||
        canAddAllowances) &&
        !project.is_template ? (
        <ProjectAllowances />
      ) : (
        <NotFound />
      );
    case "equipment":
      return !project.is_template ? (
        <ProjectEquipment project={props.project} />
      ) : (
        <NotFound />
      );
    case "emails":
      return !project.is_template ? (
        <ProjectEmails
          messagableId={props.project.id}
          messagableType="App\\Models\\Project"
        />
      ) : (
        <NotFound />
      );
    case "audit-log":
      return isPositionAdmin ? (
        <AuditLog subjectType="App\Models\Project" uuid={number} {...props} />
      ) : (
        <NotFound />
      );
    case "project-forms":
      return !project.is_template ? (
        <ProjectFormsTabsList {...props} />
      ) : (
        <NotFound />
      );
    case "inter-entity-invoices":
      return <InterEntityInvoices project={project} />;
    case "communications":
      return project.internal ? (
        <NotFound />
      ) : (
        <Communications
          communicableId={project.id}
          communicableType="App\Models\Project"
          initialClientUuid={project.client.uuid}
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowPage;
