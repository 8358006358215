import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { BiTrash, BiUpload } from "react-icons/bi";
import { WrappedFieldProps } from "redux-form";
import getFileIcon from "../utils/getFileIcon";
import TextButton from "../utils/TextButton";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { toast } from "react-toastify";

function formatFileSize(bytes: number): string {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

const UploadMultiple = (
  props: WrappedFieldProps & {
    label?: string;
  },
) => {
  const { input } = props;

  const { takeAction } = useApi();

  const onDrop = useCallback((acceptedFiles: any[]) => {
    input.onChange([...(input.value ?? []), ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 20,
  });

  //   const { upload, files, setFiles } = useUploadMultipleToS3(
  //     `/users/${user?.uuid}/to-do-tasks/attachments`,
  //   );

  return (
    <>
      <div
        style={{
          border: "1px dashed #ccc",
        }}
        className={`d-flex align-items-center justify-content-center p-3 tn-300 rounded-lg ${
          isDragActive ? "bg-gray-200" : "bg-white"
        }`}
        {...getRootProps()}
      >
        <div className="text-center">
          <BiUpload className="tx-22" />
          <p className="text-muted mb-0 tx-12">Drag and drop files here</p>
        </div>
        <input {...getInputProps()} />
      </div>
      <div className="row mt-3">
        {input.value &&
          input.value?.map((file: any) => {
            return (
              <div className="col-lg-6 col-md-6 col-12 mb-3">
                <div className="d-flex space-x-3 align-items-center p-3 tn-300 rounded-lg shadow-sm bg-white border">
                  <i className={getFileIcon(file.name, false)} />
                  <div>
                    <p className="mb-0 tx-12 text-dark">{file.name}</p>
                    <p className="tx-10 mb-0">
                      {formatFileSize(file.size ?? file.file_size)}
                    </p>
                  </div>
                  <TextButton
                    title="Delete"
                    className="ms-auto"
                    onClick={() => {
                      const currentValue = input.value;

                      input.onChange(
                        input.value.filter((f: File) => {
                          if (file.uuid) {
                            /** @ts-ignore */
                            return f.uuid !== file.uuid;
                          }

                          return f.name !== file.path;
                        }),
                      );

                      if (file.uuid) {
                        takeAction("destroy", `documents/standard/${file.uuid}`)
                          .then(() => {
                            toast.success("File deleted");
                          })
                          .catch((err: any) => {
                            errorSwal(err);

                            input.onChange(currentValue);
                          });
                      }
                    }}
                  >
                    <BiTrash className="text-danger" />
                  </TextButton>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default UploadMultiple;
