import { useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { setDayRange } from "../../actions/projectOverviewDateRangeActions";
// import ProjectGeoFence from "../maps/ProjectGeoFence";
import Prices from "../prices/Prices";
import Tabs from "../tabs/Tabs";
import Costs from "./Costs";
import OverviewProductivity from "./OverviewProductivity";
import ProjectAggregates from "./ProjectAggregates";
import ProjectFeedback from "./ProjectFeedback";
import ProjectHazardReports from "./ProjectHazardReports";

const Overview = (props) => {
  const [allDates, setAllDates] = useState(false);

  //
  console.log(props.project);

  const tabs = [
    {
      title: "Overview",
      component: <Costs {...props} allDates={allDates} />,
    },

    {
      title: "Productivity",
      component: (
        <OverviewProductivity
          {...props}
          modelId={props.project.id}
          modelType="App\Models\Project"
          allDates={allDates}
        />
      ),
    },
    {
      title: "Aggregates",
      component: (
        <ProjectAggregates
          project={props.project}
          from={props.from}
          to={props.to}
        />
      ),
    },
    {
      title: "Safety Initiatives",
      component: <ProjectHazardReports project={props.project} />,
    },
  ];

  if (!props.project.internal) {
    tabs.push({
      title: "Prices",
      component: (
        <Prices
          chargeableType="App\Models\Project"
          chargeableId={props.project.id}
          duplicate
          showPricingPercent
          initialValues={{
            markup_percent: props.project.markup_percent,
          }}
        />
      ),
    });
    tabs.push({
      title: "Feedback",
      component: <ProjectFeedback project={props.project} />,
    });

    // tabs.push({
    //   title: "Geo Fencing",
    //   component: <ProjectGeoFence project={props.project} />,
    // });
  }

  return (
    <Tabs
      tabs={tabs}
      rightNav={(index) =>
        index !== 3 ? (
          <Button color="link" size="sm" onClick={() => setAllDates(!allDates)}>
            {allDates ? "Only View Date Range" : "View Entire Overview"}
          </Button>
        ) : null
      }
    />
  );
};

const mapStateToProps = (state) => ({
  from: state.projectOverviewDateRange.from,
  to: state.projectOverviewDateRange.to,
});

export default connect(mapStateToProps, { setDayRange })(Overview);
