import { useEffect, useState } from "react";
import CardDash from "../dashboard/CardDash";
import DashboardViews from "../dashboard/DashboardViews";
import fetchDashboard from "../utils/fetchDashboard";
import CustomScaleLoader from "../utils/scaleLoader";
import SpreadPrice from "../utils/SpreadPrice";
import DashboardCards from "./DashboardCards";
import { Button } from "reactstrap";

const Costs = ({ project, from, to, allDates }) => {
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});
  const [selectedView, setSelectedView] = useState("");

  const [advanced, setAdvanced] = useState(false);

  const fetch = () => {
    return fetchDashboard(
      "App\\Models\\Project",
      project.id,
      allDates ? null : from,
      allDates ? null : to,
      setDashboard,
      false,
    ).then(() => setLoading(false));
  };

  useEffect(() => {
    setSelectedView("");

    if ((from && to) || allDates) {
      setLoading(true);
      fetch();
    }
  }, [from, to, allDates]);

  const budget = [
    {
      label: "Approved Budget",
      value: <SpreadPrice price={project.estimated_value} />,
      color: "success",
      view: "budget",
    },
    {
      label: "Effort",
      value: <SpreadPrice price={dashboard.effort} />,
      color: "success",
      onClick: () => setSelectedView("effort"),
      view: "effort",
    },
    {
      label: "Budget Remaining",
      value: <SpreadPrice price={dashboard.budget_remaining} />,
      color: "success",
      onClick: () => setSelectedView("budget_remaining"),
      view: "budget_remaining",
    },
  ];

  const income = [
    {
      label: "Invoiced",
      value: <SpreadPrice price={dashboard.total_invoiced} />,
      color: "success",
      view: "invoiced",
      onClick: () => setSelectedView("invoiced"),
      view: "invoiced",
    },
    {
      label: "WIP",
      value: <SpreadPrice price={dashboard.second_wip} />,
      color: "success",
      view: "second_wip",
    },
    {
      label: "Backlog",
      value: <SpreadPrice price={dashboard.backlog} />,
      color: "success",
      view: "backlog",
    },
  ];

  const expenses = [
    {
      label: "Labour",
      value: <SpreadPrice price={dashboard.labour ?? 0} />,
      color: "danger",
      view: "labour",
      onClick: () => setSelectedView("labour"),
    },
    {
      label: "Expenses",
      value: <SpreadPrice price={dashboard.expense_cost} />,
      color: "danger",
      view: "expense_cost",
      onClick: () => setSelectedView("expense_cost"),
    },
  ];

  const costs = [
    {
      label: "Total Cost",
      value: <SpreadPrice price={dashboard.expense_cost + dashboard.labour} />,
      color: "danger",
      view: "total_cost",
    },
    {
      label: "Outstanding Purchase Orders",
      value: <SpreadPrice price={dashboard.upcoming_purchases} />,
      color: "danger",
      view: "upcoming_purchases",
    },
  ];

  const grossMargin =
    dashboard.total_invoiced - dashboard.expense_cost - dashboard.labour;

  const netRevenue = dashboard.total_invoiced - dashboard.expense_cost;

  const revenue = [
    {
      label: "Revenue",
      value: <SpreadPrice price={dashboard.total_invoiced} />,
      color: "success",
      view: "revenue",
    },
    {
      label: "Net Revenue",
      value: <SpreadPrice price={netRevenue} />,
      color: netRevenue > 0 ? "success" : "danger",
      view: "net_revenue",
    },
    {
      label: "Gross Margin",
      value: <SpreadPrice price={grossMargin} />,
      color: grossMargin > 0 ? "success" : "danger",
      view: "gross_margin",
    },
  ];

  const grossMarginPercent = (grossMargin / dashboard.total_invoiced) * 100;

  const nlm = (netRevenue / dashboard.labour) * 100;

  const percentages = [
    {
      label: "FPC%",
      value: (
        <SpreadPercent
          percent={(dashboard.effort / project.estimated_value) * 100}
        />
      ),
      color: "success",
      view: "fpc_percentage",
    },
    {
      label: "Gross Margin%",
      value: <SpreadPercent percent={grossMarginPercent} />,
      color: grossMarginPercent > 0 ? "success" : "danger",
      view: "gross_margin_percentage",
    },
    {
      label: "Net Labour Margin%",
      value: <SpreadPercent percent={nlm} />,
      color: nlm > 0 ? "success" : "danger",
      view: "net_labour_margin_percentage",
    },
  ];

  if (loading) {
    return <CustomScaleLoader>Fetching Overview...</CustomScaleLoader>;
  }

  return (
    <>
      <Button size="sm" outline onClick={() => setAdvanced(!advanced)}>
        {advanced ? "Hide" : "Show"} New Dashboard
      </Button>
      {advanced ? (
        <>
          <CardDash
            items={budget}
            selectedView={selectedView}
            outerClass="mt-3"
          />
          <CardDash
            selectedView={selectedView}
            items={income}
            outerClass="mt-3"
          />
          <CardDash
            selectedView={selectedView}
            items={expenses}
            outerClass="mt-3"
          />
          <CardDash
            selectedView={selectedView}
            items={costs}
            outerClass="mt-3"
          />
          <CardDash
            selectedView={selectedView}
            items={revenue}
            outerClass="mt-3"
          />

          <CardDash
            selectedView={selectedView}
            items={percentages}
            outerClass="mt-3"
          />
        </>
      ) : (
        <DashboardCards
          dashboard={dashboard}
          setSelectedView={setSelectedView}
          selectedView={selectedView}
          render={(toShow) => {
            return <CardDash key={toShow.name} {...toShow} outerClass="mt-3" />;
          }}
        />
      )}

      <DashboardViews
        from={from}
        to={to}
        ignoreDates={allDates}
        selected={{
          model: "App\\Models\\Project",
          value: project.id,
        }}
        view={selectedView}
      />
    </>
  );
};

const SpreadPercent = ({ percent }) => {
  return (
    <div className="d-flex">
      <span>%</span>
      <span className="ms-auto">{percent.toFixed(2)}</span>
    </div>
  );
};

export default Costs;
