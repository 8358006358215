import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import HoldsPopover from "../clients/HoldsPopover";
import OnHoldModal from "../clients/OnHoldModal";
import SideList from "../comments/SideList";
import SyncIntegrationEstimation from "../contacts/SyncIntegrationEstimation";
import HeaderPage from "../header/HeaderPage";
import useModal from "../hooks/useModal";
import ProfilePage from "../profile";
import {
  DashboardH3,
  DashboardHeader,
  DashboardSubTitle,
} from "../utils/DashboardHeader";
import DocLink from "../utils/DocLink";
import Spinner from "../utils/Spinner";
import DeleteSupplierButton from "./DeleteSupplierButton";
import MissingInsurances from "./MissingInsurances";
import PayableBadges from "./PayableBadges";
import ResendInvitation from "./ResendInvitation";
import ResendSupplierInsuranceButton from "./ResendSupplierInsuranceButton";
import ShowPage from "./ShowProfilePage";
import useUserGroup from "../hooks/useUserGroup";
import SyncEstimatedEntity from "./SyncEstimatedEntity";
import CopyButton from "../copyToOtherOrganisation/CopyButton";

const Profile = (props) => {
  const { user } = useAuth();
  const { uuid } = useParams();
  const { toggle, modal } = useModal();
  const isPositionAdmin = user.is_position_admin;

  const {
    data: supplier,
    setData: setSupplier,
    response,
    loading,
    refreshData,
  } = useApi(`suppliers/${uuid}`, {});

  const isPartOfAccounting = useUserGroup("Accounting");

  const canPutOnHold = user.is_admin || isPartOfAccounting;

  const tabs = [
    {
      link: `/suppliers/${uuid}/details`,
      label: "Details",
      page: "details",
      icon: "icon ion-clipboard text-capitalize",
    },
    {
      link: `/suppliers/${uuid}/documents`,
      label: "Documents",
      page: "documents",
      icon: "icon ion-clipboard text-capitalize",
    },
    {
      link: `/suppliers/${uuid}/insurances`,
      label: "Insurances",
      page: "insurances",
      icon: "icon ion-clipboard text-capitalize",
    },
    {
      link: `/suppliers/${uuid}/feedback`,
      label: "Feedback",
      page: "feedback",
      icon: "icon ion-clipboard text-capitalize",
    },
    {
      link: `/suppliers/${uuid}/purchases`,
      label: "Purchases",
      page: "purchases",
      icon: "icon ion-clipboard text-capitalize",
    },
    {
      link: `/suppliers/${uuid}/contacts`,
      label: "Contacts",
      page: "contacts",
      icon: "icon ion-person-stalker text-capitalize",
    },
    {
      link: `/suppliers/${uuid}/communications`,
      label: "Communication Log",
      page: "communications",
      icon: "fa fa-comments"
    },
  ];
  
  if (isPositionAdmin) {
    tabs.push({
      link: `/suppliers/${uuid}/audit-log`,
      label: "Audit Log",
      page: "audit-log",
      icon: "icon ion-clipboard text-capitalize",
    });
  }

  if (loading || !response) return <Spinner loading />;

  if (supplier.supplier_entity_id) {
    tabs.push({
      link: `/suppliers/${uuid}/projects`,
      label: "Projects",
      page: "projects",
      icon: "icon ion-clipboard text-capitalize",
    });
  }

  return (
    <>
      <HeaderPage
        titlePage={supplier.display_name}
        titleExtra={<DocLink to="supplier-information" />}
        crumbs={[
          { link: "/suppliers", name: "Suppliers" },
          {
            link: "/",
            name: supplier.display_name ?? "-",
            active: true,
          },
        ]}
      />

      <ProfilePage
        header={
          <DashboardHeader
            extraButtons={
              <ResendSupplierInsuranceButton
                supplier={supplier}
                setSupplier={setSupplier}
              />
            }
          >
            <DashboardH3>{supplier.display_name}</DashboardH3>
            <PayableBadges
              unpaid={supplier.accounts_payable_outstanding}
              overdue={supplier.accounts_payable_overdue}
            />
            {!supplier.form_filled_at && (
              <Badge className="mb-1" color="primary">
                Form Details not Filled
              </Badge>
            )}
            {supplier.hold && (
              <DashboardSubTitle>
                <Badge className="mb-1" color="danger">
                  On Hold
                </Badge>
              </DashboardSubTitle>
            )}
            {supplier.holds_count > 0 && (
              <DashboardSubTitle>
                <Button color="warning" size="sm" id="holds">
                  {supplier.display_name} has been put on hold{" "}
                  {supplier.holds_count} times.
                </Button>
                <HoldsPopover
                  holdableType="App\Models\Supplier"
                  holdableId={supplier.id}
                />
              </DashboardSubTitle>
            )}
            {supplier.user?.name && (
              <p className="mb-0">
                Added by{" "}
                <a href={`mailto:${supplier.user.email}`}>
                  {supplier.user.name}
                </a>
                .
              </p>
            )}
            <MissingInsurances supplier={supplier} />
            {supplier.insurances_last_sent_at && (
              <p className="mb-0">
                Insurance reminder last sent at{" "}
                {dayjs(supplier.insurances_last_sent_at).format(
                  "DD/MM/YYYY, hh:mma",
                )}
              </p>
            )}
          </DashboardHeader>
        }
        content={
          <>
            <SyncIntegrationEstimation
              contact={supplier}
              setContact={setSupplier}
            />
            <SyncEstimatedEntity
              refreshData={refreshData}
              supplier={supplier}
            />
            <ShowPage
              setSupplier={setSupplier}
              supplier={supplier}
              {...props}
            />
          </>
        }
        sideBar={
          <>
            <ResendInvitation />
            {canPutOnHold && (
              <div className="mt-3">
                <button
                  className="btn btn-outline-secondary w-100"
                  onClick={toggle}
                >
                  {supplier.hold ? "Remove Hold" : "Add Hold"}
                </button>
                <OnHoldModal
                  toggle={toggle}
                  modal={modal}
                  hold={supplier.hold}
                  onSuccess={refreshData}
                  holdable={{
                    holdable_id: supplier.id,
                    holdable_type: "App\\Models\\Supplier",
                  }}
                />
              </div>
            )}
            <CopyButton model="App\Models\Supplier" />
            <div className="mt-3">
              <DeleteSupplierButton supplier={supplier} />
            </div>
            <div className="mt-3">
              <SideList
                commentableType="App\Models\Supplier"
                commentableId={supplier.id}
              />
            </div>
          </>
        }
        tabs={tabs}
      />
    </>
  );
};

export default Profile;
