import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import NotFound from "../404";
import Details from "./Details";
import SupplierPurchases from "../purchases/SupplierPurchases";
import EntityFeedback from "../entityFeedback/EntityFeedback";
import ProfileDocuments from "../standardDocuments/ProfileDocuments";
import Insurances from "./Insurances";
import Projects from "./Projects";
import AuditLog from "../auditLog/AuditLog";
import SupplierContacts from "./SupplierContacts";
import Communications from "../communicationLogs/Communications";

const ShowProfilePage = (props) => {
  const { page, uuid } = useParams();
  
  const { user } = useAuth();
  const isPositionAdmin = user.is_position_admin;

  switch (page) {
    case "details":
      return <Details {...props} />;
    case "insurances":
      return <Insurances {...props} />;
    case "documents":
      return (
        <ProfileDocuments
          {...props}
          model="App\Models\Supplier"
          documentableId={uuid}
          documentPath={`organisations/${user.active_organisation.uuid}/documents/supppliers/${uuid}`}
          homeUrl={`suppliers/${uuid}/documents`}
        />
      );
    case "feedback":
      return (
        <EntityFeedback
          entity={{
            entity_type: "App\\Models\\Supplier",
            entity_id: props.supplier.id,
          }}
        />
      );
    case "purchases":
      return <SupplierPurchases />;
    case "contacts":
      return <SupplierContacts {...props} />;
    case "projects":
      return <Projects {...props} />;
    case "audit-log":
      return isPositionAdmin ? (
        <AuditLog subjectType="App\Models\Supplier" uuid={uuid} {...props} />
      ) : (
        <NotFound />
      );
    case "communications":
      return (
        <Communications
          communicableId={props?.supplier?.id}
          communicableType="App\Models\Supplier"
          initialClientUuid={props?.supplier?.uuid}
        />
      );
    default:
      return <NotFound />;
  }
};

export default ShowProfilePage;
