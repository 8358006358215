import React, { useEffect, useState } from "react";
import {
  Field,
  FieldArray,
  FormSubmitHandler,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import required from "../utils/required";
import RenderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import { useAuth } from "../../context/auth-context";
import SelectInput from "../form/SelectInput";
import employment_units from "../../data/employment_units";
import renderToggleInput from "../utils/renderToggleInput";
import StaffRoleType from "../employmentDetails/StaffRoleType";
import dateTime, { formatting } from "../utils/dateTime";
import employment_basis from "../../data/employment_basis";
import useApi from "../api/useApi";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import { Position } from "../positions/Position";
import FormHeader from "../utils/FormHeader";
import { money } from "../form/formatters";
import { connect } from "react-redux";
import formError from "../utils/formError";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import SubmitButton from "../utils/SubmitButton";
import HeaderPage from "../header/HeaderPage";
import AddressFields from "../addresses/AddressFields";
import { AboriginalEnum, GenderEnum } from "./userTypes";
import FormErrorAlert from "../form/FormErrorAlert";
import AsyncSelectInput from "../form/AsyncSelectInput";
import api from "../api/api";
import InformationAlert from "../utils/InformationAlert";
import confirm from "../utils/confirm";
import errorSwal from "../utils/errorSwal";
import dayjs from "dayjs";
import buttonGroup from "../utils/buttonGroup";
import EmployeeSuperFundTable from "./EmployeeSuperFundTable";
import SuperFundModal from "./SuperFundModal";
import { MdAdd } from "react-icons/md";
import DaysWorkedFields from "../employmentDetails/DaysWorkedFields";

interface NewUserFormProps {
  employmentBasis?: string;
  branchId?: number;
  isContractor?: boolean;
  requiresEmploymentDetails?: boolean;
}

const NewUserForm = (
  props: InjectedFormProps<any, NewUserFormProps> & NewUserFormProps,
) => {
  const {
    employmentBasis,
    branchId,
    handleSubmit,
    isContractor,
    requiresEmploymentDetails,
    change
  } = props;
  // const data = superFund.length > 0 ? superFund : [{ fund_name: "", abn: "", product_code: "", memberNumber: "", allocatedPercentage: "" }];
  const { user: authUser } = useAuth();

  const [allUsersToken, setAllUsersToken] = useState("");

  const history = useHistory();

  const { takeAction }: IUseApi = useApi();

  const [modal, setModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const toggle = () => setModal(!modal);

  const handleOpenModal = (row: any) => {
    setSelectedRow(row);
    toggle();
  };

  const [superFund, setSuperFund] = useState([
    { index: 1, fund_name: "", abn: "", product_code: "", memberNumber: "", allocatedPercentage: "" },
  ]);
  
  const modalOnSubmit = (values: any) => {
    const {
      index, fund_name, product_code, is_employer_nominated_fund, smsf
    } = values;
  
    const basePath = `employment_hero.superfund.superFund${index}`;
    change(`${basePath}_FundName`, fund_name);
    change(`${basePath}_ProductCode`, product_code);
    change(`${basePath}_EmployerNominatedFund`, is_employer_nominated_fund);
  
    if (product_code === "SMSF") {
      change(`employment_hero.superfund.smsf.${index}`, smsf);
    }

    setSuperFund((prevFunds) =>
      prevFunds.map((fund) =>
        fund.index === index ? { ...fund, ...values } : fund
      )
    );
  
    toggle();
  };

  const handleAddSuperFund = () => {
    const newIndex = superFund.length + 1;
    const newSuperFund = {
      index: newIndex,
      fund_name: "",
      abn: "",
      product_code: "",
      memberNumber: "",
      allocatedPercentage: ""
    };
    setSuperFund([...superFund, newSuperFund]);
  };

  const onSubmit: FormSubmitHandler<any> = (values) => {
    return takeAction(
      "store",
      `organisation-users/${currentUser[0]?.uuid ?? ""}`,
      values,
    )
      .then(() => {
        toast.success("User Invited Successfully");
        history.push("/users/invite");
      })
      .catch(formError);
  };

  const {
    takeAction: getAllUsersToken,
  }: IUseApi<any, { data: { uuid: string } }> = useApi();

  useEffect(() => {
    getAllUsersToken("store", `all-users`).then(({ data }) =>
      setAllUsersToken(data.data.uuid ?? ""),
    );
  }, []);

  const {
    data: positions,
    setUrl: setPositionUrl,
  }: IUseApiWithData<Position[]> = useApi("", []);

  const { data: currentUser, setUrl: setCurrentUserUrl } = useApi();

  const { data: roles, setUrl: setRoleUrl }: IUseApiWithData<any[]> = useApi(
    "",
    [],
  );

  useEffect(() => {
    if (branchId) {
      setPositionUrl(`branches/${branchId}/positions`);

      setRoleUrl(
        `organisation-roles?filter[organisation_branches.id]=${branchId}`,
      );
    }
  }, [branchId]);

  const isCurrentEmployee =
    currentUser?.length > 0 && currentUser[0].current_employee;

  const hasAccount =
    currentUser?.length > 0 && !currentUser[0].current_employee;

  return (
    <>
      <HeaderPage
        titlePage="Add New User"
        crumbs={[
          {
            name: "Invited Users",
            link: "/users/invite",
          },
          {
            name: "Add New User",
            link: "",
            active: true,
          },
        ]}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <FormErrorAlert error={props.error} />
          <FormHeader>Personal Details</FormHeader>
          <div className="col-lg-12 form-group">
            <Field
              name="email"
              type="email"
              component={RenderField}
              label="Email"
              validate={required}
              required
              onBlur={(e: any) => {
                if (!e.target.value) return;

                takeAction("store", "invite/user/check", {
                  email: e.target.value,
                }).catch((data) => {
                  if (data?.response?.status == 409) {
                    confirm({
                      title: "Employee Exists",
                      text: "We found a previous employee with this email, would you like to reinstate them?",
                      onConfirm: () =>
                        takeAction(
                          "store",
                          `organisation-users/${data.response.data.message}/reinstate`,
                        )
                          .then(() => {
                            toast.success(`User Reinstated Successfully`);
                            history.push("/users/invite");
                          })
                          .catch(errorSwal),
                    });
                  }
                });
                setCurrentUserUrl(
                  `all-users?filter[email]=${e.target.value}&uuid=${allUsersToken}`,
                );
              }}
            />
            {hasAccount && (
              <small>
                {currentUser[0].email} already has an account with theBOSSapp.
                The current account will be invited.
              </small>
            )}
          </div>

          {isCurrentEmployee ? (
            <div className="col-lg-12">
              <InformationAlert
                type="info"
                title="User Already Exists"
                body={`${currentUser[0].full_name} is already part of your organisation.`}
              />
            </div>
          ) : (
            <>
              {hasAccount ? null : (
                <>
                  <div className="col-lg-4 form-group">
                    <Field
                      name="first_name"
                      validate={required}
                      required
                      type="text"
                      component={RenderField}
                      label="First Name"
                    />
                  </div>
                  <div className="col-lg-4 form-group">
                    <Field
                      name="middle_name"
                      type="text"
                      component={RenderField}
                      label="Middle Name"
                    />
                  </div>
                  <div className="col-lg-4 form-group">
                    <Field
                      name="last_name"
                      type="text"
                      validate={required}
                      required
                      component={RenderField}
                      label="Last Name"
                    />
                  </div>
                  <div className="col-lg-4 form-group">
                    <Field
                      name="display_name"
                      type="text"
                      component={RenderField}
                      label="Display Name"
                      information={
                        <>
                          This is the name that will appear across the system
                          for the selected user.
                          <br />
                          <br />
                          It can be edited by admins and HR members to
                          accommodate users who prefer to use an English name or
                          a nickname.
                          <br />
                          <br />
                          Please confirm with the user before changing.
                          <br />
                          <br />
                          Note: This will not affect the user's official records
                          or login credentials
                        </>
                      }
                    />
                  </div>
                  <div className="col-lg-4 form-group">
                    <Field
                      component={SelectInput}
                      required
                      validate={required}
                      options={[
                        {
                          label: "Male",
                          value: GenderEnum.Male,
                        },
                        {
                          label: "Female",
                          value: GenderEnum.Female,
                        },
                        {
                          label: "Non-Binary",
                          value: GenderEnum.NonBinary,
                        },
                        {
                          label: "Prefer not to say",
                          value: GenderEnum.Unspecified,
                        },
                      ]}
                      name="gender"
                      label="Gender"
                    />
                  </div>
                  <div className="col-lg-4 form-group">
                    <Field
                      component={SelectInput}
                      required
                      validate={required}
                      options={[
                        {
                          label: "Aboriginal",
                          value: AboriginalEnum.Aboriginal,
                        },
                        {
                          label: "Torres Straight Islander",
                          value: AboriginalEnum.TorresStraightIslander,
                        },
                        {
                          label: "Neither",
                          value: AboriginalEnum.Neither,
                        },
                        {
                          label: "Prefer not to say",
                          value: AboriginalEnum.Unspecified,
                        },
                      ]}
                      name="aboriginal"
                      label="Aboriginal or Torres Strait Islander origin?"
                    />
                  </div>

                  <div className={`col-lg-6 form-group`}>
                    <Field
                      name="dob"
                      type="date"
                      component={RenderField}
                      required
                      validate={required}
                      label="Date of Birth"
                      extraProps={{
                        max: dayjs().subtract(15, "years").format("YYYY-MM-DD"),
                      }}
                    />
                  </div>
                  <div className="col-lg-6 form-group">
                    <Field
                      name="phone"
                      type="text"
                      component={RenderField}
                      label="Personal Phone Number"
                      required
                      validate={required}
                    />
                  </div>
                </>
              )}
              <div className="col-lg-6 form-group">
                <Field
                  name="organisation_user.work_phone"
                  type="text"
                  component={RenderField}
                  label="Work Phone Number"
                />
              </div>
              <div className="col-lg-6 form-group">
                <Field
                  name="organisation_user.employment_start_date"
                  required
                  validate={required}
                  component={RenderField}
                  label="Employment Start Date"
                  type="date"
                />
              </div>

              <div className="col-lg-4 form-group">
                <Field
                  component={renderToggleInput}
                  name="organisation_user.is_contractor"
                  label="User is Contractor"
                />
              </div>
              <div className="col-lg-8 form-group">
                <Field
                  name="organisation_user.default_branch_id"
                  type="text"
                  url="/organisation-branches"
                  component={SelectInput}
                  validate={required}
                  required
                  formatData={(data: any[]) => {
                    return data.map((d) => ({
                      label: d.name,
                      value: d.id,
                    }));
                  }}
                  label={`Default Branch for ${authUser?.active_organisation.display_name}`}
                />
              </div>

              {hasAccount ? null : (
                <>
                  <AddressFields {...props} postal />
                  <div className="col-12 d-flex align-items-center my-3">
                    <label className="section-title mt-0">
                      Payroll Details
                    </label>
                  </div>
                  {!isContractor && (
                    <>
                      <div className="col-lg-12 form-group">
                        <Field
                          component={RenderField}
                          name="employment_hero.taxFileNumber"
                          label="Tax File Number"
                          validate={required}
                          required
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <Field
                          component={renderToggleInput}
                          name="employment_hero.stslDebt"
                          label="STSL Debt"
                        />
                      </div>
                      <div className="col-lg-6 form-group">
                        <Field
                          component={renderToggleInput}
                          name="employment_hero.claimTaxFreeThreshold"
                          label="Claim Tax Free Threshold"
                        />
                      </div>
                      <div className="col-lg-4 form-group">
                        <Field
                          name="employment_hero.leaveTemplate"
                          url="/payroll-integration/leave-allowance-templates"
                          required
                          validate={required}
                          component={SelectInput}
                          label="Leave Allowance Template"
                          formatData={(data: any[]) =>
                            data.map((d) => {
                              return {
                                label: d.name,
                                value: d.name,
                              };
                            })
                          }
                        />
                      </div>
                      <div className="col-lg-4 form-group">
                        <Field
                          name="employment_hero.payschedule"
                          url="/payroll-integration/pay-schedules"
                          required
                          validate={required}
                          component={SelectInput}
                          label="Pay Schedule"
                          formatData={(data: any[]) =>
                            data.map((d) => {
                              return {
                                label: d.name,
                                value: d.name,
                              };
                            })
                          }
                        />
                      </div>
                      <div className="col-lg-4 form-group">
                        <Field
                          name="employment_hero.primaryLocation"
                          url="/payroll-integration/locations"
                          required
                          validate={required}
                          component={SelectInput}
                          label="Location"
                          formatData={(data: any[]) =>
                            data.map((d) => {
                              return {
                                label: d.name,
                                value: d.name,
                              };
                            })
                          }
                        />
                      </div>
                      <FormHeader>Bank Details</FormHeader>
                      <div className="col-lg-4 form-group">
                        <Field
                          name="employment_hero.bankAccount1_AccountName"
                          required
                          validate={required}
                          component={RenderField}
                          label="Bank Account Name"
                        />
                      </div>
                      <div className="col-lg-4 form-group">
                        <Field
                          name="employment_hero.bankAccount1_BSB"
                          required
                          validate={required}
                          component={RenderField}
                          label="Bank Account BSB"
                        />
                      </div>
                      <div className="col-lg-4 form-group">
                        <Field
                          name="employment_hero.bankAccount1_AccountNumber"
                          required
                          validate={required}
                          component={RenderField}
                          label="Bank Account Number"
                        />
                      </div>
                      <FormHeader>Super Details</FormHeader>
                      <div className="col-lg-12 form-group">
                        {superFund.length < 3 && (
                          <div className="tx-right mg-b-10">
                            <a className="btn btn-outline-primary mb-10" onClick={handleAddSuperFund}>
                              <MdAdd className="tx-18" /> Add
                            </a>
                          </div>
                        )}
                        <EmployeeSuperFundTable 
                          handleOpenModal={handleOpenModal} 
                          superFund={superFund} 
                          fieldNamePrefix="employment_hero.superfund"/>
                      </div>
                    </>
                  )}
                </>
              )}

              <div className="col-12 d-flex align-items-center my-3">
                <label className="section-title mt-0">Employment Details</label>
                <div className="ms-auto">
                  <Field
                    component={buttonGroup}
                    buttonClass="btn-sm"
                    name="requires_employment_details"
                    options={[
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                  />
                  <p className="me-2 mb-0 text-dark">
                    Requires Employment Details
                  </p>
                </div>
              </div>
              {requiresEmploymentDetails ? (
                <>
                  <div className="form-group col-lg-6">
                    <Field
                      name="employment_details.branch_id"
                      label="Branch"
                      component={SelectInputAsync}
                      url="/organisationbranches/branches"
                      required
                      validate={required}
                      hideDisabled={true}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <Field
                      name="employment_details.manager_id"
                      label="Manager"
                      component={SelectInputAsync}
                      url="/users/staff"
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <Field
                      name="employment_details.start_date"
                      label="Start Date"
                      component={dateTime}
                      required
                      validate={required}
                      {...formatting}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <Field
                      name="employment_details.finish_date"
                      label="Finish Date"
                      component={dateTime}
                      {...formatting}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <Field
                      name="employment_details.employment_basis"
                      label="Employment Basis"
                      component={SelectInput}
                      options={employment_basis}
                      required
                      validate={required}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <Field
                      name="employment_details.position_id"
                      label="Position"
                      component={SelectInput}
                      options={positions.map((p) => ({
                        label: p.name,
                        value: p.id,
                      }))}
                      required
                      validate={required}
                    />
                    {positions?.length === 0 && (
                      <small>
                        Unable to find any positions attached to the division of
                        the selected branch. Please ensure your organisation has
                        these created before continuing.
                      </small>
                    )}
                  </div>
                  <div className="form-group col-lg-4">
                    <Field
                      information={
                        <>
                          <p className="d-block mb-0">
                            This is the title that will be displayed for the
                            staff member if this is their primary role.
                          </p>
                          <br />
                          <p className="d-block mb-0">
                            Leave blank to use the position title as the staff
                            member's title.
                          </p>
                        </>
                      }
                      name="employment_details.title"
                      component={RenderField}
                      label="Title"
                    />
                  </div>

                  {employmentBasis?.includes("Part Time") && (
                    <FieldArray name="days" component={DaysWorkedFields} />
                  )}

                  {employmentBasis !== "Student Placement" && (
                    <>
                      <div className="form-group col-lg-4">
                        <Field
                          name="employment_details.chargeout_rate"
                          {...money}
                          component={RenderField}
                          label="Chargeout Rate (Per Hour)"
                        />
                      </div>
                      <div className="form-group col-lg-4">
                        <Field
                          name="employment_details.remuneration"
                          {...money}
                          component={RenderField}
                          label="Remuneration"
                          required
                          validate={required}
                        />
                      </div>
                      <div className="form-group col-lg-4">
                        <Field
                          name="employment_details.units"
                          type="text"
                          component={SelectInput}
                          options={employment_units}
                          label="Units"
                          required
                          validate={required}
                        />
                      </div>
                      <div className="form-group col-lg-6">
                        <Field
                          name="employment_details.award"
                          component={SelectInput}
                          label="Award"
                          url="/employment-awards"
                          required
                          validate={required}
                          formatData={(data: any[]) =>
                            data.map(({ abbreviation, id }) => ({
                              label: abbreviation,
                              value: id,
                            }))
                          }
                        />
                      </div>
                    </>
                  )}
                  <div className="form-group col-lg-6">
                    <Field
                      name="employment_details.remarks"
                      type="text"
                      component={RenderField}
                      label="Remarks"
                    />
                  </div>
                  <div className="form-group col-lg-12">
                    <Field
                      name="employment_details.overtime"
                      component={renderToggleInput}
                      label="Overtime"
                    />
                  </div>
                  <FieldArray
                    {...props}
                    roles={roles.map(({ organisationRole }) => ({
                      label: organisationRole.name,
                      value: organisationRole.id,
                    }))}
                    component={StaffRoleType}
                    name="employment_details.role_types"
                  />
                </>
              ) : (
                <div className="col-lg-12 form-group">
                  <Field
                    name="organisation_user.administration_level"
                    label="Access Level"
                    url="/enums?enum=AdministrationLevels"
                    component={SelectInput}
                  />
                </div>
              )}
              <div className="col-lg-12 form-group">
                <SubmitButton {...props} />
              </div>
            </>
          )}
        </div>
      </form>

      {selectedRow && (
        <SuperFundModal
          toggle={toggle}
          modal={modal}
          onSubmit={modalOnSubmit}
          row={selectedRow}
        />
      )}
    </>
  );
};

const validateAllocatedPercentage = (values: any) => {
  const errors: any = {
    employment_hero: {
      superfund: {},
    },
  };

  // Parse the allocated percentages, fallback to 0 if not present
  const allocated1 = parseFloat(values?.employment_hero?.superfund?.superFund1_AllocatedPercentage) || 0;
  const allocated2 = parseFloat(values?.employment_hero?.superfund?.superFund2_AllocatedPercentage) || 0;
  const allocated3 = parseFloat(values?.employment_hero?.superfund?.superFund3_AllocatedPercentage) || 0;

  const totalAllocated = allocated1 + allocated2 + allocated3;

  if (totalAllocated !== 100) {
    if (values?.employment_hero?.superfund?.superFund1_AllocatedPercentage !== undefined) {
      errors.employment_hero.superfund.superFund1_AllocatedPercentage = "The total allocated percentage must equal 100%";
    }
    if (values?.employment_hero?.superfund?.superFund2_AllocatedPercentage !== undefined) {
      errors.employment_hero.superfund.superFund2_AllocatedPercentage = "The total allocated percentage must equal 100%";
    }
    if (values?.employment_hero?.superfund?.superFund3_AllocatedPercentage !== undefined) {
      errors.employment_hero.superfund.superFund3_AllocatedPercentage = "The total allocated percentage must equal 100%";
    }
  }

  // Clean up empty objects (if there are no errors in superfund or employment_hero)
  if (Object.keys(errors.employment_hero.superfund).length === 0) {
    delete errors.employment_hero.superfund;
  }
  if (Object.keys(errors.employment_hero).length === 0) {
    delete errors.employment_hero;
  }

  // Return `undefined` or an empty object when no errors
  return Object.keys(errors).length === 0 ? undefined : errors;
};

const form = reduxForm<any, NewUserFormProps>({
  form: "NewUserForm",
  validate: validateAllocatedPercentage,
  initialValues: { requires_employment_details: true },
});

const selector = formValueSelector("NewUserForm");

const mapStateToProps = (state: any) => {
  return {
    branchId: selector(state, "employment_details.branch_id"),
    employmentBasis: selector(state, "employment_details.employment_basis"),
    isContractor: selector(state, "organisation_user.is_contractor"),
    requiresEmploymentDetails: selector(state, "requires_employment_details"),
  };
};

export default connect(mapStateToProps, {})(form(NewUserForm));
