import { useEffect, useState } from "react";
import WeekPicker from "../utils/WeekPicker";
import ReactTable from "../table/ReactTable";
import TimesheetSummaryFilters from "./TimesheetSummaryFilters";
import useApi from "../api/useApi";
import useFilter, { IFilter } from "../hooks/useFilter";
import dayjs from "dayjs";
import { ApprovalStatuses } from "../approvals/approvalTypes";
import { UserProductivity } from "./timesheetTypes";
import _ from "lodash";

export type TimeSheetSummaryFilterType = "status";

export type TimeSheetSummaryFilters = IFilter<
  TimeSheetSummaryFilterType,
  number | [string, string]
>[];

const TimesheetSummary = ({ userUuid }: { userUuid: string }) => {
  const [columns, setColumns] = useState([
    { accessorKey: "project_name", header: "Project" },
    { accessorKey: "role_name", header: "Role" },
  ]);

  const generateWeekDays = () =>
    Array.from({ length: 7 }, (_, i) =>
      dayjs().startOf("week").add(i, "day").toDate(),
    );

  const [currentWeekDays, setCurrentWeekDays] = useState<Date[]>(
    generateWeekDays(),
  );
  const [selectedFirstDayOfWeek, setSelectedFirstDayOfWeek] = useState(
    dayjs(currentWeekDays[0]).format("YYYY-MM-DD"),
  );
  const [selectedLastDayOfWeek, setSelectedLastDayOfWeek] = useState(
    dayjs(currentWeekDays[6]).format("YYYY-MM-DD"),
  );

  const { data, loading, setUrl } = useApi();

  const { filters, toggleFilter, filterCount, stringified } = useFilter<
    TimeSheetSummaryFilterType,
    number | [string, string]
  >(timesheetSummaryFilters);

  const generateWeekdayColumns = (weekDays: Date[]) =>
    weekDays.map((date) => {
      const dayName = dayjs(date).format("dddd");
      const formattedDate = dayjs(date).format("DD/MM/YYYY");

      return {
        accessorKey: `${dayName.toLowerCase()}_hours`,
        header: `${dayName} (${formattedDate})`,
        cell: ({ row }: { row: any }) => {
          const value = row.original[`${dayName.toLowerCase()}_hours`] || 0;

          return value === 0 ? "-" : value;
        },
      };
    });

  const groupByProject = (data: UserProductivity[]) => {
    return _.chain(data)
      .groupBy("project_id")
      .map((productivities) => {
        const weekDays = [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ];

        const weekDayBreakDown = weekDays.map((weekDay) => {
          const totalHours = _.sumBy(
            productivities.filter(
              (p) => dayjs(p.date).format("dddd").toLowerCase() === weekDay,
            ),
            "hours",
          );

          return {
            [`${weekDay}_hours`]: totalHours,
          };
        });

        return {
          project_name: productivities[0].project_name,
          role_name: _.chain(productivities)
            .groupBy("role_name")
            .map((roles) => roles[0].role_name)
            .value()
            .join(", "),
          total_hours: _.sumBy(productivities, "hours"),
          ...Object.assign({}, ...weekDayBreakDown),
        };
      })
      .value();
  };

  useEffect(() => {
    const weekdayColumns = generateWeekdayColumns(currentWeekDays);

    setColumns((prevColumns) => [
      ...prevColumns.slice(0, 3), // Keep the first three columns as is
      ...weekdayColumns,
      {
        accessorKey: "total_hours",
        header: "Total",
        cell: ({ row }: { row: any }) => {
          const totalHours = row.original.total_hours || 0;

          return totalHours === 0 ? "-" : totalHours;
        },
      },
    ]);

    setUrl(
      `/users/${userUuid}/productivity?filter[period]=${selectedFirstDayOfWeek},${selectedLastDayOfWeek}${stringified}`,
    );
  }, [
    currentWeekDays,
    selectedFirstDayOfWeek,
    selectedLastDayOfWeek,
    stringified,
  ]);

  const onWeekSelected = (weekDays: Date[]) => {
    setCurrentWeekDays(weekDays);
    setSelectedFirstDayOfWeek(dayjs(weekDays[0]).format("YYYY-MM-DD"));
    setSelectedLastDayOfWeek(dayjs(weekDays[6]).format("YYYY-MM-DD"));
  };

  const processedData = groupByProject(data ?? []);

  return (
    <>
      <div className="row mb-3">
        <div className="col-lg-6">
          <WeekPicker onWeekSelected={onWeekSelected} />
        </div>
        <div className="col-lg-6 tx-right">
          <TimesheetSummaryFilters
            filterCount={filterCount}
            filters={filters}
            toggleFilter={toggleFilter}
          />
        </div>
      </div>
      <ReactTable
        disableSearch
        columns={columns}
        data={processedData}
        loading={loading}
      />
    </>
  );
};

export const timesheetSummaryFilters: TimeSheetSummaryFilters = [
  {
    name: "status",
    label: "Approval Status",
    options: [
      { label: "Approved", value: ApprovalStatuses.APPROVED },
      { label: "Not Approved", value: ApprovalStatuses.PENDING },
    ],
  },
];

export default TimesheetSummary;
