import {
  Field,
  FieldArray,
  InjectedFormProps,
  WrappedFieldArrayProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import SelectInput from "../form/SelectInput";
import useApi from "../api/useApi";
import { IUseApi, IUseApiWithData } from "../api/apiTypes";
import { ChartOfAccount } from "../chartOfAccounts/chartOfAccountTypes";
import { BranchIndex } from "../branches/branchTypes";
import { Button, ButtonGroup } from "reactstrap";
import FormHeader from "../utils/FormHeader";
import filterOption from "../../utils/filterOption";
import { money } from "../form/formatters";
import isSubmitting from "../utils/submitting";
import FormErrorAlert from "../form/FormErrorAlert";
import { AccountsPayable } from "./types";
import { connect } from "react-redux";
import { useState } from "react";

export interface InboundPurchaseOrderFormProps {
  purchaseOrder: AccountsPayable;
}

const InboundPurchaseOrderForm = (
  props: InjectedFormProps<{}, InboundPurchaseOrderFormProps> &
    InboundPurchaseOrderFormProps,
) => {
  const { handleSubmit, purchaseOrder } = props;

  const { response, takeAction: checkNumber }: IUseApi = useApi();

  const { data: accounts }: IUseApiWithData<ChartOfAccount[]> = useApi(
    `chart-of-accounts?purchaseOrders=1`,
    [],
  );

  const { data: branches }: IUseApiWithData<BranchIndex[]> = useApi(
    `user-branches`,
    [],
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-lg-6 form-group">
          <Field
            name="date"
            component={RenderField}
            label="Date"
            required
            validate={required}
            type="date"
          />
        </div>
        <div className="col-lg-6 form-group" id="invoice_number">
          <Field
            name="number"
            component={RenderField}
            /** @ts-ignore */
            label="Supplier Invoice Number"
            required
            validate={required}
            onBlur={(_, number) => {
              checkNumber(
                "store",
                `purchase-invoices/${purchaseOrder.purchase_invoice?.uuid}/validate-number`,
                {
                  number,
                },
              );
            }}
          />
          {response?.response?.status === 422 && (
            <small className="tx-warning">
              {response?.response?.data.errors.number[0]}
            </small>
          )}
        </div>
        <FieldArray
          component={ConnectedInboundPurchaseItems}
          name="purchase_items"
          purchaseOrder={purchaseOrder}
          change={props.change}
          branches={branches.map((branch) => {
            return {
              label: branch.name,
              value: branch.id,
            };
          })}
          accounts={accounts.map((account) => {
            return {
              label: (
                <div>
                  <p className="mb-0 text-dark">{account.name}</p>
                  <p className="mb-0 text-muted">{account.code}</p>
                </div>
              ),
              value: account.id,
              text: `${account.name} ${account.code}`,
            };
          })}
        />
        <div className="col-12">
          <Button
            disabled={props.submitting}
            type="submit"
            color="primary"
            outline={!props.valid}
            block
          >
            {isSubmitting(props.submitting, "Save", "Saving...")}
          </Button>
        </div>
      </div>
    </form>
  );
};

const InboundPurchaseItems = ({
  fields,
  accounts,
  branches,
  items,
  purchaseOrder,
  change,
}: WrappedFieldArrayProps & {
  accounts: any[];
  branches: any[];
  items: any[];
  purchaseOrder?: AccountsPayable;
  change: Function;
}) => {
  const total = items?.reduce((acc, item) => {
    if (!item.quantity || !item.price) return acc;

    return acc + item.quantity * item.price;
  }, 0);

  const { data: jobs } = useApi(
    `custom-fields/jobs?filter[project_id]=${purchaseOrder?.project_id}`,
    [],
  );

  return (
    <>
      <FormHeader>
        Purchase Items (${total ? total.toFixed(2) : 0} -{" "}
        {purchaseOrder?.includes_gst_description})
      </FormHeader>
      {fields.map((member, index) => {
        return (
          <PurchaseItem
            fields={fields}
            member={member}
            index={index}
            accounts={accounts}
            branches={branches}
            change={change}
            jobs={jobs}
          />
        );
      })}
      <div className="col-12 form-group">
        <Button onClick={() => fields.push({})}>Add</Button>
      </div>
    </>
  );
};

const PurchaseItem = ({
  member,
  fields,
  index,
  accounts,
  branches,
  jobs,
  change,
}: any) => {
  const item = fields.get(index);

  const [assignedTo, setAssignedTo] = useState(
    item.branch_id ? "branch_id" : "job_id",
  );

  return (
    <>
      <div className="col-12">
        <p className="text-dark">Item {index + 1}</p>
      </div>
      <div className="col-lg-12 form-group">
        <Field
          label="Description"
          component={RenderField}
          name={`${member}.description`}
          required
          validate={required}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          label="Price"
          component={RenderField}
          name={`${member}.price`}
          required
          validate={required}
          {...money}
        />
      </div>
      <div className="col-lg-6 form-group">
        <Field
          label="Quantity"
          component={RenderField}
          name={`${member}.quantity`}
          required
          validate={required}
          type="number"
        />
      </div>
      <div className="col-lg-12 form-group">
        <Field
          label="Account Code"
          component={SelectInput}
          options={accounts}
          name={`${member}.chart_of_accounts_id`}
          required
          validate={required}
          filterOption={filterOption}
        />
      </div>
      <div className="col-lg-12 form-group">
        <div className="d-flex mb-2">
          <label className="tx-inverse tx-semibold mb-0">
            {item.branch_id ? "Branch" : "Job"}
          </label>
          <div className="d-flex ms-auto space-x-1  align-items-center">
            <p className="mb-0 tx-12">Assign To</p>
            <ButtonGroup size="sm">
              <Button
                color="primary"
                className="py-0"
                onClick={() => {
                  change(`${member}.job_id`, null);
                  setAssignedTo("branch_id");
                }}
                outline={assignedTo === "job_id"}
              >
                Branch
              </Button>
              <Button
                color="primary"
                className="py-0"
                onClick={() => {
                  change(`${member}.branch_id`, null);
                  setAssignedTo("job_id");
                }}
                outline={assignedTo === "branch_id"}
              >
                Job
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <Field
          component={SelectInput}
          options={
            assignedTo === "branch_id"
              ? branches
              : jobs.map((j: any) => ({
                  label: j.name,
                  value: j.id,
                }))
          }
          name={`${member}.${assignedTo}`}
          required
          validate={required}
        />
      </div>
      <div className="col-lg-12 form-group">
        <Button color="danger" onClick={() => fields.remove(index)}>
          Remove
        </Button>
      </div>
    </>
  );
};

const form = reduxForm<{}, InboundPurchaseOrderFormProps>({
  enableReinitialize: true,
});

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("inbound_purchase_order");

  return {
    items: selector(state, "purchase_items"),
  };
};

const ConnectedInboundPurchaseItems =
  connect(mapStateToProps)(InboundPurchaseItems);

export default form(InboundPurchaseOrderForm);
