import { FormSubmitHandler, InjectedFormProps } from "redux-form";
import AddTenderFields from "../tenders/AddTenderFields";
import FormModal from "../utils/FormModal";
import { Opportunity } from "./opportunityTypes";
import { Tender } from "../tenders/tenderTypes";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import formError from "../utils/formError";
import { toast } from "react-toastify";

const CreateTenderFromOpportunityModal = ({
  modal,
  toggle,
  opportunity,
  refreshData,
}: {
  modal: boolean;
  toggle: () => void;
  opportunity: Opportunity;
  refreshData: Function;
}) => {
  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<Tender> = (values) => {
    return takeAction(
      "store",
      `opportunities/${opportunity.uuid}/tenders`,
      values,
    )
      .then(({ data }) => {
        toggle();
        toast.success("Tender created successfully");
        refreshData();
      })
      .catch(formError);
  };

  return (
    <FormModal
      toggle={toggle}
      className="mx-wd-1000"
      modal={modal}
      title={`Create Tender ${
        opportunity.child_organisation_id
          ? `for ${opportunity.child_organisation?.display_name}`
          : ""
      }`}
      form="CreateTenderFromOpportunity"
      initialValues={{
        ...opportunity,
        tender: {
          project_name: opportunity.name,
          work_start_date: opportunity.work_start_date,
          work_finish_date: opportunity.work_finish_date,
          client_id: opportunity.client_id,
          client_name: opportunity.client_name,
          probability_of_project_starting:
            opportunity.probability_of_work_starting,
        },
      }}
      onSubmit={onSubmit}
    >
      {(props: InjectedFormProps) => {
        return (
          <AddTenderFields
            {...props}
            showProject={true}
            showSave={false}
            organisationId={opportunity.child_organisation_id}
          />
        );
      }}
    </FormModal>
  );
};

export default CreateTenderFromOpportunityModal;
