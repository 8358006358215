import { useParams } from "react-router-dom";
import { Opportunity, OpportunityPages } from "./opportunityTypes";
import EditOpportunity from "./EditOpportunity";

import NotFound from "../404";
import NewProfileDocuments from "../standardDocuments/ProfileDocuments";
import { useAuth } from "../../context/auth-context";

const ShowOpportunityPage = ({
  opportunity,
  setOpportunity,
}: {
  opportunity: Opportunity;
  setOpportunity: Function;
}) => {
  const { page, uuid } = useParams<{ page: OpportunityPages; uuid: string }>();

  const { user } = useAuth();

  switch (page) {
    case "details":
      return (
        <EditOpportunity
          opportunity={opportunity}
          setOpportunity={setOpportunity}
        />
      );
    case "documents":
      return (
        <NewProfileDocuments
          model="App\Models\Opportunity"
          documentableId={uuid}
          documentPath={`organisations/${user?.active_organisation.uuid}/documents/opportunities/${uuid}`}
          homeUrl={`opportunities/${uuid}/documents`}
        />
      );
    default: {
      return <NotFound />;
    }
  }
};

export default ShowOpportunityPage;
