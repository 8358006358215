import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { useAuth } from "../../context/auth-context";
import { formatNumber } from "../dashboard/OverviewCard";
import ReactTable from "../table/ReactTable";
import formatDate from "../utils/formatDate";
import InvoiceStatusBadge from "./InvoiceStatusBadge";
import getStatus from "./status";
import { RiPushpinLine, RiPushpinFill } from "react-icons/ri";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

const JobTable = ({
  jobs,
  selectedJob,
  setSelectedJob,
  showProject = false,
  showEstimate = true,
  sorting,
  setSorting,
  disablePinning = false,
  enableColumnToggle = false,
}) => {
  const { user } = useAuth();
  const { takeAction } = useApi();
  const isPositionAdmin = user.is_position_admin;
  const queryClient = useQueryClient();

  const togglePin = (info) => {
    const isPinned = info.is_pinned;

    return takeAction(
      isPinned ? "destroy" : "store",
      isPinned ? `user-pinned/${info.pinned_uuid}` : "user-pinned",
      {
        pinnable_type: "App\\Models\\ProjectJob",
        pinnable_id: info.id,
      },
    )
      .then(({ data, status }) => {
        queryClient.invalidateQueries("project-jobs");
      })
      .catch(errorSwal);
  };

  const columns = [
    {
      accessorKey: "full_name_custom_sort",
      header: "Job",
      cell: (info) => {
        const job = info.row.original;

        return (
          <>
            {job.organisation.id == user.active_organisation.id ? (
              <Link className="text-secondary" to={job.link}>
                {job.full_name}
              </Link>
            ) : (
              job.full_name
            )}
            {(job.is_overdue || job.critical_path) && (
              <div>
                {job.is_overdue ? (
                  <Badge className="me-1" color="danger">
                    Overdue
                  </Badge>
                ) : null}
                {job.critical_path ? (
                  <Badge color="danger">Critical Path</Badge>
                ) : null}
              </div>
            )}
          </>
        );
      },
    },
    {
      accessorKey: "manager.name",
      header: "Job Manager",
      cell: (info) => info.getValue(),
      enableSorting: false,
    },
    {
      accessorKey: "users",
      header: "Team Members",
      cell: (info) => {
        return info
          .getValue()
          ?.map((user) => user.label)
          .join(", ");
      },
      enableSorting: false,
    },
    {
      accessorKey: "date",
      header: "Finish Date",
      cell: (info) => finishDate(info.row.original),
    },
    {
      accessorKey: "estimated_value",
      header: "Estimated/Invoiced",
      cell: (info) =>
        `$${formatNumber(info.getValue())} / $${formatNumber(
          info.row.original.completed_value,
        )}`,
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (info) => {
        const job = info.row.original;

        return getStatus(job.status);
      },
      enableSorting: false,
    },
    {
      accessorKey: "invoice_status",
      header: "Invoice Status",
      cell: (info) => <InvoiceStatusBadge status={info.getValue()} />,
      enableSorting: false,
    },
    {
      accessorKey: "budget",
      header: "Budget",
      cell: (info) => {
        const job = info.row.original;

        if (
          job.completion_value > job.estimated_value ||
          job.effort_value > job.estimated_value
        ) {
          return (
            <Badge className="rounded-pill shadow" color="warning">
              Over Budget
            </Badge>
          );
        }

        return (
          <Badge className="rounded-pill shadow" color="success">
            On Budget
          </Badge>
        );
      },
    },
  ];

  if (!disablePinning) {
    columns.push({
      accessorKey: "pinned",
      header: "Pinned",
      cell: (info) => {
        const handlePinClick = (e) => {
          e.stopPropagation();
          togglePin(info.row.original);
        };

        const PinIcon = info.row.original.is_pinned
          ? RiPushpinFill
          : RiPushpinLine;

        return <PinIcon onClick={handlePinClick} className="pinIcon" />;
      },
      enableSorting: false,
    });
  }

  if (showProject) {
    columns.splice(0, 0, {
      accessorKey: "project.name",
      header: "Project",
      cell: (info) => {
        const job = info.row.original;
        return <Link to={`/${job.project.link}`}>{info.getValue()}</Link>;
      },
    });
  }

  const hasOtherOrgs = jobs.some(
    (job) => job.organisation.id != user.active_organisation.id,
  );

  if (hasOtherOrgs) {
    columns.splice(1, 0, {
      accessorKey: "organisation.name",
      header: "Organisation",
    });
  }

  let filteredColumns = isPositionAdmin
    ? columns
    : columns.filter(({ accessorKey }) => accessorKey !== "estimated_value");

  if (!showEstimate) {
    filteredColumns = filteredColumns.filter(
      ({ accessorKey }) =>
        accessorKey !== "estimated_value" && accessorKey !== "invoice_status",
    );
  }

  const rowClasses = (row) => {
    if (row.id === selectedJob?.id) {
      return "table-info";
    }

    if (row.organisation.id != user.active_organisation.id) {
      return "table-secondary";
    }
  };

  return (
    <ReactTable
      disableSearch
      columns={filteredColumns}
      data={jobs}
      onRowClick={(job) => {
        if (job.organisation.id != user.active_organisation.id) {
          toast.warning(
            "You are unable to copy jobs from other organisations.",
          );
          return;
        }
        setSelectedJob(job.id !== selectedJob?.id ? job : undefined);
      }}
      rowClasses={rowClasses}
      manualSorting={true}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
};

const finishDate = (job) => {
  if (job.actual_finish_date) {
    return `Actual: ${formatDate(job.actual_finish_date)}`;
  }
  return `Scheduled: ${formatDate(job.scheduled_finish_date)}`;
};

export default JobTable;
