import _ from "lodash";
import { useState } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import useApi from "../api/useApi";
import { Test } from "./testRequestTypes";
import { toast } from "react-toastify";
import errorSwal from "../utils/errorSwal";
import PurchaseModal from "../purchases/PurchaseModal";
import swal from "sweetalert";
import { useAuth } from "../../context/auth-context";
import calculateTotal from "../purchases/calculateTotal";
import uploadToS3 from "../upload/uploadToS3";
import AddToExistingPurchaseOrderModal from "./purchases/AddToExistingPurchaseOrderModal";
import deleteSwal from "../utils/deleteSwal";
import axios from "../api/api";
import { DropdownItem } from "reactstrap";

const checkTotals = (data: any) => {
  if (!data.total) {
    return Promise.resolve(true);
  }

  const total = calculateTotal(data.purchase_items);
  if (total !== data.total) {
    return swal({
      icon: "warning",
      title: "Totals don't match",
      text: `The total of the analyzed receipt (${data.total}) does not match the total you added in ${total}. Click OK to continue or cancel to re-enter information.`,
      buttons: [true, true],
    });
  }

  return Promise.resolve(true);
};

const TestAddPurchaseOrderButton = ({
  selectedTests,
  projectID,
  testRequestID,
  setSelectedTests,
  fetchTests,
}: {
  selectedTests: Test[];
  projectID: number;
  setSelectedTests: (tests: Test[]) => void;
  testRequestID: number;
  fetchTests: Function;
}) => {
  const { takeAction } = useApi();
  const [action, setAction] = useState("store");
  const { user } = useAuth();
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [url, setUrl] = useState("");
  const history = useHistory();
  const [displayModal, setDisplayModal] = useState("");
  const [addToExistingPurchaseOrderModal, setAddToExistingPurchaseOrderModal] =
    useState(false);

  const checkAndDisplayWarning = async (modal?: string) => {
    const hasPurchaseOrder = selectedTests.some(
      (test: Test) => test.purchase_order !== null,
    );

    if (hasPurchaseOrder) {
      try {
        const someOfSelectedHasAlreadySent = selectedTests.filter(
          (test: Test) =>
            test.purchase_order !== null && test.purchase_order.sent_at,
        );

        if (someOfSelectedHasAlreadySent.length > 0) {
          const groupTest = _.groupBy(someOfSelectedHasAlreadySent, "method");
          const testDescription = Object.keys(groupTest).map(
            (key) =>
              `Method ${key} for sample ${groupTest[key]
                ?.map((item) => item.sample_number_id)
                .join(", ")}`,
          );

          const formattedDescription =
            testDescription.length === 1
              ? testDescription[0]
              : `${testDescription.slice(0, -1).join(", ")} And ${
                  testDescription[testDescription.length - 1]
                }`;

          return swal({
            title: "Unable to add",
            text: `${formattedDescription} are already in a 'sent' status, so you can't add or move them to a new purchase order.`,
            icon: "warning",
          });
        }

        await handlePurchaseOrderRemoval();
        await handlePurchaseItemDeletion();
        await resetPurchaseOrderData();
        await handleModalToggle(modal);
        fetchTests();
      } catch (error) {
        errorSwal(error);
      }
    } else {
      await handleModalToggle(modal);
    }
  };

  const handlePurchaseOrderRemoval = async () => {
    await deleteSwal(
      "",
      "Some of the selected tests have already been added to an existing purchase order. If you wish to add them to a new purchase order or move them to another one, we will need to remove them from the existing purchase order. Would you like to proceed with removing these tests from the existing purchase order?",
    );
  };

  const handlePurchaseItemDeletion = async () => {
    const selectedTestsExistsInPurchaseOrder = selectedTests.filter(
      (test: Test) => test.purchase_order !== null,
    );

    for (const test of selectedTestsExistsInPurchaseOrder) {
      if (test.purchase_item?.quantity < 2) {
        await axios.delete(`/purchase-items/${test.purchase_item?.uuid}`);
      } else {
        const response = await axios.post(
          `/purchase-items/${test.purchase_item?.uuid}/tests`,
          {
            selectedTest: selectedTests?.map((test) => test.uuid),
          },
        );
      }
    }
  };

  const resetPurchaseOrderData = async () => {
    const cleanedTestSelected = selectedTests.map((test) => ({
      ...test,
      purchase_order: null,
    }));
    setSelectedTests(cleanedTestSelected);
  };

  const handleModalToggle = async (modal?: string) => {
    if (modal === "addToExisting") {
      toggleAddToExistingPurchaseOrderModal();
    } else {
      toggleTestSamplePurchaseOrder("purchase-orders");
    }
  };

  const toggleTestSamplePurchaseOrder = (newUrl: string) => {
    setPurchaseModal(!purchaseModal);
    setUrl(newUrl);
    setAction("store");
  };

  const toggleAddToExistingPurchaseOrderModal = () => {
    setAddToExistingPurchaseOrderModal(!addToExistingPurchaseOrderModal);
    setAction("update");
  };

  const buttons = [
    {
      text: (
        <>
          <i className="icon ion-plus tx-primary" /> Add Purchase Order
        </>
      ),
      onClick: checkAndDisplayWarning,
    },
    {
      text: (
        <>
          <i className="icon ion-plus tx-primary" /> Add To Existing Purchase
          Order
        </>
      ),
      onClick: () => checkAndDisplayWarning("addToExisting"),
    },
  ];

  if (selectedTests.length === 0) {
    return null;
  }

  const onSubmit = (values: any) => {
    return takeAction("store", "budget-check", values)
      .catch((err: any) => {
        if (err?.response?.data?.type === "over-budget") {
          return swal({
            title: "Over Budget",
            text: err?.response?.data?.message,
            icon: "warning",
            buttons: [true, true],
          });
        }
        errorSwal(err);
      })
      .then((val: any) => {
        if (val) {
          const extraData = values.site_order
            ? {
                sent_at: dayjs().format("YYYY-MM-DD"),
                sent_by: user?.id,
                received_at: dayjs().format("YYYY-MM-DD"),
                received_by: user?.id,
              }
            : {};

          return store({
            ...values,
            ...extraData,
          });
        }
      });
  };

  const store = (values: any) => {
    const { file, ...data } = values;

    return checkTotals(values)
      .then((value) => {
        if (!value) {
          return Promise.reject("bail");
        }

        return uploadToS3(
          file,
          `organisations/${user?.active_organisation.uuid}/${url}`,
        );
      })
      .then((documents) => {
        if (action == "update") {
          return takeAction(
            "update",
            `/purchase-orders/${values.uuid}`,
            values,
          );
        } else {
          return takeAction(action, "purchase-orders", {
            documents,
            ...data,
          });
        }
      })
      .then(({ data }) => {
        history.push(data.data.link);
        toast.success("Purchase added!");
      })
      .catch((err) => {
        if (err === "bail") {
          return;
        }

        errorSwal(err);
      });
  };

  const groupSelectedTest = _.groupBy(selectedTests, "method");
  return (
    <>
      {buttons.map((button) => {
        return (
          //@ts-ignore
          <DropdownItem onClick={button.onClick}>{button.text}</DropdownItem>
        );
      })}
      <AddToExistingPurchaseOrderModal
        toggle={toggleAddToExistingPurchaseOrderModal}
        modal={addToExistingPurchaseOrderModal}
        test_request_id={testRequestID}
        onSubmit={onSubmit}
        form="AddPurchase"
        title="Add New Purchase"
        fromModal={true}
        purchaseItems={Object.keys(groupSelectedTest).map((key) => ({
          method: key,
          tests: (groupSelectedTest[key] || []).map((item) => item.id),
          description: groupSelectedTest[key]
            ?.map((item) => item.sample_number_id)
            .join(", "),
          quantity: (groupSelectedTest[key] || []).length,
        }))}
      />
      <PurchaseModal
        toggle={toggleTestSamplePurchaseOrder}
        modal={purchaseModal}
        selectedTests={selectedTests}
        url={url}
        project={{
          id: projectID,
        }}
        initialValues={{
          project_id: projectID,
          test_request_id: testRequestID,
          purchase_items: Object.keys(groupSelectedTest).map((key) => {
            return {
              method: key,
              description:
                key +
                " for samples " +
                groupSelectedTest[key]
                  ?.map((item) => item.sample_number_id)
                  .join(", "),
              tests: groupSelectedTest[key]?.map((item) => item.id),
              quantity: groupSelectedTest[key].length,
            };
          }),
        }}
        onSubmit={onSubmit}
        form="AddPurchase"
        title="Add New Purchase"
      />
    </>
  );
};

export default TestAddPurchaseOrderButton;
