import dayjs from "dayjs";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import RecurringPurchaseModal from "../recurringPurchases/RecurringPurchaseModal";
import uploadToS3 from "../upload/uploadToS3";
import useAuthUser from "../user/hooks/useAuthUser";
import errorSwal from "../utils/errorSwal";
import ProfileButtons from "../utils/ProfileButtons";
import calculateTotal from "./calculateTotal";
import PurchaseModal from "./PurchaseModal";
import formError from "../utils/formError";

const checkTotals = (data) => {
  if (!data.total) {
    return Promise.resolve(true);
  }

  const total = calculateTotal(data.purchase_items);
  if (total != data.total) {
    return swal({
      icon: "warning",
      title: "Totals don't match",
      text: `The total of the analysed receipt (${data.total}) does not match the total you added in ${total}. Click OK to continue or cancel to re enter information.`,
      buttons: [true, true],
    });
  }

  return Promise.resolve(true);
};

const PurchaseButtons = (props) => {
  const [recurringModal, setRecurringModal] = useState(false);
  const {
    toggleModal,
    url,
    purchaseModal,
    selectedPurchase,
    setSelectedPurchase,
    project,
  } = props;
  const { user } = useAuth();

  const { takeAction } = useApi();
  const history = useHistory();

  const { data: authUser } = useAuthUser();

  const toggleRecurring = () => {
    setRecurringModal(!recurringModal);
  };

  const buttons = [
    {
      text: "Add Payment",
      onClick: () => toggleModal("credit-card-receipts"),
    },
    {
      text: "Add Purchase Order",
      onClick: () => toggleModal("purchase-orders"),
    },
    {
      text: "Add Contract Agreement",
      onClick: toggleRecurring,
    },
  ];

  const onSubmit = (values) => {
    if (url === "credit-card-receipts") {
      return store(values);
    }

    return takeAction("store", "budget-check", values)
      .catch((err) => {
        if (err?.response?.data?.type === "over-budget") {
          return swal({
            title: "Over Budget",
            text: err?.response?.data?.message,
            icon: "warning",
            buttons: [true, true],
          });
        }
        errorSwal(err);
      })
      .then((val) => {
        if (val) {
          const extraData = values.site_order
            ? {
                sent_at: dayjs().format("YYYY-MM-DD"),
                sent_by: user?.id,
                received_at: dayjs().format("YYYY-MM-DD"),
                received_by: user?.id,
              }
            : {};

          return store({
            ...values,
            ...extraData,
          });
        }
      });
  };

  const store = (values) => {
    const { file, ...data } = values;

    return checkTotals(values)
      .then((value) => {
        if (!value) {
          return Promise.reject("bail");
        }

        return uploadToS3(
          file,
          `organisations/${user.active_organisation.uuid}/${url}`,
        );
      })
      .then((documents) => {
        return takeAction("store", url, {
          documents,
          ...data,
        });
      })
      .then(({ data }) => {
        history.push(data.data.link);
        toast.success("Purchase added!");
      })
      .catch((err) => {
        if (err === "bail") {
          return;
        }

        return formError(err);
      });
  };

  const autoFillBranch =
    authUser?.settings?.auto_fill_purchase_branch ||
    authUser?.settings?.auto_fill_purchase_branch === undefined;

  return (
    <>
      <RecurringPurchaseModal
        toggle={toggleRecurring}
        modal={recurringModal}
        initialValues={{
          project_id: props.project.id,
          pricing: [
            { branch_id: autoFillBranch ? user.default_branch_id : null },
          ],
        }}
      />
      <PurchaseModal
        toggle={toggleModal}
        modal={purchaseModal}
        markup={project.markup_percent}
        url={url}
        initialValues={
          selectedPurchase ?? {
            project_id: props.project.id,
            purchase_items: [],
            include_markup_percent: true,
          }
        }
        onSubmit={onSubmit}
        form="AddPurchase"
        title="Add New Purchase"
        project={props.project}
      />
      <ProfileButtons buttons={addPlusIconToButtons(buttons)} />
    </>
  );
};

const addPlusIconToButtons = (buttons) => {
  return buttons.map((button) => {
    return {
      ...button,
      text: (
        <>
          {" "}
          <i className="icon ion-plus tx-primary" /> {button.text}
        </>
      ),
    };
  });
};

export default PurchaseButtons;
