import React, { useEffect, useState } from "react";
import {
  Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane, 
  Button, ModalFooter
} from "reactstrap";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import classnames from "classnames";
import required from "../utils/required";
import FormErrorAlert from "../form/FormErrorAlert";
import AsyncSelectInput from "../form/AsyncSelectInput";
import api from "../api/api";
import RenderField from "../utils/renderField";
import SelectInput from "../form/SelectInput";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import { IUseApi } from "../api/apiTypes";
import { useParams } from "react-router-dom";

interface SuperFundModalProps {
  toggle: () => void;
  modal: boolean;
  onSubmit: (values: any) => void;
  row: any;
}

interface SelfManagedFundResponse {
    data: {
        fundName: string; // Adjust based on the actual response structure
    };
}

const SuperFundModal: React.FC<SuperFundModalProps> = ({
  toggle,
  modal,
  onSubmit,
  row,
}) => {
  const [activeTab, setActiveTab] = useState("regulated");
  const { userId } = useParams<{ userId: string }>();
  const { takeAction }: IUseApi<{}, {}> = useApi();
  const { data: integrationSetting } = useApi(
    `payroll-integration/integration-settings`,
    null,
    true
  );

  useEffect(() => {
    if (row) {
      const tab = row.product_type === "SMSF"
      ? "self_managed"
      : row.is_employer_nominated_fund
      ? "employer_nominated_fund"
      : "regulated";
  
      setActiveTab(tab);
    }
  }, [row]);

  const toggleTab = (tab: string) => {
    setActiveTab((prev) => (prev !== tab ? tab : prev));
  };
      
  const handleRegulatedSubmit = (values: Record<string, any>) => {
    onSubmit({
      ...values,
      is_employer_nominated_fund: false,
    });
  };

  const handleSelfManagedSubmit = (values: Record<string, any>) => {
    if (userId === undefined) {
      onSubmit({
        index: values.index,
        product_code: "SMSF",
        abn: values.abn,
        fund_name: values.fund_name,
        is_employer_nominated_fund: false,
        smsf: values
      });
    } else {
      takeAction("store", `payroll-integration/self-managed-super-fund/${userId}`, values)
        .then(({ data }) => {
          const { fundName } = (data as SelfManagedFundResponse).data;

          onSubmit({
            ...values,
            product_code: "SMSF",
            fund_name: fundName,
            allocate_balance: row.allocate_balance,
            is_employer_nominated_fund: false,
            allocated_percentage: row.allocated_percentage,
            id: row.id,
          });
        })
        .catch(formError);
    }
  };

  const handleEmployerNominatedFundSubmit = (values: Record<string, any>) => {
    onSubmit({
      ...values,
      product_code: integrationSetting.employer_nominated_product_code,
      fund_name: integrationSetting.employer_nominated_fund_name,
      allocate_balance: row.allocate_balance,
      allocated_percentage: row.allocated_percentage,
      is_employer_nominated_fund: true,
      id: row.id,
    });
  };

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-dialog modal-lg">
      <ModalHeader toggle={toggle}>Super Fund</ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "regulated" })}
              onClick={() => toggleTab("regulated")}
            >
              Regulated
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "self_managed" })}
              onClick={() => toggleTab("self_managed")}
            >
              Self Managed
            </NavLink>
          </NavItem>
          {(integrationSetting?.employer_nominated_fund_name && integrationSetting?.employer_nominated_product_code) && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "employer_nominated_fund" })}
                onClick={() => toggleTab("employer_nominated_fund")}
              >
                Employer Nominated Fund
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="regulated">
            <RegulatedForm onSubmit={handleRegulatedSubmit} initialValues={row} />
          </TabPane>

          <TabPane tabId="self_managed">
            <SelfManagedForm onSubmit={handleSelfManagedSubmit} initialValues={row.superProduct == undefined ? row:row.superProduct}/>
          </TabPane>
          
          <TabPane tabId="employer_nominated_fund">
            <EmployerNominatedFundForm onSubmit={handleEmployerNominatedFundSubmit} initialValues={row} integrationSetting={integrationSetting}/>
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

interface SuperProduct {
  id?: number;
  abn?: string;
  account_name?: string;
  account_number?: string;
  bsb?: string;
  electronic_service_address?: string;
  external_id?: string;
  fund_email?: string;
  fund_name?: string;
}

interface SuperFund {
  id?: number;
  abn: string;
  allocate_balance: boolean;
  allocated_percentage: number;
  fund_name: string;
  is_employer_nominated_fund: boolean;
  member_number: string;
  product_code: string;
  product_type: string;
  superProduct: SuperProduct;
}

const RegulatedForm = reduxForm<SuperFund>({
  form: "regulatedForm",
})(({ handleSubmit, error, initialValues, change }: InjectedFormProps<SuperFund>) => (
  <form onSubmit={handleSubmit}>
    <div className="row mt-3">
      <FormErrorAlert error={error} />
      <div className="form-group col-12">
        <Field
          name="product_code"
          component={AsyncSelectInput}
          label="Super Fund"
          validate={required}
          defaultOptions={
            initialValues?.fund_name
              ? [
                  {
                    label: (
                      <div>
                        <p className="mb-0 text-dark">{initialValues?.fund_name}</p>
                        <small>{initialValues?.product_code}</small>
                      </div>
                    ),
                    value: initialValues?.product_code,
                    fundName: initialValues?.fund_name,
                  },
                ]
              : []
          }
          asyncFunction={(inputValue: string) =>
            api
              .get(`/payroll-integration/super-funds?term=${inputValue}`)
              .then(({ data }) =>
                data.data.map((d: any) => ({
                  label: (
                    <div>
                      <p className="mb-0 text-dark">{d.productName}</p>
                      <small>{d.productCode}</small>
                    </div>
                  ),
                  value: d.productCode,
                  fundName: d.productName,
                }))
              )
          }
          changeValue={(value: any) => change("fund_name", value.fundName)}
        />
      </div>
    </div>
    <ModalFooter>
      <Button color="primary" type="submit">
        Save
      </Button>
    </ModalFooter>
  </form>
));

// Self Managed Form
const SelfManagedForm = reduxForm({
  form: "selfManagedForm",
})(({ handleSubmit, error, change }: InjectedFormProps) => (
  <form onSubmit={handleSubmit}>
    <div className="row mt-3">
        <FormErrorAlert error={error} />
        <div className="form-group col-12">
          <Field
          component={RenderField}
          name="fund_name"
          label="Fund Name"
          required
          validate={required}
          />
        </div>
        <div className="form-group col-12">
          <Field
          component={RenderField}
          name="abn"
          label="ABN"
          required
          validate={required}
          />
        </div>
        <div className="form-group col-12">
          <Field
          component={RenderField}
          name="account_name"
          label="Account Name"
          required
          validate={required}
          />
        </div>
        <div className="form-group col-12">
          <Field
          component={RenderField}
          name="bsb"
          label="BSB"
          required
          validate={required}
          />
        </div>
        <div className="form-group col-12">
          <Field
          component={RenderField}
          name="account_number"
          label="Account Number"
          required
          validate={required}
          />
        </div>
        <div className="form-group col-12">
          <Field
            name="electronic_service_address"
            url="/payroll-integration/electronic-service-address"
            required
            validate={required}
            component={SelectInput}
            label="Electronic Service Address (ESA)"
            formatData={(data: any[]) =>
              data.map((d) => {
                return {
                    label: d.name,
                    value: d.name,
                };
              })
            }
          />
        </div>
        <div className="form-group col-12">
          <Field
            component={RenderField}
            name="fund_email"
            label="Fund Email"
            />
        </div>
        <div className="form-group col-12">
            <Field
              component={RenderField}
              name="external_id"
              label="External ID"
              />
        </div>
        </div>
    <ModalFooter>
      <Button color="primary" type="submit">
        Save
      </Button>
    </ModalFooter>
  </form>
));

interface IntegrationSetting {
  default_pay_category: number;
  public_holiday_pay_category: number;
  overtime_pay_category: number;
  business_id: number;
  employer_nominated_fund_name: string;
  employer_nominated_product_code: string;
}

interface EmployerNominatedFundFormProps {
  integrationSetting: IntegrationSetting;
}
const EmployerNominatedFundForm = reduxForm<{}, EmployerNominatedFundFormProps>({
        form: 'employerNominatedFund',
    }) (
    ({
      handleSubmit,
      error,
      integrationSetting,
    }: InjectedFormProps<{}, EmployerNominatedFundFormProps> & EmployerNominatedFundFormProps) => {
      return (
        <form onSubmit={handleSubmit}>
          <div className="row mt-3">
            <div className="form-group col-12">
              <FormErrorAlert error={error} />
              <label>
                <div className="row align-items-center">
                  <div className="col-3">
                    <Field component="input" type="radio" />
                  </div>
                  <div className="col-9">
                    <p className="mb-0 text-dark">
                      {integrationSetting?.employer_nominated_fund_name}
                    </p>
                    <small>{integrationSetting?.employer_nominated_product_code}</small>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <ModalFooter>
            <Button color="primary" type="submit">
              Save
            </Button>
          </ModalFooter>
        </form>
      );
    }
);


// Export Modal
export default SuperFundModal;