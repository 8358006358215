import { useState } from "react";
import JobTypeModal from "../jobs/JobTypeModal.jsx";
import ProjectProfileButtons from "../jobs/ProjectProfileButtons";
import RecurringJobs from "../recurringJobs";
import ProjectJobCalendar from "./ProjectJobCalendar";
import ProjectJobs from "./ProjectJobs";
import { Link, useHistory, useParams } from "react-router-dom";
import ProjectJobFolders from "../jobs/ProjectJobFolders";
import SendDetailsFormModal from "../clients/SendDetailsFormModal.jsx";
import useModal from "../hooks/useModal";
import Empty from "../utils/Empty";
import TextButton from "../utils/TextButton";
import useApi from "../api/useApi";
import errorSwal from "../utils/errorSwal";
import GanttChart from "./GanttChart";

const ProjectJobsTabsList = (props) => {
  const [modal, setModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");
  const { modal: SendDetailsModal, toggle: SendDetailsFormToggle } = useModal();
  const { number } = useParams();
  const { takeAction, loading } = useApi();
  const { project } = props;

  const toggle = () => setModal(!modal);

  const sendDetailsForm = (values) => {
    values = {
      ...values,
      email: values.email.replace(/ /g, "").split(","),
    };

    return takeAction(
      "store",
      `client-details-form/${project.client?.uuid}`,
      values,
    )
      .then(({ data }) => {
        setClient(data.data);
        toast.success(`${data.data.name} notified to fill out details`);
      })
      .catch(errorSwal);
  };

  if (!project.internal && project.client?.form_filled_at === null) {
    return (
      <div className="row">
        <div className="col-12">
          <Empty width="50%" top={false}>
            <p className="mt-5">
              Jobs are unavailable for {project.client.display_name} because
              client details have not been filled in.{" "}
              <TextButton onClick={() => SendDetailsFormToggle()}>
                Click here
              </TextButton>{" "}
              to resend the details form.
            </p>
          </Empty>
        </div>

        <SendDetailsFormModal
          title="Send Details Form"
          toggle={SendDetailsFormToggle}
          modal={SendDetailsModal}
          onSubmit={sendDetailsForm}
          form="SendDetailsForm"
          initialValues={{ email: project.client?.contact_email?.join(",") }}
        />
      </div>
    );
  }

  return (
    <>
      <div className="mg-b-0">
        <ProjectProfileButtons
          project={project}
          toggle={toggle}
          selectedJob={selectedJob}
        />
        <JobTypeModal
          {...props}
          project={project}
          projectUuid={number}
          modal={modal}
          toggle={toggle}
        />
      </div>
      <JobTabs
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
        project={project}
      />
    </>
  );
};

const JobTabs = ({ setSelectedJob, selectedJob, project }) => {
  const { number, folderUuid: jobPage } = useParams();

  return (
    <>
      <div className="nav-statistics-wrapper">
        <nav style={{ overflowX: "auto" }} className="nav flex-nowrap">
          <Link
            to={`/projects/${number}/jobs/list`}
            className={`nav-link mb-3 ${jobPage === "list" ? "active" : ""}`}
          >
            Jobs
          </Link>
          <Link
            to={`/projects/${number}/jobs/recurring-jobs`}
            className={`nav-link mb-3 ${
              jobPage === "recurring-jobs" ? "active" : ""
            }`}
          >
            Recurring Jobs
          </Link>
          <Link
            to={`/projects/${number}/jobs/folders`}
            className={`nav-link mb-3 ${jobPage === "folders" ? "active" : ""}`}
          >
            Job Folders
          </Link>
          <Link
            to={`/projects/${number}/jobs/calendar`}
            className={`nav-link mb-3 ${
              jobPage === "calendar" ? "active" : ""
            }`}
          >
            Calendar
          </Link>
          <Link to={`/projects/${number}/jobs/gantt`} className="nav-link">
            Gantt Chart
          </Link>
        </nav>
      </div>
      <JobTabPages
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
        project={project}
      />
    </>
  );
};

const JobTabPages = ({ selectedJob, setSelectedJob, project }) => {
  const { folderUuid: jobPage } = useParams();
  const history = useHistory();

  switch (jobPage) {
    case "list":
      return (
        <ProjectJobs
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
        />
      );
    case "recurring-jobs":
      return <RecurringJobs project={project} />;
    case "calendar":
      return <ProjectJobCalendar project={project} />;
    case "folders":
      return <ProjectJobFolders project={project} />;
    case "gantt":
      return <GanttChart />;
    default:
      return history.push(`/jobs/${jobPage}/details`);
  }
};

export default ProjectJobsTabsList;
