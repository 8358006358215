import { useState } from "react";
import { toast } from "react-toastify";
import { Field } from "redux-form";
import renderField from "../utils/renderField";
import deleteSwal from "../utils/deleteSwal";
import errorSwal from "../utils/errorSwal";
import ClipLoader from "react-spinners/ClipLoader";
import required, { isNumber } from "../utils/required";
import { primary } from "../utils/Colours";
import { remove } from "../utils/arrayUtils";
import axios from "../api/api";
import DeleteItemByTestModal from "./DeleteItemByTestModal";
import useApi from "../api/useApi";
import { useHistory } from "react-router-dom";
import SelectInput from "../form/SelectInput";
import { Button, ButtonGroup } from "reactstrap";
import { money } from "../form/formatters";

const ItemFields = ({
  member,
  index,
  fields,
  branches,
  accountCodes,
  initialValues,
  loading,
  disabled,
  siteOrder,
  isTestPurchaseOrder,
  fromModal,
  purchaseOrder,
  change,
  jobs,
}) => {
  const [deleting, setDeleting] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { takeAction } = useApi();
  const history = useHistory();
  const vals = fields.get(index);

  const [assignedTo, setAssignedTo] = useState(
    vals.branch_id ? "branch_id" : "job_id",
  );

  const deletePurchaseItem = async (purchaseItem, index) => {
    try {
      const confirmationMessage =
        fields.length < 2
          ? ` ${vals.description} will automatically archive this Purchase Order. Are you sure you want to proceed with the deletion?`
          : "";

      await deleteSwal(purchaseItem.description, confirmationMessage);
      setDeleting(true);

      await axios.delete(`/purchase-items/${purchaseItem?.uuid}`);
      toggleModal();
      toast.success("Item deleted!");

      if (fields.length < 2) {
        takeAction("store", `purchases/${purchaseOrder?.uuid}/archived`, {
          archive_reason: "Empty Line Items",
        })
          .then(() => {
            if (purchaseOrder?.test_request?.uuid) {
              history.push(
                `/testrequests/${purchaseOrder?.test_request?.uuid}/purchase-order`,
              );
            } else {
              history.push(
                `/projects/${purchaseOrder?.project?.uuid}/purchases`,
              );
            }
            toggleModal();
            toast.success("archived");
          })
          .catch((error) => {
            errorSwal(error);
          });
      }

      const newVals = remove(
        "uuid",
        initialValues?.purchase_items,
        purchaseItem,
      );

      fields.remove(index);

      setDeleting(false);
    } catch (error) {
      errorSwal(error);
    }
  };

  const deleteSelected = async (
    selectedTests,
    purchaseItemUuid,
    isAllSelected,
    purchaseItem,
  ) => {
    if (isAllSelected && purchaseItem?.uuid) {
      await deletePurchaseItem(purchaseItem, index);
    } else {
      try {
        const response = await axios.post(
          `/purchase-items/${purchaseItemUuid}/tests`,
          {
            selectedTest: selectedTests?.map((test) => test.uuid),
          },
        );
        fields.remove(index);
        fields.insert(index, response.data.purchaseItem);
        toggleModal();
      } catch (error) {
        errorSwal(error);
      }
    }
  };

  const deleteItem = async () => {
    if (vals.quantity > 1 && isTestPurchaseOrder) {
      setSelectedIndex(vals);
      toggleModal();
    } else {
      if (vals?.uuid) {
        await deletePurchaseItem(vals, index);
      } else {
        fields.remove(index);
      }
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  if (deleting) {
    return (
      <div className="mb-3 d-flex align-items-center">
        <ClipLoader color={primary} /> Deleting...
      </div>
    );
  }

  return (
    <>
      <div className="col-12 mb-2">
        <h6 className="tx-inverse mb-0">Item {index + 1}</h6>
        {vals?.over_budget_approval_action?.action_type === "Approve" && (
          <p className="tx-danger mb-0">Pending Approval</p>
        )}
      </div>

      <div className="col-lg-4 form-group">
        <Field
          label="Description"
          component={renderField}
          name={`${member}.description`}
          required
          validate={required}
          readOnly={isTestPurchaseOrder}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          label="Price"
          component={renderField}
          name={`${member}.price`}
          required={!siteOrder}
          validate={siteOrder ? isNumber : [required, isNumber]}
          {...money}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          label="Quantity"
          component={renderField}
          name={`${member}.quantity`}
          required={!siteOrder}
          validate={siteOrder ? isNumber : [required, isNumber]}
          readOnly={isTestPurchaseOrder}
        />
      </div>
      <div className="col-lg-4 form-group">
        <Field
          label="Account Code"
          component={SelectInput}
          options={accountCodes}
          name={`${member}.chart_of_accounts_id`}
          changeValue={(value) =>
            change(`${member}.chart_of_accounts_type`, value?.type)
          }
          required
          validate={required}
        />
      </div>

      <div className="col-lg-4 form-group">
        <div className="d-flex mb-2">
          <label className="tx-inverse tx-semibold mb-0">
            {assignedTo === "branch_id" ? "Branch" : "Job"}
          </label>
          <div className="d-flex ms-auto space-x-1  align-items-center">
            <p className="mb-0 tx-12">Assign To</p>
            <ButtonGroup size="sm">
              <Button
                color="primary"
                className="py-0"
                onClick={() => {
                  change(`${member}.job_id`, null);
                  setAssignedTo("branch_id");
                }}
                outline={assignedTo === "job_id"}
              >
                Branch
              </Button>
              <Button
                color="primary"
                className="py-0"
                onClick={() => {
                  change(`${member}.branch_id`, null);
                  setAssignedTo("job_id");
                }}
                outline={assignedTo === "branch_id"}
              >
                Job
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <Field
          component={SelectInput}
          isLoading={loading}
          options={
            assignedTo === "branch_id"
              ? branches
              : jobs.map((j) => ({
                  label: j.name,
                  value: j.id,
                }))
          }
          name={`${member}.${assignedTo}`}
          required
          validate={required}
        />
      </div>
      {/** Just Remove formModal to display this delete button in modal */}
      {!disabled && !fromModal && (
        <div className="col-lg-4 form-group d-flex align-items-end">
          <button
            type="button"
            className="btn btn-outline-danger w-100"
            onClick={deleteItem}
          >
            Delete
          </button>
        </div>
      )}

      {isTestPurchaseOrder && (
        <>
          <hr />
          {selectedIndex && (
            <DeleteItemByTestModal
              modal={modal}
              toggle={toggleModal}
              vals={selectedIndex}
              deleteSelected={deleteSelected}
            />
          )}
        </>
      )}
    </>
  );
};

export default ItemFields;
