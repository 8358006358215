import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import useOpportunities from "./hooks/useOpportunities";
import { Actions, Opportunity } from "./opportunityTypes";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import ReactTable from "../table/ReactTable";
import { IFilter } from "../hooks/useFilter";
import { money } from "../form/formatters";
import dayjs from "dayjs";
import { getStatusDetails } from "./OpportunityProfile";

const OpportunitiesList = () => {
  const columnHelper = createColumnHelper<Opportunity>();

  const columns = useMemo<ColumnDef<Opportunity, any>[]>(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => {
          const opportunity = info.row.original;

          return (
            <div className="d-flex align-items-center space-x-2">
              <Link
                to={`opportunities/${opportunity.uuid}/details`}
                className="mb-0 text-dark"
              >
                {info.getValue()}
              </Link>
            </div>
          );
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => {
          const statusDetails = getStatusDetails(info.row.original);

          return (
            <div
              className={`rounded-pill shadow ${statusDetails.color} d-inline-block text-white tx-10 text-center px-3 no-wrap`}
            >
              {statusDetails.text}
            </div>
          );
        },
      }),
      columnHelper.accessor("days_in_stage", {
        header: "Days in Stage",
      }),
      columnHelper.accessor("client", {
        header: "Client",
        enableSorting: false,
        cell: (info) => {
          const client = info.getValue();
          const opportunity = info.row.original;

          if (!client) {
            return (
              <span className="text-dark">
                {opportunity?.client_name ?? ""}
              </span>
            );
          }

          return (
            <Link
              to={`/clients/${client.uuid}/details`}
              className="mb-0 text-dark"
            >
              {info.getValue().display_name}
            </Link>
          );
        },
      }),
      columnHelper.accessor("industries", {
        header: "Markets",
        enableSorting: false,
        cell: (info) => {
          return info
            .getValue()
            .map((d: { name: string }) => d.name)
            .join(", ");
        },
      }),
      columnHelper.accessor("estimated_fee", {
        header: "Estimated Fee",
        cell: (info) => money.format(info.getValue()),
      }),
      columnHelper.accessor("job.manager", {
        enableSorting: false,
        header: "Manager",
        cell: (info) => (
          <a className="text-dark" href={`mailto:${info.getValue().email}`}>
            {info.getValue().name}
          </a>
        ),
      }),
      columnHelper.accessor("next_action", {
        header: "Next Action",
        cell: (info) => Actions[info.getValue()],
      }),
      columnHelper.accessor("work_start_date", {
        header: "Timing",
        cell: (info) => {
          const opportunity = info.row.original;

          if (!opportunity.work_start_date || !opportunity.work_finish_date) {
            return "-";
          }

          return `${dayjs(opportunity.work_start_date).format(
            "DD/MM/YYYY",
          )} - ${dayjs(opportunity.work_finish_date).format("DD/MM/YYYY")}`;
        },
      }),
      columnHelper.accessor("bid_team_involved", {
        header: "Bid Team Involved",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
      }),
    ],
    [],
  );

  return (
    <>
      <HeaderPage
        titlePage="Opportunities"
        crumbs={[
          {
            name: "Opportunities",
            link: "/opportunities",
            active: true,
          },
        ]}
      />

      <PaginatedList
        listName="opportunities"
        addLink="/opportunities/add"
        indexHook={useOpportunities}
        originalFilters={opportunityFilters}
        list={({ data, sorting, setSorting }) => {
          const opportunities = data as Opportunity[];

          return (
            <div className="col-12">
              <ReactTable<Opportunity>
                disableSearch
                data={opportunities}
                columns={columns}
                sorting={sorting}
                setSorting={setSorting}
                manualSorting={true}
              />
            </div>
          );
        }}
      />
    </>
  );
};

const opportunityFilters: IFilter<
  "status" | "bid_team_involved",
  number | boolean
>[] = [
  {
    label: "Stage",
    name: "status",
    options: [
      {
        label: "Identified",
        value: 1,
      },
      {
        label: "Positioning",
        value: 2,
      },
      {
        label: "Tendered",
        value: 3,
      },
    ],
  },
  {
    label: "Bid Team Involved",
    name: "bid_team_involved",
    options: [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
  },
];

export default OpportunitiesList;
