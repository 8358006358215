export type Opportunity = {
  id: number;
  uuid: string;
  organisation_id: number;
  client_id: number | null;
  project_id: number;
  number: number;
  prefix: string;
  padding: string;
  name: string;
  child_organisation_id?: number;
  status: number;
  estimated_fee: number;
  opportunity_manager_id: number;
  tender_start_date: string | null;
  tender_end_date: string | null;
  industry_id: number;
  notes: string | null;
  next_action: Actions;
  non_standard_opportunity_type_id: number | null;
  expected_award_date: string | null;
  win_probability: number | null;
  project_probability: number | null;
  work_start_date: string | null;
  work_finish_date: string | null;
  factored_fee: number | null;
  tender_id: number | null;
  tender?: {
    uuid: string;
    id: number;
    name: string;
    link: string;
  };
  bid_team_involved: boolean;
  bid_coordinator: number | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  industries: {
    uuid: string;
    name: string;
    id: number;
  }[];
  services: {
    uuid: string;
    name: string;
    id: number;
  }[];
  job: {
    project_manager: number;
    uuid: string;
    id: string;
    name: string;
    number: string;
    scheduled_start_date: string;
    scheduled_finish_date: string;
    description: string;
    manager: {
      uuid: string;
      name: string;
      email: string;
    };
  };
  days_in_stage: number;
  client?: {
    uuid: string;
    id: number;
    // name
    // trading_name
    display_name: string;
  };
  client_name?: string;
  probability_of_work_starting?: number;
  child_organisation?: {
    display_name: string;
    uuid: string;
  };
};

export enum Actions {
  "Invitation To Bid" = 1,
  "TBC" = 2,
  "Project Progression" = 3,
  "Client Decision" = 4,
}

export enum NonStandardOpportunityTypes {
  "Sole Source" = 1,
  "Panel" = 2,
  "Alliance" = 3,
  "PPP" = 4,
  "Direct Competitive" = 4,
}

export enum Stages {
  Identified = 1,
  Positioning = 2,
  Tendered = 3,
}

export type OpportunityPages = "details" | "documents";
