import { AxiosError } from "axios";
import { SubmissionError } from "redux-form";

const convertDotNotationToNested = (
  obj: Record<string, string[]>,
): Record<string, any> => {
  const result: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const parts = key.split(".");
    let current = result;

    parts.forEach((part, index) => {
      if (index === parts.length - 1) {
        // Last part - assign the array value
        current[part] = obj[key];
      } else {
        // Create nested object if doesn't exist
        current[part] = current[part] || {};
        current = current[part];
      }
    });
  });

  return result;
};

const formError = (err: AxiosError) => {
  if (err.response?.status === 422) {
    const errors = err.response.data.errors;

    const flattenedErrors = convertDotNotationToNested(errors ?? {});

    throw new SubmissionError({
      ...flattenedErrors,
      _error: err.response.data.message,
    });
  }

  if (err.response?.status) {
    throw new SubmissionError({
      _error: err.response.data.message,
    });
  }
};

export default formError;
