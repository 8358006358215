import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth-context";
import useApi from "../api/useApi";
import Notifications from "../notifications/Notifications";
import { primary } from "../utils/Colours";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";
import HelpDropdown from "./HelpDropdown";
import UserRecentOrganisation from "../organisation/UserRecentOrganisation";
import { useEffect } from "react";

const UserMenu = () => {
  const { user, setUser } = useAuth();
  const { takeAction, loading } = useApi();

  const history = useHistory();

  const {
    data: recentOrganisations,
    loading: loadingRecentOrganisation,
    refreshData,
  } = useApi(`users/recent-organisations/${user?.uuid}`, []);

  useEffect(() => {
    refreshData();
  }, [user.active_organisation.id]);

  const logout = () => {
    takeAction("store", "/logout")
      .then(() => {
        setUser(undefined);
        history.push("/login");
        toast.success("Logged out");
      })
      .catch(errorSwal);
  };

  const initials = user.name.match(/\b\w/g) || [];

  const organisationDisplayName = user.active_organisation.display_name;

  const isSelectOrganisation =
    history.location.pathname === "/organisations/select";

  return (
    <div className="slim-header-right">
      {user.timesheet_required ? null : (
        <>
          <HelpDropdown />
          <Notifications />
        </>
      )}
      <div className="dropdown dropdown-c">
        <a className="logged-user" data-toggle="dropdown" href="">
          <svg className="mg-r-10 pointer-hover" height="40" width="40">
            <circle cx="20" cy="20" r="20" fill={primary} />
            <text
              fill="#ffffff"
              fontSize="16"
              textAnchor="middle"
              x="20"
              y="25"
            >
              {initials}
            </text>
          </svg>
          <p className="mg-0 user-menu d-none d-lg-block">
            <span className="ms-0">
              {user.name}
              <i className="fa fa-angle-down d-inline-block ms-2" />
            </span>
            {!isSelectOrganisation && <small>{organisationDisplayName}</small>}
          </p>
        </a>
        <div
          className="dropdown-menu dropdown-menu-right header-dropdown"
          style={{ width: "230px" }}
        >
          <nav className="nav">
            {user.timesheet_required ? null : (
              <Link to={`/users/${user.uuid}/profile`} className="nav-link">
                <i className="icon ion-person" /> Profile
              </Link>
            )}
            {user.multiple_organisations && (
              <Link to="/organisations/select" className="nav-link">
                <i className="icon ion-navigate" /> Change Organisation
              </Link>
            )}
            <a
              href="javascript:void(0)"
              className="nav-link"
              disabled={loading}
              onClick={logout}
            >
              {isSubmitting(
                loading,
                <>
                  <i className="icon ion-forward" /> Sign Out
                </>,
                "Signing Out...",
              )}
            </a>
            {recentOrganisations.length > 0 && (
              <>
                <div className="border-top my-3" />
                <label class="section-label">Recent Organisations</label>
                <UserRecentOrganisation
                  recentOrganisations={recentOrganisations}
                />
              </>
            )}
            <div className="border-top my-3" />
            <Link to="/organisations/add" className="nav-link">
              <i
                className="icon fa fa-plus tx-primary"
                style={{ fontSize: "16px" }}
              />{" "}
              New Organisation
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.localStorage.boss_token,
});

export default connect(mapStateToProps, {})(UserMenu);
