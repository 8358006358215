import { Field, reduxForm, formValueSelector } from "redux-form";
import SelectInputAsync from "../utils/SelectInputAsync";
import INVOICE_TERMS from "../../data/invoice_terms";
import required from "../utils/required";
import RenderField from "../utils/renderField";
import { connect } from "react-redux";
import SubmitButton from "../utils/SubmitButton";
import useApi from "../api/useApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormHeader from "../utils/FormHeader";
import renderToggleInput from "../utils/renderToggleInput";
import AbnField from "../utils/AbnField";
import AddressFields from "../addresses/AddressFields";
import Fields from "../bankAccountDetails/Fields";
import SelectInput from "../form/SelectInput";
import { AiOutlineWarning } from "react-icons/ai";
import { useState } from "react";
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import AddMultiple from "../form/AddMultiple";
import InformationAlert from "../utils/InformationAlert";
import { useAuth } from "../../context/auth-context";
import formError from "../utils/formError";
import FormErrorAlert from "../form/FormErrorAlert";
import CustomForm from "../customFields/CustomForm";
import useUserGroup from "../hooks/useUserGroup";

const ProfileForm = (props) => {
  const {
    handleSubmit,
    invoice_terms,
    error,
    setSupplier,
    abn_holder,
    initialValues,
    direct_debit,
    type_id,
    supplier,
    isInternational,
    change,
  } = props;
  const { user } = useAuth();
  const [supplierType, setSupplierType] = useState();

  const { takeAction } = useApi();

  const isAccounting = useUserGroup("Accounting");

  const { uuid } = useParams();

  const submit = (values) => {
    if (values.direct_debit === true) {
      delete values.bank_details;
    }

    return takeAction(
      "update",
      initialValues?.form_filled_at
        ? `suppliers/${uuid}`
        : `pre-form-filled-suppliers/${uuid}`,
      values,
    )
      .then(({ data }) => {
        toast.success("Supplier Updated");
        return setSupplier(data.data);
      })
      .catch(formError);
  };

  return (
    <>
      {initialValues?.form_filled_at ? null : (
        <div className="mb-1">
          <InformationAlert
            type="warning"
            title="Supplier has not completed form."
            body="As this supplier has not yet completed their form the only field you are able to update is the contact email and type."
          />
        </div>
      )}
      <form onSubmit={handleSubmit(submit)}>
        <div className="row">
          <FormErrorAlert error={error} />
          {user.is_admin && (
            <>
              <FormHeader>Supplier Details</FormHeader>
              <div className="col-lg-12 form-group">
                <Field
                  component={RenderField}
                  name="name"
                  label="Name"
                  readOnly={abn_holder}
                />
              </div>
              <div className="col-lg-4 form-group">
                <Field
                  component={renderToggleInput}
                  name="abn_holder"
                  label="ABN Holder"
                  onChange={(_, checked) => {
                    if (checked) {
                      change("is_international", false);
                    }
                  }}
                />
              </div>
              <div className="col-lg-4 form-group">
                <Field
                  component={renderToggleInput}
                  name="direct_debit"
                  label="Direct Debit"
                />
              </div>
              <div className="col-lg-4 form-group">
                <Field
                  component={renderToggleInput}
                  name="is_international"
                  label="International"
                  onChange={(_, checked) => {
                    if (checked) {
                      change("abn_holder", false);
                    }
                  }}
                />
              </div>

              {abn_holder && (
                <>
                  <div className="col-lg-6 form-group">
                    <AbnField
                      {...props}
                      validate={initialValues?.form_filled_at}
                    />
                  </div>
                  <div className="col-lg-6 form-group">
                    <Field
                      component={RenderField}
                      name="trading_name"
                      label="Trading Name"
                    />
                  </div>
                  <div className="col-lg-6 form-group">
                    <Field
                      component={AddMultiple}
                      name="accounts_email"
                      label="Accounts Email/s"
                    />
                  </div>
                  <div className="col-12 form-group">
                    <Field
                      component={RenderField}
                      textarea
                      name="description"
                      label="Description"
                    />
                  </div>
                </>
              )}
              <div className="col-lg-6 form-group">
                <Field
                  component={AddMultiple}
                  name="contact_email"
                  label="Contact Email/s"
                  validate={required}
                  required
                />
                <small>
                  When sending notifications to this supplier, the first email
                  address in this list will be the address the email is sent to.
                </small>
              </div>
              <div className="col-lg-6 form-group">
                <Field
                  component={RenderField}
                  name="contact_telephone"
                  label="Contact Phone"
                />
              </div>
              <div className="col-lg-6 form-group">
                <Field
                  component={renderToggleInput}
                  name="is_subcontractor"
                  label="Subcontractor"
                />
              </div>
              <div className="col-lg-12 form-group">
                <Field
                  component={SelectInput}
                  url="supplier-types"
                  name="type_id"
                  label="Type"
                  formatData={(types) =>
                    types.map((type) => {
                      return { label: type.name, value: type.id, ...type };
                    })
                  }
                  changeValue={setSupplierType}
                />
                {type_id !== props.initialValues.type_id &&
                  supplierType &&
                  supplierType.insurances.length > 0 && (
                    <p className="tx-12 mt-2 mb-0">
                      <AiOutlineWarning
                        className="tx-16 me-1"
                        style={{ marginBottom: "6px" }}
                      />
                      Changing the supplier type will notify the supplier to re
                      upload required insurances
                      <Button
                        id="required_insurances"
                        type="button"
                        color="link"
                        className="p-0 ms-1"
                        style={{ marginBottom: "2px" }}
                      >
                        <i className="fa fa-info-circle tx-inverse tx-12" />
                      </Button>
                      <UncontrolledPopover
                        placement="auto"
                        target="required_insurances"
                      >
                        <PopoverHeader>
                          Required Insurances for {supplierType.name}
                        </PopoverHeader>
                        <PopoverBody className="white-space">
                          {supplierType.insurances.map((insurance) => {
                            return (
                              <p className="mb-2" key={insurance.uuid}>
                                {insurance.name}
                              </p>
                            );
                          })}
                        </PopoverBody>
                      </UncontrolledPopover>
                      .
                    </p>
                  )}
              </div>
              {!direct_debit && !isInternational && (
                <>
                  <FormHeader>Bank Details</FormHeader>
                  <Fields validate={false} prefix="bank_details" />
                </>
              )}
              {!isInternational && (
                <AddressFields validate={false} postal {...props} />
              )}
            </>
          )}
          {(isAccounting || user.is_admin) && (
            <>
              <FormHeader>Invoice Terms</FormHeader>
              <div
                className={`col-lg-${
                  invoice_terms != "0" ? "6" : "12"
                } form-group`}
              >
                <Field
                  component={SelectInputAsync}
                  label="Terms"
                  name="invoice_terms"
                  options={INVOICE_TERMS}
                />
              </div>
              {invoice_terms != "0" && (
                <div className="col-lg-6 form-group">
                  <Field
                    component={RenderField}
                    name="invoice_terms_amount"
                    label="Days"
                    type="number"
                  />
                </div>
              )}
              {supplier?.custom_form?.length > 0 && (
                <>
                  <FormHeader
                    extra={
                      <p className="mb-0">
                        This is information supplied by the supplier and is
                        unable to be edited
                      </p>
                    }
                  >
                    Extra Information
                  </FormHeader>
                  <CustomForm
                    customForm={supplier?.custom_form}
                    modelType="App\Models\Supplier"
                    modelId={supplier?.id}
                    {...props}
                  />
                </>
              )}
              <div className="col-lg-12">
                <SubmitButton {...props} />
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
};

const form = reduxForm({ form: "SupplierDetails" });

const selector = formValueSelector("SupplierDetails");

const mapStateToProps = (state) => ({
  invoice_terms: selector(state, "invoice_terms"),
  abn_holder: selector(state, "abn_holder"),
  direct_debit: selector(state, "direct_debit"),
  type_id: selector(state, "type_id"),
  isInternational: selector(state, "is_international"),
});

export default connect(mapStateToProps, {})(form(ProfileForm));
