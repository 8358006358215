import { Field, FormSubmitHandler, formValueSelector } from "redux-form";
import FormModal from "../utils/FormModal";
import StyledRadio from "../form/StyledRadio";
import { ReactComponent as Email } from "../../svgs/email.svg";
import { ReactComponent as Phone } from "../../svgs/phone_call.svg";
import { ReactComponent as Meeting } from "../../svgs/meeting.svg";
import { ReactComponent as SMS } from "../../svgs/sms.svg";
import { BiCheck } from "react-icons/bi";
import { connect } from "react-redux";
import SelectInput from "../form/SelectInput";
import PaginatedSelectInput from "../form/PaginatedSelectInput";
import { useEffect, useState } from "react";
import useApi from "../api/useApi";
import Avatar from "../utils/Avatar";
import { ClientContact } from "./ClientContacts";
import { IUseApiWithData } from "../api/apiTypes";
import RenderField from "../utils/renderField";
import UploadMultiple from "../form/UploadMultiple";
import useUploadMultipleToS3 from "../hooks/useUploadMultipleToS3";
import formError from "../utils/formError";
import { toast } from "react-toastify";
import required from "../utils/required";
import { useAuth } from "../../context/auth-context";
import { Button } from "reactstrap";
import errorSwal from "../utils/errorSwal";
import deleteSwal from "../utils/deleteSwal";

interface CommunicationFields {
  type: number;
  contact_id: number;
  details: string;
  documents: any[];
  communicable_type: string;
  communicable_id: number;
}

const CommunicationModal = ({
  toggle,
  modal,
  communicable,
  communicableType,
  initialClientUuid,
  onSuccess,
  initialValues,
  uuid,
}: {
  toggle: Function;
  modal: boolean;
  communicable?: {
    id: number;
    type: string;
  };
  communicableType?: string;
  initialClientUuid?: string;
  onSuccess?: Function;
  uuid?: string;
  initialValues?: {
    [key: string]: any;
  };
}) => {
  const [clientUuid, setClientUuid] = useState<string>(initialClientUuid ?? "");

  const { user } = useAuth();

  const { takeAction: deleteAction, loading: deleting } = useApi();

  const { upload } = useUploadMultipleToS3(
    `organisations/${user?.active_organisation.uuid}/communications`,
  );

  const { takeAction } = useApi();

  const { data, setUrl }: IUseApiWithData<ClientContact[]> = useApi("", []);

  const onSubmit: FormSubmitHandler<CommunicationFields> = (values) => {
    return upload(
      values.documents ? values.documents.filter((d) => !d.uuid) : [],
    )
      .then((documents) =>
        takeAction(
          uuid ? "update" : "store",
          uuid ? `communications/${uuid}` : "communications",
          {
            ...values,
            documents,
          },
        ),
      )
      .then(() => {
        toggle();
        toast.success("Communication added.");
        onSuccess?.();
      })
      .catch(formError);
  };

  useEffect(() => {
    if (clientUuid && communicableType) {
      const baseUrl = communicableType === "App\\Models\\Supplier" ? "suppliers" : "clients";
      setUrl(`${baseUrl}/${clientUuid}/contacts`);
    } else {
      setUrl("");
    }
  }, [clientUuid, communicableType]);

  return (
    <FormModal
      form="CommunicationModal"
      toggle={toggle}
      modal={modal}
      title={initialValues ? "Edit Communication" : "Add Communication"}
      onSubmit={onSubmit}
      extraFooterButtons={
        uuid
          ? [
              <Button
                onClick={() => {
                  deleteSwal("Communication")
                    .then(() =>
                      deleteAction("destroy", `communications/${uuid}`),
                    )
                    .then(() => {
                      toggle();
                      toast.success("Communication deleted");
                      onSuccess?.();
                    })
                    .catch((err: any) => {
                      errorSwal(err);
                    });
                }}
                color="danger"
              >
                Delete
              </Button>,
            ]
          : []
      }
      initialValues={
        initialValues ?? {
          communicable_type: communicable?.type,
          communicable_id: communicable?.id,
        }
      }
    >
      {({ change }: any) => {
        return (
          <>
            <div className="col-12">
              <label className="form-control-label tx-inverse tx-semibold">
                Type
              </label>
            </div>
            <Field
              component={StyledRadio}
              options={[
                {
                  value: 1,
                  label: "Email",
                  icon: Email,
                },
                {
                  value: 2,
                  label: "Phone Call",
                  icon: Phone,
                },
                {
                  value: 3,
                  label: "Meeting",
                  icon: Meeting,
                },
                {
                  value: 4,
                  label: "SMS",
                  icon: SMS,
                },
              ]}
              button={(props: any) => (
                <div className="col-lg-3 col-6 form-group">
                  <button
                    type="button"
                    className={`btn position-relative w-100 p-3 ${
                      props.active
                        ? "border-warning shadow-inner bg-gray-100"
                        : "border shadow-sm bg-white"
                    } rounded-lg d-flex align-items-center justify-content-center`}
                    onClick={props.onClick}
                  >
                    <div
                      style={{ right: 5, top: 5 }}
                      className={`position-absolute tn-300 ${
                        props.active ? "opacity-1" : "opacity-0"
                      }`}
                    >
                      <BiCheck className="tx-warning tx-26" />
                    </div>
                    <div className="text-center">
                      <props.icon
                        className={props.active ? "active" : ""}
                        height="100px"
                        width="100px"
                      />
                      <p className="mb-2 mt-3 text-dark fw-bolder">
                        {props.label}
                      </p>
                      <p className="mb-0 tx-12">{props.description}</p>
                    </div>
                  </button>
                </div>
              )}
              name="type"
            />
            {!communicable && (
              <>
                <div className="col-12">
                  <label className="form-control-label tx-inverse tx-semibold">
                    Entity Type
                  </label>
                </div>
                <Field
                  component={StyledRadio}
                  options={[
                    {
                      value: "App\\Models\\Project",
                      label: "Project"
                    },
                    {
                      value: "App\\Models\\Client",
                      label: "Client"
                    },
                    {
                      value: "App\\Models\\Supplier",
                      label: "Supplier"
                    }
                  ]}
                  onChange={() => {
                    setClientUuid("");
                    change("communicable_id", null);
                  }}
                  button={(props: any) => (
                    <div className="col-lg-3 col-6 form-group">
                      <button
                        type="button"
                        className={`btn position-relative w-100 p-3 ${
                          props.active
                            ? "border-warning shadow-inner bg-gray-100"
                            : "border shadow-sm bg-white"
                        } rounded-lg d-flex align-items-center justify-content-center`}
                        onClick={props.onClick}
                      >
                        <div
                          style={{ right: 5, top: 5 }}
                          className={`position-absolute tn-300 ${
                            props.active ? "opacity-1" : "opacity-0"
                          }`}
                        >
                          <BiCheck className="tx-warning tx-26" />
                        </div>
                        <div className="text-center">
                          <p className="mb-2 mt-3 text-dark fw-bolder">
                            {props.label}
                          </p>
                          <p className="mb-0 tx-12">{props.description}</p>
                        </div>
                      </button>
                    </div>
                  )}
                  name="communicable_type"
                  required
                  validate={required}
                />

                <div className="col-12 form-group">
                  {communicableType === "App\\Models\\Project" ? (
                    <Field
                      component={PaginatedSelectInput}
                      name="communicable_id"
                      label="Project"
                      url="/projects?filter[internal]=false"
                      key="project"
                      required
                      validate={required}
                      changeValue={(value: { client: { uuid: string } }) => {
                        setClientUuid(value.client.uuid);
                        change("contact_id", null);
                      }}
                      formatData={(data: any[]) => {
                        return data.map((d) => ({
                          label: (
                            <div>
                              <p className="mb-0 text-dark">{d.name}</p>
                              <small>{d.number}</small>
                            </div>
                          ),
                          value: d.id,
                          client: {
                            uuid: d.client.uuid,
                          },
                        }));
                      }}
                    />
                  ) : communicableType === "App\\Models\\Supplier" ? (
                    <Field
                      component={PaginatedSelectInput}
                      name="communicable_id"
                      required
                      validate={required}
                      label="Supplier"
                      key="supplier"
                      url="/suppliers?"
                      changeValue={(value: { uuid: string }) => {
                        setClientUuid(value.uuid);
                        change("contact_id", null);
                      }}
                      formatData={(data: any[]) => {
                        return data.map((d) => ({
                          label: d.name,
                          value: d.id,
                          uuid: d.uuid,
                        }));
                      }}
                    />
                  ) : (
                    <Field
                      component={PaginatedSelectInput}
                      name="communicable_id"
                      required
                      validate={required}
                      label="Client"
                      key="client"
                      url="/organisation-clients?"
                      changeValue={(value: { uuid: string }) => {
                        setClientUuid(value.uuid);
                        change("contact_id", null);
                      }}
                      formatData={(data: any[]) => {
                        return data.map((d) => ({
                          label: d.display_name,
                          value: d.id,
                          uuid: d.uuid,
                        }));
                      }}
                    />
                  )}
                </div>
              </>
            )}
            <div className="col-12 form-group">
              <Field
                component={SelectInput}
                label="Contact"
                name="contact_id"
                options={data.map((d) => ({
                  label: (
                    <div className="d-flex align-items-center">
                      <div className="me-2">
                        <Avatar rounded="-circle" name={d.name} colour="info" />
                      </div>
                      <div>
                        <p className="mb-0">{d.name}</p>
                        <p className="mb-0 fw-normal tx-12">
                          {d.description ?? d.email}
                        </p>
                      </div>
                    </div>
                  ),
                  value: d.id,
                }))}
              />
            </div>
            <div className="col-12 form-group">
              <Field
                component={RenderField}
                name="details"
                label="Details"
                textarea
                required
                validate={required}
              />
            </div>
            <div className="col-12 form-group">
              <Field
                component={UploadMultiple}
                name="documents"
                label="Files"
              />
            </div>
          </>
        );
      }}
    </FormModal>
  );
};

const mapStateToProps = (state: any) => {
  const selector = formValueSelector("CommunicationModal");

  return {
    communicableType: selector(state, "communicable_type"),
    communicableId: selector(state, "communicable_id"),
  };
};

export default connect(mapStateToProps, {})(CommunicationModal);
