import { Fragment, useEffect, useRef } from "react";
import FieldInformationPopOver from "./FieldInformationPopOver";

export const adjustTextareaHeight = (textareaRef) => {
  if (textareaRef?.current) {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${
      textareaRef.current.scrollHeight + 3
    }px`;
  }
};

const RenderField = (props) => {
  const {
    input,
    label,
    placeholder,
    className,
    readOnly,
    type,
    textarea,
    cols,
    rows,
    list,
    ariaLabel,
    meta: { touched, error, invalid, warning },
    required,
    extraProps,
    information,
    autoComplete,
  } = props;

  const textAreaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight(textAreaRef);
  }, [input.value]);

  const textareaType = (
    <textarea
      {...input}
      disabled={props.disabled}
      rows={rows}
      cols={cols}
      label={label}
      placeholder={placeholder}
      aria-label={ariaLabel}
      type={type}
      ref={textAreaRef}
      className={`form-control ${touched && invalid ? " parsley-error" : ""}`}
      {...extraProps}
    />
  );

  const inputType = (
    <input
      id={input.name}
      aria-label={ariaLabel}
      list={list}
      className={
        error && touched
          ? `${
              type !== "checkbox" ? "form-control" : ""
            } parsley-error border-danger ${className ? ` ${className}` : ""}`
          : `${type !== "checkbox" ? "form-control" : ""}${
              className ? ` ${className}` : ""
            }`
      }
      {...input}
      onChange={(e) => {
        if (props.onChangeValue) {
          props.onChangeValue(e.target.value);
        }

        input.onChange(e);
      }}
      placeholder={placeholder}
      {...extraProps}
      type={type}
      autoComplete={autoComplete}
    />
  );

  return (
    <Fragment>
      {label && (
        <div className="d-flex space-x-1 align-items-start">
          <label
            htmlFor={input.name}
            className="form-control-label tx-inverse tx-semibold"
          >
            {label}
          </label>
          {required ? <span className="tx-danger"> *</span> : ""}
          <FieldInformationPopOver information={information} input={input} />
        </div>
      )}
      {readOnly && <div className="readOnly">{input.value}</div>}
      {!readOnly && (
        <Fragment>
          {textarea ? textareaType : inputType}
          {touched && error && (
            <span className="parsley-errors-list">{error}</span>
          )}

          {touched && warning && <span className="tx-warning">{warning}</span>}
        </Fragment>
      )}
    </Fragment>
  );
};

export const money = {
  format: (value) => {
    if (!value) {
      return;
    }
    return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  normalize: (value) => value.replace(/\$\s?|(,*)/g, ""),
};

export default RenderField;
