import { useEffect } from "react";
import { useAuth } from "../../context/auth-context";
import FormHeader from "../utils/FormHeader";
import ItemFields from "./ItemFields";
import useApi from "../api/useApi";

const PurchaseItems = (props) => {
  const { fields, initialValues, title } = props;
  const { user } = useAuth();

  const { data: accountCodes } = useApi(
    "chart-of-accounts?purchaseOrders=1",
    [],
  );

  const { data: branches } = useApi("user-branches", []);

  const { data: jobs } = useApi(
    `custom-fields/jobs?filter[project_id]=${initialValues?.project_id}`,
    [],
  );

  useEffect(() => {
    if ((!initialValues || !initialValues.uuid) && fields.length === 0) {
      fields.push({});
    }
  }, []);

  const canEdit =
    !initialValues?.uuid ||
    initialValues?.purchases?.length === 0 ||
    user.is_admin ||
    props.isAccounting;

  return (
    <>
      <FormHeader>{title || "Add Items Below"}</FormHeader>
      {fields.map((member, index) => {
        const newProps = {
          member,
          branches,
          fields,
          index,
          accountCodes,
          key: index,
          change: props.change,
          jobs,
        };

        return <ItemFields canEdit={canEdit} {...props} {...newProps} />;
      })}

      {canEdit && (
        <div className="col-12 form-group d-flex">
          <button
            className="btn btn-link ms-auto"
            type="button"
            onClick={() => fields.push({ branch_id: user.default_branch_id })}
          >
            Add Item
          </button>
        </div>
      )}
    </>
  );
};

export default PurchaseItems;
