import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { formValueSelector } from "redux-form";
import { clearTimesheetForm } from "../../actions/timesheetActions";
import { useUserTimesheet } from "../../context/timesheet-context";
import axios from "../api/api";
import deleteSwal from "../utils/deleteSwal";
import InformationAlert from "../utils/InformationAlert";
import ProfileButtons from "../utils/ProfileButtons";
import CustomScaleLoader from "../utils/scaleLoader";
import AddTimesheet from "./AddTimesheet";
import useTimesheets from "./hooks/useTimesheets";
import ProductivityCard from "./ProductivityCard";
import ProductivityForm from "./ProductivityForm";
import TimesheetTable from "./TimesheetTable";
import { BreakType } from "./timesheetTypes";
import DocsModal from "../utils/DocsModal";
import useModal from "../hooks/useModal";
import ExportTimesheetFormModal from "./ExportTimesheetFormModal";
import { useAuth } from "../../context/auth-context";
import TimesheetSummary from "./TimesheetSummary";

const Timesheets = (props) => {
  const [showProductivity, setShowProductivity] = useState(false);
  const { folderUuid, userId: uuid } = useParams();
  const { selectedDay, initialValues } = props;
  const formattedSelected = dayjs(selectedDay).format("YYYY-MM-DD");
  const [viewOnlyUnpaid, setViewOnlyUnpaid] = useState(true);
  const [viewLeave, setViewLeave] = useState(true);
  const add = folderUuid === "add";
  const { toggle, modal } = useModal();
  const { user } = useAuth();

  const {
    data: timesheets,
    isLoading,
    error,
  } = useTimesheets(initialValues.id, viewOnlyUnpaid, viewLeave);

  const { productivity, setProductivity } = useUserTimesheet();

  const { userId } = useParams();

  useEffect(() => {
    return () => {
      props.clearTimesheetForm();
      setProductivity([]);
    };
  }, []);

  useEffect(() => {
    addStaffSchedules(userId, selectedDay);
  }, [formattedSelected]);

  const hasProductivity = productivity.length > 0;

  function addStaffSchedules(userId, selectedDate) {
    axios
      .get(
        `staff-schedules/${userId}?date=${dayjs(selectedDate).format(
          "YYYY-MM-DD",
        )}`,
      )
      .then(({ data }) => {
        prepScheduledProductivity(data.data);
      });
  }

  const updateProductivityDate = (event) => {
    const timesheetDay = add ? selectedDay : props?.timesheet?.date;

    const productivityStart = dayjs(
      `${dayjs(timesheetDay).format("YYYY-MM-DD")}T${event?.start_time_time}`,
    ).toDate();

    const productivityEnd = dayjs(
      `${dayjs(timesheetDay).format("YYYY-MM-DD")}T${event?.finish_time_time}`,
    ).toDate();
    return {
      ...event,
      start: productivityStart,
      start_time_date: dayjs(productivityStart).format("YYYY-MM-DD"),
      end: productivityEnd,
      finish_time_date: dayjs(productivityEnd).format("YYYY-MM-DD"),
      allowances: event?.allowances,
    };
  };

  function prepScheduledProductivity(schedules) {
    const productivitiesWithoutScheduled = productivity
      .filter(
        (event) =>
          (!event.pulled_from_schedule &&
            ![BreakType.Paid, BreakType.Unpaid].includes(event?.break_type)) ||
          [BreakType.Paid, BreakType.Unpaid].includes(event?.break_type),
      )
      .map((event) => updateProductivityDate(event));

    const formattedSchedules = schedules.map((event) => ({
      id: event.uuid,
      uuid: event.uuid,
      title: event.title,
      start: dayjs(event.start_time).toDate(),
      start_time_date: dayjs(event.start_time).format("YYYY-MM-DD"),
      start_time_time: dayjs(event.start_time).format("HH:mm:ss"),
      end: dayjs(event.finish_time).toDate(),
      finish_time_date: dayjs(event.finish_time).format("YYYY-MM-DD"),
      finish_time_time: dayjs(event.finish_time).format("HH:mm:ss"),
      comments: event.comments,
      job_id: event.job_id,
      project: event.job.project_id,
      is_job: event.is_job,
      pulled_from_schedule: true,
      break_type: null,
      allowances: event.allowances,
    }));

    setProductivity(productivitiesWithoutScheduled.concat(formattedSchedules));
  }

  if (error) {
    return (
      <InformationAlert
        type="danger"
        title={`Error ${error?.response?.status}`}
        body={error?.response?.data?.message}
      />
    );
  }

  const buttons = [
    {
      text: "Export Timesheet to CSV",
      onClick: toggle,
    },
  ];

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/timesheet-tracking/adding-time-sheets/" />
      {user.is_admin && (
        <>
          <ExportTimesheetFormModal toggle={toggle} modal={modal} {...props} />
          <ProfileButtons buttons={buttons} />
        </>
      )}
      <ul className="nav nav-activity-profile mg-y-20">
        <li className="nav-item">
          <Link
            type="button"
            to={() =>
              add
                ? `/users/${uuid}/timesheets`
                : `/users/${userId}/timesheets/add`
            }
            className="nav-link w-100 pointer-hover link-hover"
          >
            <i className="icon ion-add tx-purple" />{" "}
            {add ? "View Timesheets" : "New Timesheet"}
          </Link>
        </li>
        {folderUuid !== "summary" && (
          <li className="nav-item">
            <Link
              to={`/users/${userId}/timesheets/summary`}
              className="nav-link w-100 pointer-hover link-hover"
            >
              <i className="icon ion-add tx-purple" /> Productivity Summary
            </Link>
          </li>
        )}
      </ul>

      {add ? (
        <>
          <Buttons
            setShowProductivity={setShowProductivity}
            showProductivity={showProductivity}
            leaveReason={props.leaveReason}
          />
          <ProductivityCard
            className="d-lg-none mg-y-20"
            events={productivity}
            addTimesheet={add}
          />
          <div className="d-flex justify-content-center">
            <div>
              <p className={`text-center ${hasProductivity ? "mb-0" : ""}`}>
                {dayjs(selectedDay).format("DD/MM/YYYY")}
              </p>
              {hasProductivity && (
                <button
                  className="btn btn-link p-0 mb-3 mt-1"
                  onClick={() =>
                    deleteSwal("Productivity").then(() =>
                      setProductivity(
                        productivity.filter((event) =>
                          [BreakType.Paid, BreakType.Unpaid].includes(
                            event?.break_type,
                          ),
                        ),
                      ),
                    )
                  }
                >
                  Clear Productivity
                </button>
              )}
            </div>
          </div>
        </>
      ) : folderUuid === "summary" ? (
        <>
          <TimesheetSummary userUuid={uuid} />
        </>
      ) : (
        <>
          {isLoading ? (
            <CustomScaleLoader />
          ) : (
            <>
              {
                <div className="d-flex">
                  <Button
                    outline
                    className="btn-oblong ms-auto mb-3"
                    color="primary"
                    size="sm"
                    onClick={() => setViewLeave(!viewLeave)}
                  >
                    {viewLeave ? "Hide Leave" : "Show Leave"}{" "}
                  </Button>

                  <Button
                    outline
                    className="btn-oblong ms-1 mb-3"
                    color="primary"
                    size="sm"
                    onClick={() => setViewOnlyUnpaid(!viewOnlyUnpaid)}
                  >
                    {viewOnlyUnpaid ? "Show Paid" : "Hide Paid"}{" "}
                  </Button>
                </div>
              }
              <TimesheetTable {...props} timesheets={timesheets} />
            </>
          )}
        </>
      )}

      <NewTimesheetPage
        {...props}
        userId={userId}
        showProductivity={showProductivity}
        addTimesheet={add}
        setShowProductivity={setShowProductivity}
        leaveReason={props.leaveReason}
      />
    </>
  );
};

const Buttons = ({ showProductivity, setShowProductivity, leaveReason }) => {
  const buttons = [
    {
      text: "Timesheet",
      onClick: () => setShowProductivity(false),
      className: !showProductivity ? "bg-dark text-white" : "",
    },
  ];

  if (!leaveReason) {
    buttons.push({
      text: "Productivity",
      onClick: () => setShowProductivity(true),
      className: showProductivity ? "bg-dark text-white" : "",
    });
  }

  return <ProfileButtons buttons={buttons} />;
};

export const NewTimesheetPage = (props) => {
  const { showProductivity, addTimesheet, userId, initialValues } = props;

  if (!addTimesheet) {
    return null;
  }

  return (
    <>
      <DocsModal url="https://docs.thebossapp.com.au/docs/thebossapp-docs/timesheet-tracking/adding-time-sheets/" />
      <div className={`${showProductivity ? "" : "d-none"} `}>
        <ProductivityForm
          showSave
          form="AddTimesheet"
          {...props}
          userId={userId}
        />
      </div>
      <div className={`${showProductivity ? "d-none" : ""} `}>
        <AddTimesheet
          form="AddTimesheet"
          {...props}
          userId={initialValues.id}
        />
      </div>
    </>
  );
};
const selector = formValueSelector("AddTimesheet");

const mapStateToProps = (state) => {
  return {
    selectedDay: state.timesheets.selectedDay,
    leaveReason: selector(state, "leave_reason_id"),
  };
};

export default connect(mapStateToProps, {
  clearTimesheetForm,
})(Timesheets);
