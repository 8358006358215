import { OrganisationSetting } from "./../organisation/types";
import { useQuery } from "react-query";
import api from "../api/api";
import { OrganisationSettingKeys } from "../organisation/types";

const getOrganisationSettings = (organisationId?: number) =>
  api
    .get(
      `organisation-settings${
        organisationId ? `?organisation_id=${organisationId}` : ""
      }`,
    )
    .then(({ data }) => data.data?.settings);

const useOrganisationSetting = (
  setting: OrganisationSettingKeys,
  organisationId?: number,
): OrganisationSetting | undefined => {
  const { data } = useQuery("organisation_settings", () =>
    getOrganisationSettings(organisationId),
  );

  return data?.find(
    ({ meta_key }: { meta_key: OrganisationSettingKeys }) =>
      meta_key === setting,
  );
};

export default useOrganisationSetting;
