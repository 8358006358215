import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Field, FieldArray } from "redux-form";
import { useAuth } from "../../context/auth-context";
import axios from "../api/api";
import { formatSuppliers } from "../purchases/Fields";
import FileInput from "../upload/FileInput";
import uploadToS3 from "../upload/uploadToS3";
import history from "../utils/History";
import renderField from "../utils/renderField";
import SelectInputAsync from "../utils/SelectInputAsync";
import FrequencyFields from "./FrequencyFields";
import ItemFieldArray from "./ItemFieldArray";
import formError from "../utils/formError";
import required from "../utils/required";
import SelectInput from "../form/SelectInput";
import FormModal from "../utils/FormModal";

const RecurringPurchaseModal = (props) => {
  const [suppliers, setSuppliers] = useState([]);
  const { toggle, modal, handleSubmit } = props;
  const { user } = useAuth();
  const { number } = useParams();

  const submitForm = (values) => {
    return uploadToS3(
      values.file,
      `organisations/${user.active_organisation.uuid}/recurring-payment-quotes`,
    )
      .then((documents) => {
        const data = {
          documents,
          ...values,
        };

        return axios.post(`projects/${number}/recurring-payments`, data);
      })
      .then(({ data }) => {
        toast.success("Recurring purchase added successfully");
        return history.push(data.data.link);
      })
      .catch(formError);
  };

  useEffect(() => {
    axios.get(`suppliers`).then(({ data }) => {
      setSuppliers(formatSuppliers(data.data));
    });
  }, []);

  return (
    <FormModal
      {...props}
      form="RecurringPurchase"
      title="Add Contract Agreement"
      onSubmit={submitForm}
    >
      {({ change }) => {
        return (
          <>
            <div className="col-12 form-group">
              <Field
                label="GST Options"
                required
                url="/gst-options"
                component={SelectInputAsync}
                name="includes_gst"
                validate={required}
              />
            </div>
            <div className="col-12 form-group">
              <Field label="Quote" component={FileInput} name="file" />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                label="Quote Number"
                component={renderField}
                name="quote_number"
              />
            </div>
            <div className="col-lg-6 form-group">
              <Field
                label="Description"
                required
                component={renderField}
                name="description"
                validate={required}
              />
            </div>
            <div className="col-12 form-group mb-3">
              <Field
                label="Supplier"
                required
                component={SelectInput}
                name="supplier_id"
                options={suppliers}
                validate={required}
              />
            </div>
            <FrequencyFields />
            <FieldArray
              component={ItemFieldArray}
              {...props}
              change={change}
              name="pricing"
              form="RecurringPurchase"
            />
          </>
        );
      }}
    </FormModal>
  );
};

export default RecurringPurchaseModal;
