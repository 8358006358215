import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import useApi from "../api/useApi";
import formError from "../utils/formError";
import RenderField from "../utils/renderField";
import { percentage } from "../form/formatters";
import SubmitButton from "../utils/SubmitButton";
import { MarkupPercentFormFields } from "./priceTypes";
import FormErrorAlert from "../form/FormErrorAlert";
import { toast } from "react-toastify";

interface MarkupPercentFormProps {
  percent_type: string;
  percent_id: number;
}

const MarkupPercentForm = (
  props: InjectedFormProps<MarkupPercentFormFields, MarkupPercentFormProps> &
    MarkupPercentFormProps,
) => {
  const { handleSubmit } = props;

  const { takeAction } = useApi();

  const onSubmit: FormSubmitHandler<
    MarkupPercentFormFields,
    MarkupPercentFormProps
  > = (values) => {
    return takeAction("update", `/markup-percent`, {
      percent_type: props.percent_type,
      percent_id: props.percent_id,
      ...values,
    })
      .then(() => {
        toast.success("Markup Percent Updated");
      })
      .catch(formError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert error={props.error} />
        <div className="col-lg-12 form-group">
          <Field
            name="markup_percent"
            component={RenderField}
            label="Markup"
            {...percentage}
          />
        </div>
        <div className="col-lg-12 form-group">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
  );
};

const form = reduxForm<MarkupPercentFormFields, MarkupPercentFormProps>({
  form: "MarkupPercentForm",
});

export default form(MarkupPercentForm);
