import axios from "../api/api";
import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import errorSwal from "../utils/errorSwal";
import TextButton from "../utils/TextButton";

const ToggleBranchSchedule = ({
  selectedBranch,
  setSelectedBranch,
  fetchToDos,
  loading,
  calendarTimes,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userBranches, setUserBranches] = useState([]);
  // const [selectedBranch, setSelectedBranch] = useState();

  useEffect(() => {
    axios
      .get(`/organisationbranches/user`)
      .catch((err) => errorSwal())
      .then(({ data }) => setUserBranches(data.data));
  }, []);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const fetchBranch = (branch) => {
    setDropdownOpen(false);

    setSelectedBranch(branch);

    localStorage.setItem("selectedBranch", JSON.stringify(branch));

    return fetchToDos(
      calendarTimes.start,
      calendarTimes.end,
      `/branches/${branch.uuid}/to-dos`,
    );
  };

  const fetchAll = () => {
    setSelectedBranch(null);

    localStorage.removeItem("selectedBranch");

    return fetchToDos(
      calendarTimes.start,
      calendarTimes.end,
      "/calendar-tasks",
    );
  };

  return (
    <>
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle color="primary" caret={!loading}>
          <ButtonText selectedBranch={selectedBranch} />
        </DropdownToggle>
        <DropdownMenu>
          {userBranches.map((branch) => (
            <DropdownItem
              type="button"
              href="javascript:void(0)"
              onClick={() => fetchBranch(branch)}
              className="nav-link"
              key={branch.uuid}
            >
              <i className="fa fa-building-o tx-primary me-1 me-2 tx-18" />{" "}
              {branch.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
      {selectedBranch?.name && (
        <TextButton
          type="button"
          onClick={fetchAll}
          className="px-3 ms-2 close"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </TextButton>
      )}
    </>
  );
};

const ButtonText = ({ loading, selectedBranch }) => {
  if (loading) {
    return (
      <>
        <ClipLoader loading color="#fff" size={14} /> Fetching Data...
      </>
    );
  }
  if (selectedBranch?.name) {
    return `Viewing jobs for ${selectedBranch.name}`;
  }

  return "View Schedule for Branch ";
};

export default ToggleBranchSchedule;
