import { toast } from "react-toastify";
import { initialize, reduxForm } from "redux-form";

import axios from "../api/api";
import Fields from "../purchases/Fields";
import errorSwal from "../utils/errorSwal";
import isSubmitting from "../utils/submitting";

const Edit = (props: any) => {
  const { handleSubmit, receipt, setReceipt, submitting, dispatch } = props;

  const submit = (values: any) => {
    return axios
      .put(`/credit-card-receipts/${receipt.uuid}`, values)
      .then(({ data }) => {
        toast.success("Receipt updated!");
        setReceipt(data.data);
        dispatch(initialize("EditCreditCardReceipt", data.data));
      })
      .catch(errorSwal);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="row">
        <Fields
          {...props}
          creditCard
          setItem={setReceipt}
          item={receipt}
          markup={receipt.project.markup_percent}
          project={props.project}
        />

        <div className="col-12 form-group">
          <button
            type="submit"
            disabled={submitting}
            className="btn btn-primary"
          >
            {isSubmitting(submitting)}
          </button>
        </div>
      </div>
    </form>
  );
};

const form = reduxForm({
  form: "EditCreditCardReceipt",
});

export default form(Edit);
