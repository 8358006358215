import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Communication } from "./communicationTypes";
import { useMemo } from "react";
import dayjs from "dayjs";
import ReactTable from "../table/ReactTable";
import { Button } from "reactstrap";
import CommunicationModal from "../clients/CommunicationModal";
import useModal from "../hooks/useModal";
import PaginatedList from "../pagination/PaginatedList";
import useCommunicationLogs from "./hooks/useCommunicationLogs";
import { useQueryClient } from "react-query";
import { useAuth } from "../../context/auth-context";
import useSelectedDocuments from "../hooks/useSelectedDocuments";
import PreviewModal from "../documents/PreviewModal";
import { StandardDocument } from "../standardDocuments/standardDocumentTypes";

const Communications = ({
  communicableType,
  communicableId,
  initialClientUuid,
}: {
  communicableType: string;
  communicableId: number;
  initialClientUuid: string;
}) => {
  const { toggle, modal } = useModal();
  const queryClient = useQueryClient();

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button color="primary" onClick={toggle} className="mb-3">
          Add Communication
        </Button>
      </div>

      <PaginatedList<"", {}, Communication>
        listName="communicationLogs"
        indexHook={useCommunicationLogs}
        originalFilters={[]}
        indexHookArguments={[
          {
            type: communicableType,
            id: communicableId,
          },
        ]}
        list={({ data }) => {
          return (
            <CommunicationList
              data={data ?? []}
              communicableType={communicableType}
              communicableId={communicableId}
              initialClientUuid={initialClientUuid}
            />
          );
        }}
      />

      <CommunicationModal
        toggle={toggle}
        modal={modal}
        communicable={{
          type: communicableType,
          id: communicableId,
        }}
        initialClientUuid={initialClientUuid}
        onSuccess={() => queryClient.invalidateQueries("communication-logs")}
      />
    </>
  );
};

const CommunicationList = ({
  data,
  communicableType,
  communicableId,
  initialClientUuid,
}: {
  data: Communication[];
  communicableType: string;
  communicableId: number;
  initialClientUuid: string;
}) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const columnHelper = createColumnHelper<Communication>();

  const columns = useMemo<ColumnDef<Communication, any>[]>(
    () => [
      columnHelper.accessor("user.name", {
        header: "Staff Member",
        cell: (info) => {
          const { toggle, modal } = useModal();

          if (info.row.original.user.uuid !== user?.uuid) {
            return info.getValue();
          }

          return (
            <>
              <Button color="link" className="p-0" onClick={() => toggle()}>
                {info.getValue()}
              </Button>
              <CommunicationModal
                toggle={toggle}
                modal={modal}
                uuid={info.row.original.uuid}
                onSuccess={() =>
                  queryClient.invalidateQueries("communication-logs")
                }
                initialValues={info.row.original}
                initialClientUuid={initialClientUuid}
                communicable={{
                  type: communicableType,
                  id: communicableId,
                }}
              />
            </>
          );
        },
      }),
      columnHelper.accessor("contact.name", {
        header: "Communicated With",
      }),
      columnHelper.accessor("type_description", {
        header: "Type",
        cell: (info: any) => info.getValue(),
      }),
      columnHelper.accessor("created_at", {
        header: "Date",
        cell: (info: any) => dayjs(info.getValue()).format("DD/MM/YYYY HH:mm"),
      }),
      columnHelper.accessor("details", {
        header: "Details",
        cell: (info: any) => {
          return info.getValue() ?? "-";
        },
      }),
      columnHelper.accessor("documents", {
        header: "Files",
        cell: (info: any) => {
          return <DocumentColumn documents={info.getValue()} />;
        },
      }),
    ],
    [data],
  );

  return (
    <div className="col-12">
      <ReactTable disableSearch columns={columns} data={data} />
    </div>
  );
};

const DocumentColumn = ({ documents }: { documents: StandardDocument[] }) => {
  const { toggle, modal } = useModal();

  const { selectedDocument, setSelectedDocument, changeDocument } =
    useSelectedDocuments(documents);

  if (documents.length === 0) {
    return <div>0 files</div>;
  }

  return (
    <>
      <Button
        color="link"
        className="p-0"
        onClick={() => {
          /** @ts-ignore */
          setSelectedDocument(documents[0]);
          toggle();
        }}
      >
        {documents.length} File{documents.length > 1 && "s"}
      </Button>
      <PreviewModal
        document={selectedDocument}
        modal={modal}
        toggle={toggle}
        changeDocument={changeDocument}
      />
    </>
  );
};

export default Communications;
