import { InjectedFormProps, reduxForm } from "redux-form";
import CustomForm from "../customFields/CustomForm";
import TextButton from "../utils/TextButton";
import { ClientFeedback as IClientFeedback } from "./clientFeedbackTypes";

const ClientFeedback = (
  props: InjectedFormProps<
    {},
    {
      feedback: IClientFeedback;
      setFeedback: Function;
    }
  > & {
    feedback: IClientFeedback;
    setFeedback: Function;
  },
) => {
  const { feedback, handleSubmit, setFeedback } = props;

  return (
    <>
      <div className="d-flex">
        <div className="ms-auto">
          <TextButton onClick={() => setFeedback(null)} className="tx-18">
            ×
          </TextButton>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <CustomForm customForm={feedback.custom_form} {...props} />
        </div>
      </form>
    </>
  );
};

const form = reduxForm<
  {},
  {
    feedback: IClientFeedback;
    setFeedback: Function;
  }
>({});

export default form(ClientFeedback);
