import { useInfiniteQuery } from "react-query";
import api from "../../api/api";

const getCommunicationLogs = (
  { pageParam = 1 },
  search?: string,
  communicable?: {
    type: string;
    id: number;
  },
) => {
  if (!pageParam) {
    return;
  }

  return api
    .get(
      `communications?page=${pageParam}&filter[search]=${search}&filter[communicable_id]=${
        communicable?.id
      }&filter[communicable_type]=${encodeURIComponent(
        communicable?.type ?? "",
      )}`,
    )
    .then(({ data }) => data);
};

export default function useCommunicationLogs(
  search?: string,
  communicable?: {
    type: string;
    id: number;
  },
) {
  return useInfiniteQuery(
    ["communication-logs", { search, communicable }],
    (pageParam) => getCommunicationLogs(pageParam, search, communicable),
    {
      getNextPageParam: ({ meta }) => {
        if (meta.current_page === meta.last_page) {
          return;
        }

        return meta.current_page + 1;
      },
      staleTime: 5 * 60 * 100,
    },
  );
}
