import FormModal from "../utils/FormModal";
import { Field } from "redux-form";
import RenderField from "../utils/renderField";
import required from "../utils/required";
import { Service } from "./serviceTypes";

const ServiceModal = ({
  toggle,
  modal,
  title,
  form,
  onSubmit,
  initialValues,
}: {
  toggle: Function;
  modal: boolean;
  title: string;
  form: string;
  onSubmit: Function;
  initialValues?: Service;
}) => {
  return (
    <FormModal
      onSubmit={onSubmit}
      title={title}
      toggle={toggle}
      modal={modal}
      form={form}
      initialValues={initialValues}
    >
      <div className="col-12 form-group">
        <Field
          component={RenderField}
          required
          validate={required}
          name="name"
          label="Name"
        />
      </div>
    </FormModal>
  );
};

export default ServiceModal;
