import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Service } from "./serviceTypes";
import { useMemo, useState } from "react";
import ReactTable from "../table/ReactTable";
import useModal from "../hooks/useModal";
import TextButton from "../utils/TextButton";
import ServiceModal from "./ServiceModal";
import { FormSubmitHandler } from "redux-form";
import useApi from "../api/useApi";
import { IUseApi } from "../api/apiTypes";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import formError from "../utils/formError";

const ServiceTable = ({ services }: { services: Service[] }) => {
  const columnHelper = createColumnHelper<Service>();

  const { toggle } = useModal();

  const [selectedService, setSelectedService] = useState<Service>();

  const queryClient = useQueryClient();

  const { takeAction }: IUseApi = useApi();

  const onSubmit: FormSubmitHandler<{ name: string }> = (values, _, props) => {
    return takeAction("update", `services/${selectedService?.uuid}`, values)
      .then(({ data }) => {
        toast.success(`${values.name} updated successfully`);
        queryClient.invalidateQueries("services");
        setSelectedService(undefined);
        props?.reset?.();
      })
      .catch(formError);
  };

  const columns = useMemo<ColumnDef<Service, any>[]>(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: ({ row }) => {
          const service = row.original;

          return (
            <TextButton
              onClick={() => {
                toggle();
                setSelectedService(service);
              }}
              className="mb-0 text-dark"
            >
              {service.name}
            </TextButton>
          );
        },
      }),
    ],
    [toggle, services, selectedService],
  );

  return (
    <>
      <div className="col-12">
        <ReactTable<Service> disableSearch data={services} columns={columns} />
      </div>
      <ServiceModal
        title="Edit Service"
        form="EditService"
        modal={!!selectedService}
        toggle={toggle}
        onSubmit={onSubmit}
        initialValues={selectedService}
      />
    </>
  );
};

export default ServiceTable;
