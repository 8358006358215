import useApi from "../api/useApi";
import { Link, useParams } from "react-router-dom";
import * as d3 from "d3";
import { IUseApiWithData } from "../api/apiTypes";
import { ProjectJob } from "../projectTypes/projectTypeTypes";
import { useEffect, useRef } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import errorSwal from "../utils/errorSwal";

const GanttChart = () => {
  const { number } = useParams<{ number: string }>();

  const ref = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const { data, refreshData }: IUseApiWithData<ProjectJob[]> = useApi(
    `projects/${number}/jobs`,
    [],
  );

  const { takeAction } = useApi();

  const dateMin =
    d3.min(data, (j) => new Date(j.scheduled_start_date) as Date) ?? new Date();

  const dateMax =
    d3.max(
      data.filter((j) => j.scheduled_finish_date),
      (j) => new Date(j.scheduled_finish_date!) as Date,
    ) ?? new Date();

  const xScale = d3
    .scaleTime()
    .domain([dateMin, dateMax])
    .range([100, (ref.current?.clientWidth ?? 0) - 50]);

  // Create the axis
  const xAxis = d3.axisBottom(xScale);

  const jobs = _.sortBy(data, ["scheduled_start_date"]).map((job) => {
    return {
      x: xScale(new Date(job.scheduled_start_date)),
      width:
        xScale(new Date(job.scheduled_finish_date)) -
        xScale(new Date(job.scheduled_start_date)),
      ...job,
      className: `fill-${job.status_badge.color}`,
    };
  });

  const height = data.length * 60;

  const yScale = d3
    .scaleBand()
    .domain(jobs.map((j) => j.id))
    .range([0, height]);

  useEffect(() => {
    // Select all rectangles
    const svg = d3.select(svgRef.current);
    const rects = svg.selectAll<SVGRectElement, (typeof jobs)[0]>("rect");

    // Apply drag behavior
    const dragBehavior = d3
      .drag<SVGRectElement, (typeof jobs)[0]>()
      .on("start", function (event, d) {
        d3.select(this).raise().attr("stroke", "black");
      })
      .on("drag", function (event, d) {
        if (!d) return; // Ensure 'd' is defined

        // Get the dragged position in terms of time
        const draggedDate = xScale.invert(event.x);
        const draggedFinish = xScale.invert(event.x + d.width);

        // Round the dragged date to the nearest day (start of the day)
        const snappedDate = new Date(
          draggedDate.getFullYear(),
          draggedDate.getMonth(),
          draggedDate.getDate(),
        );

        const snappedFinish = new Date(
          draggedFinish.getFullYear(),
          draggedFinish.getMonth(),
          draggedFinish.getDate(),
        );

        // Convert the snapped date back to an x position
        const snappedX = xScale(snappedDate);
        const snappedEnd = xScale(snappedDate);

        // Update the job's start date
        d.scheduled_start_date = dayjs(snappedDate).format("YYYY-MM-DD");
        d.scheduled_finish_date = dayjs(snappedFinish).format("YYYY-MM-DD");

        // Move the rectangle to the snapped position
        d3.select(this).attr("x", snappedX);
      })
      .on("end", function (event, d) {
        if (!d) return; // Ensure 'd' is defined
        d3.select(this).attr("stroke", null);

        takeAction("update", `project-jobs/${d.uuid}`, {
          ...d,
          scheduled_start_date: d.scheduled_start_date,
          scheduled_finish_date: d.scheduled_finish_date,
        })
          .then(refreshData)
          .catch((err: any) => {
            errorSwal(err);
            refreshData();
          });
      });

    rects
      .data(jobs) // Ensure data is bound to rectangles
      .call(dragBehavior);
  }, [jobs, xScale]);

  return (
    <div ref={ref} className="bg-white p-3 rounded-lg shadow-sm border">
      <svg ref={svgRef} width={ref.current?.clientWidth ?? 0} height={height}>
        <g
          ref={(node) => {
            if (node) {
              d3.select(node).call(xAxis);
            }
          }}
        />
        <g transform="translate(0, 30)">
          {jobs.map((job) => {
            return (
              <g className="gantt-group">
                {/* <line
                  x1={job.x}
                  y1={0}
                  x2={job.x}
                  y2={(yScale(job.id) as number) + 30}
                  className="stroke-gray"
                /> */}
                {/* End line */}
                {/* <line
                  x1={job.x + job.width}
                  y1={0}
                  x2={job.x + job.width}
                  y2={(yScale(job.id) as number) + 30}
                  className="stroke-gray"
                /> */}
                <Link to={job.link}>
                  <rect
                    key={job.id}
                    x={job.x}
                    y={yScale(job.id)}
                    width={job.width}
                    height={30}
                    className={job.className}
                  />
                </Link>
                <text fontSize={10} y={(yScale(job.id) as number) + 13}>
                  {job.name.substring(0, 20)}
                  {job.name.length > 20 ? "..." : ""}
                </text>
                <text
                  fill="gray"
                  fontSize={10}
                  y={(yScale(job.id) as number) + 30}
                >
                  {job.number}
                </text>
              </g>
            );
          })}
        </g>
      </svg>
    </div>
  );
};

export default GanttChart;
