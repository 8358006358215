import { useEffect, useMemo } from "react";
import RenderField from "../utils/renderField";
import { Field } from "redux-form";
import ReactTable from "../table/ReactTable";
import required from "../utils/required";

interface SuperProduct {
  id?: number;
  abn?: string;
  account_name?: string;
  account_number?: string;
  bsb?: string;
  electronic_service_address?: string;
  external_id?: string;
  fund_email?: string;
  fund_name?: string;
}

interface SuperFund {
  id?: number;
  index?: number;
  abn?: string;
  allocate_balance?: boolean;
  allocated_percentage?: number;
  fund_name?: string;
  is_employer_nominated_fund?: boolean;
  member_number?: string;
  product_code?: string;
  product_type?: string;
  superProduct?: SuperProduct;
}

interface SuperFundTableProps {
  superFund?: SuperFund[];
  change?: (field: string, value: any) => void;
  handleOpenModal: (row: any) => void;
  fieldNamePrefix?: string;
}

const EmployeeSuperFundTable: React.FC<SuperFundTableProps> = ({ superFund = [], change, handleOpenModal, fieldNamePrefix }) => {
  const columns = useMemo(() => [
    {
      accessorKey: "fund_name",
      header: "Fund Name",
      cell: ({ row }: any) => (
        <div>
          <div>
            <a
              style={{ cursor: 'pointer' }}
              className="links"
              onClick={() => handleOpenModal(row.original)}
            >
              {row.original.fund_name == "" ? 'Select Super Fund' : row.original.fund_name}
            </a>
          </div>
          <span>{row.original.abn == "" ? '' : `ABN: ${row.original.abn}`}</span>
        </div>
      ),
    },
    {
      accessorKey: "product_code",
      header: "Product Code",
      cell: ({ row }: any) => {
        useEffect(() => {
          if (row.original.product_code === "SMSF") {
            change?.(
              `superFund${row.index + 1}_ProductCode`,
              row.original.product_code
            );
          }
        }, [row, change]);

        return <span>{row.original.product_code}</span>;
      },
    },
    {
      accessorKey: "memberNumber",
      header: "Member Number",
      cell: ({ row }: any) => (
        <Field
          component={RenderField}
          name={fieldNamePrefix ? `${fieldNamePrefix}.superFund${row.index + 1}_MemberNumber` : `superFund${row.index + 1}_MemberNumber`}
          required={true}
          validate={required}
        />
      ),
    },
    {
      accessorKey: "allocatedPercentage",
      header: "Allocated Percentage",
      cell: ({ row }: any) => (
        <Field
          component={RenderField}
          name={fieldNamePrefix ? `${fieldNamePrefix}.superFund${row.index + 1}_AllocatedPercentage` :`superFund${row.index + 1}_AllocatedPercentage`}
          required={true}
          validate={required}
        />
      ),
    },
  ], []);

  return (
    <ReactTable disableSearch data={superFund} columns={columns} />
  );
};

export default EmployeeSuperFundTable;