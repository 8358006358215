import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import HeaderPage from "../header/HeaderPage";
import PaginatedList from "../pagination/PaginatedList";
import useServices from "./hooks/useServices";
import { Service } from "./serviceTypes";
import { useMemo } from "react";
import AddService from "./AddService";
import ServiceTable from "./ServiceTable";

const ServiceList = () => {
  const columnHelper = createColumnHelper<Service>();

  const columns = useMemo<ColumnDef<Service, any>[]>(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: ({ row }) => {
          const service = row.original;

          return <p className="mb-0 text-dark">{service.name}</p>;
        },
      }),
    ],
    [],
  );

  return (
    <>
      <HeaderPage
        titlePage="Services"
        crumbs={[
          {
            name: "Services",
            link: "/services",
            active: true,
          },
        ]}
      />
      <PaginatedList
        originalFilters={[]}
        listName="services"
        indexHook={useServices}
        extraButtons={() => <AddService />}
        list={({ data }) => {
          const services = data as Service[];

          return <ServiceTable services={services} />;
        }}
      />
    </>
  );
};

export default ServiceList;
