import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  getFormValues,
  reduxForm,
} from "redux-form";
import SubmitButton from "../utils/SubmitButton";
import RenderField from "../utils/renderField";
import FormHeader from "../utils/FormHeader";
import renderToggleInput from "../utils/renderToggleInput";
import required from "../utils/required";
import { connect } from "react-redux";
import useApi from "../api/useApi";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { EmploymentHeroUser } from "./userTypes";
import { IUseApi } from "../api/apiTypes";
import FormErrorAlert from "../form/FormErrorAlert";
import { toast } from "react-toastify";
import formError from "../utils/formError";
import SuperFundModal from "./SuperFundModal";
import EmployeeSuperFundTable from "./EmployeeSuperFundTable";
import { MdAdd } from "react-icons/md";

interface UserPayrollProps {
  formValues?: EmploymentHeroUser;
}

const UserPayroll = (
  props: UserPayrollProps &
    InjectedFormProps<EmploymentHeroUser, UserPayrollProps>,
) => {
  const [modal, setModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const toggle = () => setModal(!modal);
  const { handleSubmit, formValues, change } = props;
  const { userId } = useParams<{ userId: string }>();
  const { takeAction }: IUseApi<{}, {}> = useApi();
  const { data, refreshData: refreshEmployee } = useApi(`payroll-integration/users/${userId}`, null, true);
  const { data: superFund, refreshData: refreshSuperFund, setData: setSuperFund } = useApi(
    `payroll-integration/employee-super-funds/${userId}`,
    null,
    true
  );

  const handleOpenModal = (row: any) => {
    setSelectedRow(row);
    toggle();
  };

  const handleAddSuperFund = () => {
    const newIndex = superFund.length + 1;
    const newSuperFund = {
      index: newIndex,
      fund_name: "",
      abn: "",
      product_code: "",
      memberNumber: "",
      allocatedPercentage: ""
    };
    setSuperFund([...superFund, newSuperFund]);
  };

  const modalOnSubmit = (values : any) => {
    if ("index" in values) {
      const { index, fund_name, product_code, is_employer_nominated_fund } = values;

      change(`superFund${index}_FundName`, fund_name);
      change(`superFund${index}_ProductCode`, product_code);
      change(`superFund${index}_EmployerNominatedFund`, is_employer_nominated_fund);

      setSuperFund((prevFunds: any) =>
        prevFunds.map((fund : any) =>
          fund.index === values.index
            ? { ...fund, ...values }
            : fund
        )
      );

      toggle();
    } else {
      return takeAction("update", `payroll-integration/employee-super-funds/${userId}`, values)
        .then(({ data }) => {
          refreshEmployee();
          refreshSuperFund();
          toggle();
        })
        .catch(formError);
    }
  };

  const onSubmit: FormSubmitHandler<EmploymentHeroUser> = (values) => {
    return takeAction("update", `payroll-integration/users/${userId}`, values)
      .then(({ data }) => {
        refreshEmployee();
        refreshSuperFund();
        toast.success("User updated");
      })
      .catch(formError);
  };

  useEffect(() => {
    if (data) {
      props.initialize(data);
    }
  }, [data]);

  if (!data) return null;

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <FormErrorAlert error={props.error} />

        <div className="col-lg-12 form-group">
          <FormHeader>Super Funds</FormHeader>
          {superFund?.length < 3 && (
              <div className="tx-right mg-b-10">
                <a className="btn btn-outline-primary mb-10" onClick={handleAddSuperFund}>
                  <MdAdd className="tx-18" /> Add
                </a>
              </div>
            )}
          <EmployeeSuperFundTable superFund={superFund} handleOpenModal={handleOpenModal} change={change} />
        </div>
        <FormHeader>Tax Details</FormHeader>
        <div className="col-lg-4 form-group">
          <Field
            component={renderToggleInput}
            name="stslDebt"
            label="STSL Debt"
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={renderToggleInput}
            name="claimTaxFreeThreshold"
            label="Claim Tax Free Threshold"
          />
        </div>

        <FormHeader>Bank Account</FormHeader>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="bankAccount1_AccountName"
            label="Bank Account Name"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="bankAccount1_AccountNumber"
            label="Bank Account Number"
            required
            validate={required}
          />
        </div>
        <div className="col-lg-4 form-group">
          <Field
            component={RenderField}
            name="bankAccount1_BSB"
            label="Bank Account BSB"
            required
            validate={required}
          />
        </div>

        <div className="col-12">
          <SubmitButton {...props} />
        </div>
      </div>
    </form>
      {selectedRow && (
        <SuperFundModal
          toggle={toggle}
          modal={modal}
          onSubmit={modalOnSubmit}
          row={selectedRow}
        />
      )}
    </>
  );
};

const validateAllocatedPercentage = (values: any) => {
  const errors: Record<string, any> = {};

  // Parse the allocated percentages, fallback to 0 if not present
  const allocated1 = parseFloat(values?.superFund1_AllocatedPercentage) || 0;
  const allocated2 = parseFloat(values?.superFund2_AllocatedPercentage) || 0;
  const allocated3 = parseFloat(values?.superFund3_AllocatedPercentage) || 0;

  const totalAllocated = allocated1 + allocated2 + allocated3;

  // Check if total percentage is exactly 100%
  if (totalAllocated !== 100) {
    const errorMessage = "The total allocated percentage must equal 100%";

    if (values?.superFund1_AllocatedPercentage !== undefined) {
      errors.superFund1_AllocatedPercentage = errorMessage;
    }
    if (values?.superFund2_AllocatedPercentage !== undefined) {
      errors.superFund2_AllocatedPercentage = errorMessage;
    }
    if (values?.superFund3_AllocatedPercentage !== undefined) {
      errors.superFund3_AllocatedPercentage = errorMessage;
    }
  }

  // Return `undefined` if no errors, otherwise return the errors object
  return Object.keys(errors).length === 0 ? {} : errors;
};

const mapStateToProps = (state: any, { form }: { form: string }) => {
  return {
    formValues: getFormValues(form)(state) as EmploymentHeroUser,
  };
};

const form = reduxForm<EmploymentHeroUser, UserPayrollProps>({
  form: "UserPayrollForm",
  validate: validateAllocatedPercentage,
})(UserPayroll);

export default connect(mapStateToProps)(form);