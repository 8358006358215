import { FormSubmitHandler } from "redux-form";
import useApi from "../api/useApi";
import HeaderPage from "../header/HeaderPage";
import OpportunityForm from "./OpportunityForm";
import formError from "../utils/formError";
import { IUseApi } from "../api/apiTypes";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const AddOpportunity = () => {
  const {
    takeAction,
  }: IUseApi<
    any,
    {
      data: {
        uuid: string;
      };
    }
  > = useApi();

  const history = useHistory();

  const onSubmit: FormSubmitHandler = (values) => {
    return takeAction("store", "opportunities", values)
      .then(({ data }) => {
        toast.success("Opportunity added successfully");

        history.push(`/opportunities/${data.data.uuid}/details`);
      })
      .catch(formError);
  };

  return (
    <>
      <HeaderPage
        titlePage="Add Opportunity"
        crumbs={[
          {
            name: "Opportunities",
            link: "/opportunities",
          },
          {
            name: "Add Opportunity",
            link: "/opportunities/add",
            active: true,
          },
        ]}
      />
      <OpportunityForm
        form="AddOpportunity"
        onSubmit={onSubmit}
        initialValues={{
          status: 1,
        }}
      />
    </>
  );
};

export default AddOpportunity;
