import React, { useState } from "react";
import moment from "moment";
import { DayPicker, Matcher } from "react-day-picker";
import "react-day-picker/dist/style.css";

interface WeekPickerProps {
  onWeekSelected: (weekDays: Date[]) => void;
}

interface HoverRange {
  from: Date;
  to: Date;
}

const getWeekDays = (weekStart: Date): Date[] => {
  const days: Date[] = [weekStart];
  for (let i = 1; i < 7; i++) {
    days.push(moment(weekStart).add(i, "days").toDate());
  }
  return days;
};

const getWeekRange = (date: Date) => {
  return {
    from: moment(date).startOf("week").toDate(),
    to: moment(date).endOf("week").toDate(),
  };
};

const WeekPicker: React.FC<WeekPickerProps> = ({ onWeekSelected }) => {
  const currentWeek = getWeekRange(new Date());
  const defaultSelectedDays = getWeekDays(currentWeek.from);

  const [hoverRange, setHoverRange] = useState<HoverRange | undefined>(
    undefined,
  );
  const [selectedDays, setSelectedDays] = useState<Date[]>(defaultSelectedDays);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [selectedRange, setSelectedRange] = useState({
    from: defaultSelectedDays[0],
    to: defaultSelectedDays[6],
  });

  const handleDayChange = (date: Date) => {
    const weekRange = getWeekRange(date);
    const weekDays = getWeekDays(weekRange.from);
    onWeekSelected(weekDays);
    setSelectedDays(weekDays);
    setSelectedRange(weekRange); // Update the selected range
    setIsDropdownOpen(false);
  };

  const handleDayEnter = (date: Date) => {
    setHoverRange(getWeekRange(date));
  };

  const handleDayLeave = () => {
    setHoverRange(undefined);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const daysAreSelected = selectedDays.length === 7;

  const modifiers: Record<string, Matcher | Matcher[]> = {
    hoverRange: hoverRange ? { from: hoverRange.from, to: hoverRange.to } : [],
    selectedRange: daysAreSelected
      ? [{ from: selectedDays[0], to: selectedDays[6] }]
      : [],
    hoverRangeStart: hoverRange?.from ? [hoverRange.from] : [],
    hoverRangeEnd: hoverRange?.to ? [hoverRange.to] : [],
    selectedRangeStart: daysAreSelected ? [selectedDays[0]] : [],
    selectedRangeEnd: daysAreSelected ? [selectedDays[6]] : [],
  };

  const formatSelectedWeek = () => {
    if (!daysAreSelected) return "Select a week";
    return `From ${moment(selectedDays[0]).format("LL")} to ${moment(
      selectedDays[6],
    ).format("LL")}`;
  };

  return (
    <div
      className="WeekPickerDropdown"
      style={{ position: "relative", display: "inline-block" }}
    >
      <button
        className="dropdown-toggle"
        onClick={handleDropdownToggle}
        style={{
          padding: "8px 12px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          backgroundColor: "white",
          cursor: "pointer",
        }}
      >
        {formatSelectedWeek()}
      </button>

      {isDropdownOpen && (
        <div className="card" style={{ position: "absolute", zIndex: 99 }}>
          <DayPicker
            mode="range"
            selected={selectedRange}
            showWeekNumber
            showOutsideDays
            modifiers={modifiers}
            onDayClick={handleDayChange}
            onDayMouseEnter={handleDayEnter}
            onDayMouseLeave={handleDayLeave}
          />
        </div>
      )}
    </div>
  );
};

export default WeekPicker;
