import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import Tab from "../utils/tabs/Tab";
import TabList from "../utils/tabs/TabList";

const ProfileTabs = () => {
  const { uuid, page } = useParams();
  const { user } = useAuth();
  const isPositionAdmin = user.is_position_admin;

  return (
    <TabList>
      <Tab
        link={`/clients/${uuid}/details`}
        active={page === "details"}
        icon="icon ion-clipboard text-capitalize"
      >
        Details
      </Tab>
      <Tab
        link={`/clients/${uuid}/documents`}
        active={page === "documents"}
        icon="icon ion-document text-capitalize"
      >
        Documents
      </Tab>
      <Tab
        link={`/clients/${uuid}/feedback`}
        active={page === "feedback"}
        icon="icon ion-person-stalker text-capitalize"
      >
        Feedback
      </Tab>
      <Tab
        link={`/clients/${uuid}/account`}
        active={page === "account"}
        icon="icon ion-person-stalker text-capitalize"
      >
        Account Details
      </Tab>
      <Tab
        link={`/clients/${uuid}/contacts`}
        active={page === "contacts"}
        icon="icon ion-person-stalker text-capitalize"
      >
        Contacts
      </Tab>
      <Tab
        link={`/clients/${uuid}/projects`}
        active={page === "projects"}
        icon="icon ion-hammer text-capitalize"
      >
        Projects
      </Tab>
      <Tab
        link={`/clients/${uuid}/tenders`}
        active={page === "tenders"}
        icon="icon ion-hammer text-capitalize"
      >
        Tenders
      </Tab>
      <Tab
        link={`/clients/${uuid}/pricing`}
        active={page === "pricing"}
        icon="fa fa-money text-capitalize"
      >
        Pricing
      </Tab>
      <Tab
        link={`/clients/${uuid}/overview`}
        active={page === "overview"}
        icon="fa fa-money text-capitalize"
      >
        Overview
      </Tab>
      {isPositionAdmin && (
        <Tab
          link={`/clients/${uuid}/invoices`}
          active={page === "invoices"}
          icon="fa fa-money text-capitalize"
        >
          Invoices
        </Tab>
      )}
      {isPositionAdmin && (
        <Tab
          link={`/clients/${uuid}/audit-log`}
          active={page === "audit-log"}
          icon="fa fa-pencil-square-o"
        >
          Audit Log
        </Tab>
      )}
      <Tab
        link={`/clients/${uuid}/communications`}
        active={page === "communications"}
        icon="fa fa-comments"
      >
        Communication Log
      </Tab>
    </TabList>
  );
};

export default ProfileTabs;
